import React, { useState ,useEffect} from 'react';

import { useParams } from 'react-router-dom';
import BASE_URL from '../components/constant';
import axios from 'axios';
import { Markup } from 'interweave';
import Homeblog from '../Pages/HomeBlogs';
import HomeClient from '../Pages/Clients';

const BlogsPost = () => {

    let {blogId} = useParams();

    // const Blg = BlogsData.find((p)=>p.id == blogId);
    const [Blg, setBlog] = useState([]);

    useEffect(() => {
      // Fetch data from your API using Axios
      axios
        .get(`${BASE_URL}/blogs_api.php?id=${blogId}`)
        .then(response => {
          setBlog(response.data);
          // Initialize the swiperRefs array based on the fetched data length
          // swiperRefs.current = Array(response.data.length).fill(null).map(() => useRef());
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }, []);
    function truncateString(str, maxLength) {
        return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
      }

    return (
        <>
            <div className="ww90" style={{paddingTop:"4%"}}>
            <p className="text-left md:text-5xl text-6xl text-black-900 font-bold font-lato"> {Blg.title}</p> 
            <img src={Blg.image} style={{paddingTop:"20px", paddingBottom:"20px"}} alt="How To Develop Your Website22?"/>
            <Markup content={Blg.description} /> 
                   {/* <p className='text-left mt-[26px] text-black-900 text-lg font-lato font-normal'>{Blg.content}</p> */}
            </div>

           
                  
        </>
    )
};
export default BlogsPost;