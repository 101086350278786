import React, {useState,useContext,useEffect} from "react";
import { BrowserRouter , Router, Routes, Route, NavLink, Switch, Link } from "react-router-dom";
import "./Nav.css";
import About from "./Pages/About"
import Contact from "./Pages/Contact";
import Services from "./Pages/Services";
import Portfolio from "./Pages/Portfolio";
import Why from "./Pages/Why";
import Home from "./Home";
import login from "./Pages/login";
import Sign from "./Pages/sign";
import Service from './Pages/Service';
import Writing from "./Pages/WritingS";
import VideoS from "./Pages/VideoS";
import GraphicS from "./Pages/GraphicS";
import Terms from "./Pages/Terms";
import Policy from "./Pages/Policy";
import BlogsPost from "./Pages/BlogPosts";
import Blog from "./Pages/Blog";
import Reset from "./Pages/Reset";
import Profile from "./Profile/ProfilePage";
import Admin from "./Admin/Admin";
import { AuthContext } from "./context/AuthContext";
import Thankyou from "./Pages/Thankyou";
import Meeting from "./Pages/Meeting";

const Navbar = () => {
  const { currentUser,getUserData } = useContext(AuthContext);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [userData, setUser] = useState([]);
  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };
  
  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  useEffect(() => {
    const userData = getUserData();
    setUser(userData);
    console.log(`userdata :${userData.profile_image}`);
  }, [getUserData]);

  return (
    <>
<div className="header ">
<div className="main-header items-center flex   justify-start md:px-5" style={{width:"100%", maxWidth:"1239px", marginRight:"auto", padding:"0", marginLeft:"auto", }}>
<div className="header-left">
  <a href="/" className="logo"><img src="https://cdn.shopify.com/s/files/1/0108/4473/3521/files/logo.png?v=1710796575"/></a>
  </div>
    <div className={`header-right ${currentUser ? 'navprofile' : ''}`}>
    <NavLink  exact activeClassName="active text-xl w-auto" to="/"><span className=" text-xl text-des w-auto">Home</span></NavLink>
    <NavLink  exact activeClassName="active text-xl w-auto" to="/Pages/About"><span className=" text-xl text-des w-auto">About Us</span></NavLink>
    <NavLink exact activeClassName="active text-xl w-auto" to="/Pages/Services"><span className=" text-xl text-des w-auto">Services</span></NavLink>
    <NavLink  exact activeClassName="active text-xl w-auto" to="/Pages/Portfolio"><span className=" text-xl text-des w-auto">Portfolio</span></NavLink>
    <NavLink exact activeClassName="active text-xl w-auto" to="/Pages/Why"><span className=" text-xl text-des w-auto">Why Us</span></NavLink>
    {!currentUser && (
  <>
    <NavLink exact activeClassName="active text-xl w-auto" to="/Pages/login">
      <span className="text-xl text-des w-auto">Login</span>
    </NavLink>
    <NavLink
      exact
      activeClassName="active text-xl w-auto"
      className="sign"
      to="/Pages/sign"
    >
      <span className="text-xl text-des w-auto">Sign Up</span>
    </NavLink>
  </>
)}

    <NavLink exact active ClassName="active text-xl text-des w-auto" className="contact " to="/Pages/Contact"><span className=" text-xl text-des w-auto">Contact Us</span></NavLink>
    {currentUser && (
  <>
  <Link to="/Profile/Profile" className="profile-link">
      <img
        src={userData.profile_image}
        alt="Profile"
        className="h-[50px] imgborder rounded-[50%] w-[50px]"
      />
    </Link>
  </>
)}

</div>
  </div>
</div>

<div className="mobile-drawer md:pt-[10px] sm:pb-[0px] md:pb-[20px]">
        <div className="container mx-auto flex items-center justify-between">
          <Link to="/" className="text-white text-lg font-semibold"><img className="mobile-logo md:w-20 sm:p-[14px]" src="https://cdn.shopify.com/s/files/1/0108/4473/3521/files/logo.png?v=171079657"/></Link>

          {/* Mobile Drawer Icon */}
          <div className="lg:hidden">
            <button
              onClick={toggleDrawer}
              className="text-white focus:outline-none"
            >
              {isDrawerOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              )}
            </button>
          </div>

         
        </div>
      </div>

      {/* Mobile Drawer */}
      {isDrawerOpen && (  
        <div className="lg:hidden " style={{width:"100%", height:"102%", marginTop:"-4%", position:"fixed", background:"rgb(255 255 255 / 75%)", zIndex:"199999999", }}>
          <div className="drawercint">
        {currentUser && (
        <>
        <div className="mt-[15px]"> 
        <Link to="/Profile/Profile" className="profile-link " style={{textAlign:"-webkit-center"}}>
      <img
        src={userData.profile_image}
        alt="Profile"
        onClick={closeDrawer}
        className="h-[50px] imgborder sm:m-auto rounded-[50%] w-[50px]"
      />
    </Link>
    </div>
        </>
        )}
        
          <NavLink  exact activeClassName="active text-l w-auto" to="/"><span onClick={closeDrawer} className="block reglar-m-d-t mt-2 mb-3  w-auto">Home</span></NavLink>
    <NavLink  exact activeClassName="active text-l w-auto" to="/Pages/About"><span onClick={closeDrawer}  className="block mt-2 mb-3 reglar-m-d-t  w-auto">About Us</span></NavLink>
    <NavLink exact activeClassName="active text-l w-auto" to="/Pages/Services"><span onClick={closeDrawer}  className="block reglar-m-d-t mt-2 mb-3  w-auto">Services</span></NavLink>
    <NavLink  exact activeClassName="active text-l w-auto" to="/Pages/Portfolio"><span onClick={closeDrawer}  className="block reglar-m-d-t mt-2 mb-3  w-auto">Portfolio</span></NavLink>
    <NavLink exact activeClassName="active text-l w-auto" to="/Pages/Why"><span onClick={closeDrawer}  className="block reglar-m-d-t mt-2 mb-3  w-auto">Why Us</span></NavLink>
    {currentUser && (
      <>
    <Link exact  className="contact-mobilee-btn block" to="/Pages/Contact" style={{background:"transparent", margin:"auto", border:"1px solid #FF3D57",  padding:"3px", borderRadius:"24px", width:"108.077px"}}><span onClick={closeDrawer}  className="text-[#fff] text-l w-auto" style={{color:"#FF3D57"}}>Contact Us</span></Link>
    </>)
    }  {!currentUser && (
      <> 
      <NavLink exact activeClassName="active text-l w-auto" to="/Pages/login"><span onClick={closeDrawer}  className="block reglar-m-d-t mt-2 mb-2 w-auto">Login</span></NavLink>   
      <Link exact  className="sign-mobilee-btn block" to="/Pages/sign"><span onClick={closeDrawer}  className="text-[#fff] text-l w-auto">Sign Up</span></Link>
      <Link exact  className="contact-mobilee-btn block" to="/Pages/Contact" ><span onClick={closeDrawer}  className="text-[#fff] text-l w-auto">Contact Us</span></Link>
    </>
)
    }
    {currentUser && (
  <>
  <p onClick={closeDrawer}  style={{color:"#1F4168"}} className="text-center  w-full justify-center py-[15px] px-[4px] text-black-900 text-[14px]  font-lato font-semibold">
          Log out</p>
  </>
)}

          {/* Add more drawer links as needed */}
        </div></div>
      )}

{/* <Routes>
  <Route path='/' element={<Home />} />
  <Route path='/Pages/About' element={<About />} />
  <Route path='/Pages/Contact' element={<Contact />} />
  <Route path='/Pages/Services' element={<Services />} />
  <Route path='/Pages/Portfolio' element={<Portfolio />} />
  <Route path='/Pages/Why' element={<Why />} />
  <Route path='/Pages/login' element={<Login />} />
  <Route path='/Pages/sign' element={<Sign />} />
  <Route path='/Pages/Service/:id?/:servicename?' element={<Service />} />
  <Route path='/Pages/Terms' element={<Terms />} />
  <Route path='/Pages/Policy' element={<Policy />} />
  <Route path='/Pages/BlogsPost' element={<BlogsPost />} />
  <Route path='/Pages/Blog/:blogId' element={<Blog />} />
  <Route path='/Pages/Reset' element={<Reset />} />
  <Route path='/Profile/Profile' element={currentUser ? <Profile /> : <login />} />
</Routes> */}
 {/* <Route path='/Profile/Profile' Component={Profile}/> */}
      {/* <Route path='/Admin/Admin' Component={Admin}/> */}
    
    <Routes>
    <Route  path='/' Component={Home}/>
      <Route exact path='/Pages/About' Component={About}/>
      <Route exact path='/Pages/Contact' Component={Contact}/>
      <Route exact path='/Pages/Services' Component={Services}/>
      <Route path='/Pages/Portfolio' Component={Portfolio}/>
      <Route exact path='/Pages/Why' Component={Why}/>
      <Route path='/Pages/login' Component={login}/>
      <Route path='/Pages/sign' Component={Sign}/>
     
       <Route path='/Pages/Service/:id?/:servicename?' Component={Service} />


     
      <Route path='/Pages/Terms' Component={Terms}/>
      <Route path='/Pages/Policy' Component={Policy}/>
      <Route path='/Pages/BlogsPost' Component={BlogsPost}/>
      <Route path='/Pages/Blog/:blogId' Component={Blog}/>
      <Route path='/Pages/Reset' Component={Reset}/>

      <Route path='/Pages/thankyou' Component={Thankyou}/>
      <Route path='/Pages/meeting' Component={Meeting}/>

<Route path='/Profile/Profile' Component={currentUser?Profile:login}/>

     

    </Routes>
   
   


  </>
  );
};

export default Navbar;
