// import React from "react";
//mport { Button, Card, CardContent, TextField, Typography } from '@mui/material';
import React, { useState,useContext } from 'react'
import { auth,firestore,provider } from '../firebase';
// import { addDoc, collection } from 'firebase/firestore';
import { createUserWithEmailAndPassword,sendEmailVerification,RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { registerUser, handleGoogleLogin,saveUserDataToFirestore,getCountryFromIP,verifyUser } from '../services/AuthServices';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";
// import BASE_URL from '../components/constant';
// import axios from 'axios';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Sign= () =>{
  const navigate = useNavigate();
  const { saveUserData, getUserData, emptyCurrentUser } = useContext(AuthContext);
 // const [user, setUser] = useState(null);
  const [phone, setPhone] = useState('+91');
   const [hasFilled, setHasFilled] = useState(false);
  // const [otp,setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [otp,setOtp] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      }
    }, auth);
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSend = async (event) => {
    event.preventDefault();

    if (!name) {
      toast.error('Please fill in the name field');
          return;
        }
        if (!email) {
          toast.error('Please fill in the email field');
          return;
        }
        if (!isValidEmail(email)) {
          toast.error('Please fill in a valid email address');
          return;
        }
        if (!password) {
          toast.error('Please fill in the password field');
          return;
        }
      
        if (!phone) {
          toast.error('Please fill in the phone field');
          return;
        }
        if (!isEmailAllowed(email)) {
          toast.error('Registration not allowed for this email domain');
          return;
        }
    // setHasFilled(true);
    // generateRecaptcha();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('User registered successfully:', userCredential.user);

      // Save additional user data to Firestore
      let user = userCredential.user;
      sendEmailVerification(user)
      .then(() => {
        console.log('Email verification sent successfully');
       
      })
      .catch((error) => {
        console.error('Error sending email verification:', error);
      });
      const country =await   getCountryFromIP()
        const userData = {
          name: name,
          password: password,
          email: email,
          phone: phone.startsWith('+') ? phone.slice(1) : phone,
          fb_id: userCredential.user.uid,
          country:country
        };
       // console.log(userData);
        registerUser(userData)
          .then((response) => {
            if (response && response.status === 205) {
              console.log('Email already registered:', response.data.message);
              toast.error('Email already registered:', response.data.message);
              // const userData = {
              //   password: 'social',
              //   email: user.email,
              // };
              // loginUser(userData)
              //   .then(() => {
              //     navigate('/Profile/Profile');
              //   })
              //   .catch((error) => {
              //     console.error('Login failed:', error);
              //     alert('Login failed:', error);
              //   });
            } else {
              console.log('User registered successfully:', response);
              saveUserDataToFirestore({   phone: phone, email: user.email, name: name,uid:userCredential.user.uid, });
              // saveUserData(response.user_details);
              toast.success('Check OTP for phone verification');
             
           //   setHasFilled(true);
    generateRecaptcha();

    let appVerifier = window.recaptchaVerifier;
    auth.signOut();
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        toast.success('Check otp for phone verification');
        setHasFilled(true);
      }).catch((error) => {
        // Error; SMS not sent
        console.log(error);
        toast.error('An error occurred while sending OTP. Please verify your email and log into your account.');
      });
            }
          })
          .catch((error) => {
            console.error('User registration failed:', error);
          });
        // registerUser(userData);
    } catch (error) {
      console.error('User registration failed:', error);
            if (error.code === 'auth/email-already-in-use') {
              toast.error('Email is already in use. Please try again with any other email .'); // Handle specific error message
            } else {
                toast.error('User registration failed. Please try again.');
            }
      // console.error('User registration failed:', error.message);
      // toast.error('User registration failed. Please try again.');
    }
  };
  const verifyOtp = (event) => {
    let otp = event.target.value;
    setOtp(otp);

    if (otp.length === 6) {
      // verifu otp
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        let user = result.user;
        console.log(user);
        verifyUser(email);
        auth.signOut();

        toast.success('Phone verified Sucessfully .kindly check email for email verification');
        navigate('/Pages/login');
        // ...
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        toast.error('User couldn\'t sign in (bad verification code?)');
      });
    }
  }
  const isEmailAllowed = (email) => {
    const allowedDomains = [
      'gmail.com',
      'outlook.com',
      'hotmail.com',
      'yahoo.com',
      'icloud.com',
      'aol.com',
      'mail.ru',
      'yandex.com',
      'live.com'
    ];
  
    const userEmailDomain = email.split('@')[1];
  
    return allowedDomains.includes(userEmailDomain);
  };
  const handleGoogleLogins = async () => {
    await  handleGoogleLogin(navigate,saveUserData);
  };
  const isValidEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};
  
  return(
        <>
            { !hasFilled ? (
              <div className="ww90" style={{marginBottom:"100px"}}>
                <div className="log-main">
                  <ToastContainer />
                    <div className="log-left desktop">
                        <img src="../images/Completedsteps-pana.svg" />
                    </div>
                    <div className="log-left tablet">
                        <img src="../images/Completedsteps-pana.svg" />
                    </div>
                    <div className="log-right">

                    <div className="flex sm:flex-1 flex-col gap-9 items-center justify-center rounded-[11px] w-[450px] sm:w-full md:w-full">
  <div className="flex flex-col items-start justify-start w-full">
    <p className="text-left sm:text-[27.5px] md:text-[29.5px] text-[31.5px] text-gray-900_09 w-auto font-poppins font-semibold">Create an account</p>
  </div>
  <div className="flex flex-col gap-[27px] items-start justify-start w-full">
    <div className="flex flex-col gap-[13.5px] items-start justify-start w-full">
      <div className="flex flex-col items-start justify-start w-full">
        <p className="text-left capitalize text-gray-700 text-lg w-auto font-normal font-poppins">Email</p>
      </div>
      <div className="flex flex-col h-[54px] md:h-auto items-start justify-start w-[450px] md:w-full">
        <div className="w-full rounded-[9px] outline outline-[1.5px] outline-blue-50_04 text-gray-700 pb-3.5 pt-[18px] px-3.5">
          <input className="font-poppins p-0 placeholder:text-gray-700 text-left text-sm w-full bg-transparent border-0" type="email" value={email} placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} />
        </div>
      </div>
    </div>
    <div className="flex flex-col gap-[13.5px] items-start justify-start w-full">
      <div className="flex flex-col items-start justify-start w-full">
        <p className="text-left capitalize text-gray-700 text-lg w-auto font-normal font-poppins">UserName</p>
      </div>
      <div className="flex flex-col h-[54px] md:h-auto items-start justify-start w-[450px] md:w-full">
        <div className="w-full rounded-[9px] outline outline-[1.5px] outline-blue-50_04 text-gray-700 pb-3.5 pt-[18px] px-3.5">
          <input className="font-poppins p-0 placeholder:text-gray-700 text-left text-sm w-full bg-transparent border-0" type="text" value={name} placeholder="Enter UserName" onChange={(e) => setName(e.target.value)} />
        </div>
      </div>
    </div>
    <div className="flex flex-col gap-[13.5px] items-start justify-start w-full">
      <div className="flex flex-col items-start justify-start w-full">
        <p className="text-left capitalize text-gray-700 text-lg w-auto font-normal font-poppins">Mobile Number</p>
      </div>
      <div className="flex flex-col h-[54px] md:h-auto items-start justify-start w-[450px] md:w-full">
        <div className="w-full rounded-[9px] outline outline-[1.5px] outline-blue-50_04 text-gray-700 pb-3.5 pt-[18px] px-3.5">
        {/*<TextField sx={{ width: '240px'}} variant='outlined' autoComplete='off' label='Phone Number' value={phone} onChange={(event) => setPhone(event.target.value)} />*/}
          <input className="font-poppins p-0 placeholder:text-gray-700 text-left text-sm w-full bg-transparent border-0" type="text" label='Phone Number' value={phone} onChange={(event) => setPhone(event.target.value)} />
        </div>
      </div>
    </div>
    <div className="flex flex-col gap-[13.65px] items-start justify-start w-full">
                                        <div className="flex flex-row items-start justify-between w-full">
                                            <p className="text-left capitalize flex-1 text-[18.2px] text-gray-700 w-auto font-normal font-poppins">Password</p>
                                            <a href="/Pages/Reset" className="">Forgot？</a>
                                        </div>
                                        <div className="flex flex-col items-start justify-start w-[455px] sm:w-full md:w-full">
                                            <div className="flex w-full rounded-[9px] border border-gray-400_01 border-solid text-gray-400_01 p-[13px]">
                                                <input className="p-0 placeholder:text-gray-400_01 text-left text-sm w-full bg-transparent border-0"  type={showPassword ? 'text' : 'password'} name="inputtext" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                {showPassword ? (
          <IoMdEyeOff onClick={togglePasswordVisibility} style={{fontSize:"24px"}} />
        ) : (
          <IoMdEye onClick={togglePasswordVisibility} style={{fontSize:"24px"}}/>
        )}
                                            </div>
                                        </div>
                                    </div>
  </div>
  <div className="flex flex-col gap-[27px] items-center justify-start w-full">
   
   
    <button className="cursor-pointer font-poppins font-semibold h-[54px] rounded-[9px] text-base text-center w-[100%] p-[15px] bg-red-400 text-white-A700" onClick={handleSend}>Create Account</button>
   
   
    <button className="cursor-pointer flex items-center justify-center min-w-[450px] md:min-w-[100%] sm:min-w-full rounded-[9px] p-[15px] bg-blue-50_04" onClick={handleGoogleLogins}>
      <img className="h-[22px] mb-0.5 mr-[9px]" src="../images/img_icon_google_original.svg" alt="Icon/Google - Original" loading="lazy" />
      <div className="!text-light_blue-A700 font-poppins font-semibold text-base text-left">Continue with Google</div>
    </button>
    <div className="flex flex-row gap-[9px] items-start justify-start w-auto">
      <p className="text-left capitalize text-gray-400_01 text-lg w-auto font-normal font-poppins">Already have an account ?</p>
      <a href="/Pages/login" className="text-left capitalize text-lg text-red-400 w-auto font-normal font-poppins">Log in</a>
    </div>
  </div>
</div>
                    </div>
                </div>
                 <div id="recaptcha"></div>
            </div>

            ) : (
         <div className="ww90" style={{paddingTop:"4%", paddingBottom:"8%"}}>
                <div className="log-main">
                    <div className="log-left">
                    <img src="../images/Mypassword-pana1.svg" />
                    </div>
                    <div className="log-right">
                    <div className="flex sm:flex-1 flex-col gap-[36.24px] items-center justify-center md:mt-0 mt-[115px] rounded-[11px] w-[453px] sm:w-full">
  <div className="flex flex-col items-start justify-start w-full">
    <p className="text-left sm:text-[27.5px] md:text-[29.5px] text-[31.5px] text-gray-900_09 w-auto font-poppins font-semibold">Enter OTP</p>
  </div>
  <div className="flex flex-col gap-[27px] items-start justify-start w-full">
    <div className="flex flex-col gap-[13.5px] items-start justify-start w-full">
      <div className="flex flex-col items-start justify-start w-full">
        <p className="text-left capitalize text-gray-700 text-lg w-auto font-normal font-poppins">Enter Code Sent to your Phone</p>
      </div>
      <div className="flex flex-col h-[54px] md:h-auto items-start justify-start w-[450px] md:w-full">
        <div className="w-full rounded-[9px] outline outline-[1.5px] outline-blue-50_04 text-gray-700 pb-3.5 pt-[18px] px-3.5">
          <input className="font-poppins p-0 placeholder:text-gray-700 text-left text-sm w-full bg-transparent border-0" type="text"  label='OTP ' value={otp} onChange={verifyOtp} />
        </div>
      </div>
    </div>
  </div>
  <div className="flex flex-col gap-[27px] items-center justify-start w-full">
    <button className="cursor-pointer font-poppins font-semibold h-[54px] rounded-[9px] text-base text-center w-[450px] p-[15px] bg-red-400 text-white-A700" >Verify Otp</button>
    <div className="flex flex-row gap-[9px] items-start justify-start w-auto">
      <p className="text-left capitalize text-gray-400_01 text-lg w-auto font-normal font-poppins">Already Know Your Password ?</p>
      <a href="/Pages/login" className="text-left capitalize text-lg text-red-400 w-auto font-normal font-poppins">Log in</a>
    </div>
  </div>
</div>

                    </div>
                    </div>
                     <div id="recaptcha"></div>
                    </div>
 )
  }
 
    </>
  )

};

export default Sign;