// Portfolio.js
import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/react';
import { useParams } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import '../Pages/Portfolio.css';
import PortfolioRow from '../components/PortfolioData';
import axios from 'axios'
// import { Markup } from 'interweave';
import BASE_URL from '../components/constant';
// import TechData from '../components/TechData';
import WriteData from '../components/WriteData';
import VideoData from '../components/VideoData';
import GraphicData from '../components/GraphicData';
const PortfolioCategory = ({ categoryName, data }) => {
  const [itemsPerRow, setItemsPerRow] = useState(calculateItemsPerRow());
  function calculateItemsPerRow() {
    if (window.innerWidth <= 1024) {
      return 1; // Return the desired value for width >= 1024
    } else if (window.innerWidth <= 768) {
      return 2; // Return the desired value for width >= 768
    } else {
      return 2; // Default value for smaller widths
    }
  }
   
  useEffect(() => {
    function handleResize() {
      setItemsPerRow(calculateItemsPerRow());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const numberOfRows = Math.ceil(data.length / itemsPerRow);
  const rows = Array.from({ length: numberOfRows }, (_, index) =>
    data.slice(index * itemsPerRow, (index + 1) * itemsPerRow)
  );
    return (
      <>
        {rows.map((rowItems, rowIndex) => (
          <PortfolioRow key={rowIndex} items={rowItems} />
        ))}
      </>
    );
  };
  const PagePort = () => { 
    const [services, setServices] = useState([]);
    // const [cat, setCate] = useState([]);
    // const history = useNavigate();
    useEffect(() => {
      console.log(BASE_URL);
      axios.get(`${BASE_URL}/services_api.php?categories_id=1`)
        .then(res => {
          console.log(res.data);
          setServices(res.data); // Assuming the response has a services array
        })
        .catch(err => console.log(err));
    }, []); 

    return(
    <>
    <PortfolioCategory categoryName="Tech" data={services} />
    </>
    )
  };
  const Write = () => {
    const [services, setServices] = useState([]);
    // const [cat, setCate] = useState([]);
    // const history = useNavigate();
    useEffect(() => {
      console.log(BASE_URL);
      axios.get(`${BASE_URL}/services_api.php?categories_id=2`)
        .then(res => {
          console.log(res.data);
          setServices(res.data); // Assuming the response has a services array
        })
        .catch(err => console.log(err));
    }, []); 
    return <PortfolioCategory categoryName="Write" data={services} />;
  }; 
  const Video = () => {
    const [services, setServices] = useState([]);
    // const [cat, setCate] = useState([]);
    // const history = useNavigate();
    useEffect(() => {
      console.log(BASE_URL);
      axios.get(`${BASE_URL}/services_api.php?categories_id=3`)
        .then(res => {
          console.log(res.data);
          setServices(res.data); // Assuming the response has a services array
        })
        .catch(err => console.log(err));
    }, []); 
    return <PortfolioCategory categoryName="Video" data={services} />;
  };
    const Graphic =()=>{
      const [services, setServices] = useState([]);
      // const [cat, setCate] = useState([]);
      // const history = useNavigate();
      useEffect(() => {
        console.log(BASE_URL);
        axios.get(`${BASE_URL}/services_api.php?categories_id=4`)
          .then(res => {
            console.log(res.data);
            setServices(res.data); // Assuming the response has a services array
          })
          .catch(err => console.log(err));
      }, []); 
    return <PortfolioCategory categoryName="Graphic" data={services} />;
  };

export  {PagePort, Write, Video, Graphic};