import React, { useRef, useState, useEffect } from 'react';
import BASE_URL from '../components/constant';
import axios from 'axios';
const Rated =() =>{
    const [clients, setClients] = useState([]);
   
    useEffect(()=>{
      axios.get(`${BASE_URL}/top_rated_api.php`)
      .then(res => {
        console.log(res.data);
        setClients(res.data); // Assuming the response has a services array
      })
      .catch(err=>console.log(err));
      },[])

    // const imagePaths = [
    //     '../images/Fiverr.comsvg.png',
    //     '../images/Freelancersvg.png',
    //     '../images/Upworksvg.png',
    //   ];
    return(
        <>
            <div class="desktop flex flex-col font-inter gap-[27px] items-center justify-start max-w-[990px] md:mt-[10px] mt-[80px] mb-[20px] mx-auto md:px-5 w-full">
            <div class="flex flex-col gap-[7.5px] items-center justify-start w-auto">
            <p class="text-left capitalize text-black-900 text-lg w-auto font-inter font-normal">Top Rated On</p>
            <img class="h-[18px] w-[105px]" src="/../images/img_frame1289.svg" alt="frame1289" loading="lazy"/>
            {/* <img class="h-10 mt-4 mb-4 w-[990px]" src="../images/img_frame1134.svg" alt="frame1134" loading="lazy"/> */}
            </div>
            <div className="flex w-[100%]   mt-4 mb-8">
      {clients.map((path, index) => (
        <div key={index} className="w-[33%] m-auto">
          <img src={path.image} style={{width:"150px", margin:"auto"}} alt={`Image ${index}`} />
        </div>
      ))}
    </div>
            </div>

            <div class="mobile flex flex-col font-inter gap-[27px] items-center justify-start max-w-[990px] md:mt-[10px] mt-[120px] mx-auto md:px-5 w-full">
            <div class="flex flex-col gap-[7.5px] items-center justify-start w-auto">
            <p class="text-left capitalize text-black-900 text-lg w-auto font-inter font-normal">Top Rated On</p>
            <img class="h-[18px] w-[105px]" src="/../images/img_frame1289.svg" alt="frame1289" loading="lazy"/>
            {/* <div className="flex w-[100%] mt-4 mb-8">
                <div className="w-[33%] m-auto">
                    <img src="../images/Fiverr.comsvg.png"/>
                </div>
                <div className="w-[33%] m-auto">
                    <img src="../images/Freelancersvg.png"/>
                </div>
                <div className="w-[33%] m-auto">
                    <img src="../images/Upworksvg.png"/>
                </div>
            </div> */}
            <div className="flex w-[100%] md:w-[80%]  sm:w-[100%]  mb-4">
      {clients.map((path, index) => (
        <div key={index} className="w-[33%] md:w-[20%] md:w-[30%] m-auto">
          <img src={path.image}  alt={`Image ${index}`} />
        </div>
      ))}
    </div>
            </div>
            
            </div>

        </>
    )
};

export default Rated;