import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { auth, firestore,storage } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL ,uploadBytesResumable } from 'firebase/storage';
import { RiGalleryUploadFill, RiFileUploadFill  } from "react-icons/ri";
import { IoDocuments } from "react-icons/io5";
import { FaImages } from "react-icons/fa";
import { FiSend } from "react-icons/fi";


function SendMessage({ scroll }) {
  const {  getUserData } = useContext(AuthContext);
  
  // const [userData, setUser] = useState([]);
  const [name, setName] = useState("");
  const [msg, setMsg] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [document, setDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);


  const userId = auth.currentUser.uid; // Get the current user's ID
  const messagesRef = collection(firestore, 'messages', `${userId}-Admin`,"chats");
  useEffect(() => {
    const userData = getUserData();
   
    setName(userData.name);
    
   
  }, [getUserData]);
  const sendMsg = async (e) => {
    e.preventDefault();
    const { uid } = auth.currentUser;

    if (image || document) {
      let file = image || document;
      let fileName = file.name;
      let fileRef = ref(storage, fileName);
      
      await uploadBytesResumable(fileRef, file).then(async (snapshot) => {
        const downloadURL = await getDownloadURL(fileRef);
        try {
          await addDoc(messagesRef, {
            text: msg,
            createdAt: serverTimestamp(),
            uid: uid,
            image: image ? downloadURL : null,
            document: document ? { name: fileName, url: downloadURL } : null
          });
          addDataToFirestore(`${name} Sent ${image ? 'an Image' : 'a Document'}`,);
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      const trimmedMsg = msg.trim();

    // Check if the trimmed message is empty
    if (!trimmedMsg) {
        // If the trimmed message is empty, return early
        return;
    }
     else{
      await addDoc(messagesRef, {
        text: msg,
        createdAt: serverTimestamp(),
        uid: uid
      });
      addDataToFirestore(`${name} Sent a New Message.`);
    }

    }

    setMsg('');
    setImage(null);
    setImagePreview(null);
    setDocument(null);
    setDocumentName(null);

    if (scroll) {
      scroll.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // const sendMsg = async (e) => {
  //   e.preventDefault();
  //   const { uid, photoURL } = auth.currentUser;
  
  //   if (image) {
  //       //Create a unique image name
  //     const date = new Date().getTime();
  //     const storageRef = ref(storage, `chat_images${+ date}`);

  //     await uploadBytesResumable(storageRef, image).then(() => {
  //       getDownloadURL(storageRef).then(async (downloadURL) => {
  //         try {

  //           addDoc(messagesRef, {
  //               text: msg,
  //               createdAt: serverTimestamp(),
  //               uid: uid,
  //               // photoURL: downloadURL,
  //               image: downloadURL,
  //             });
         
  //         } catch (err) {
  //           console.log(err);
           
  //         }
  //       });
  //     });
       
      
    
  
     
  //   } else {
  //     await addDoc(messagesRef, {
  //       text: msg,
  //       createdAt: serverTimestamp(),
  //       uid: uid,
  //       // photoURL: photoURL,
  //     });
  //   }
  
  //   setMsg('');
  //   setImage(null);
  //   setImagePreview(null);
  //   addDataToFirestore();
  //   if (scroll) {
  //     scroll.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setImage(selectedImage);

      // Create a temporary URL for image preview
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  };
  const handleDocumentChange = (e) => {
    const selectedDocument = e.target.files[0];

    if (selectedDocument) {
      setDocument(selectedDocument);
      setDocumentName(selectedDocument.name);
      const nameParts = selectedDocument.name.split('.');
          const nameWithoutExtension = nameParts.slice(0, -1).join('.');
          const firstThreeCharacters = nameWithoutExtension.slice(0, 3); // Limit to 3 characters
          const extension = nameParts[nameParts.length - 1];
          setDocumentName(`${firstThreeCharacters}...${extension}`);
    }
  };
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const nameParts = file.name.split('.');
  //     const nameWithoutExtension = nameParts.slice(0, -1).join('.');
  //     const firstThreeCharacters = nameWithoutExtension.slice(0, 3); // Limit to 3 characters
  //     const extension = nameParts[nameParts.length - 1];
  //     setDocumentName(`${firstThreeCharacters}...${extension}`);
  //   } else {
  //     setDocumentName(null);
  //   }
  // };
  const addDataToFirestore = async (msg) => {
    try {
      // Add data to a collection named 'exampleCollection'
      const docRef =  await addDoc(collection(firestore,"notifications"), {
       message: msg,
        notify: true,
        // Add more key-value pairs as needed
      });
  
      console.log('Document added with ID: ', docRef.id);
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };
  return (
    <div>
            
      {/* <form onSubmit={sendMsg} className="sendMsg"> */}
      <div class="bg-white-A700 desktop messageBBx flex md:flex-col flex-row font-lato md:gap-5 items-start justify-end mb-1 mt-[0px] outline outline-[1px] outline-black-900_3f p-1.5 rounded-[10px] shadow-bs1 w-full">
  <div className="w-[90%]">
  <input class="text-left w-full md:mt-0 mt-[10px] text-black-900 text-lg font-lato font-normal"
          placeholder="Type a message..."
          type="text"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
       

  </div>
  <button onClick={sendMsg} >
  <FiSend  className="h-6 md:ml-[0]  md:mt-0 mt-[15px] w-6" />
  </button>
  {imagePreview && <img class="h-10 md:ml-[5px] ml-[10px] md:mt-0 mt-[5px] w-10" src={imagePreview} alt="Image Preview" />}
  <label htmlFor="imageUpload">
          <FaImages style={{ height: '24px', width: '24px', marginTop: '15px', marginLeft: '10px' }} />
        </label>
        <input
          type="file"
          id="imageUpload"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
      {documentName && <p className='mt-[10px] ml-[2px]'>{documentName}</p>}
 <label htmlFor="fileUpload">
        <IoDocuments   style={{ height: '24px', width: '24px', marginTop: '15px', marginLeft: '10px' }} />
      </label>
      <input
        type="file"
        id="fileUpload"
        accept=".pdf,.doc,.docx"
        onChange={handleDocumentChange}
        style={{ display: 'none' }}
      />
  <div class="bg-black-900_3f h-[52px] md:h-px md:ml-[0] ml-[9px] md:w-full w-px"></div>
  {/* <img class="h-6 md:ml-[0] ml-[11px] md:mt-0 mt-[15px] w-6" src="../images/img_calendarsuccesssvgrepocom.svg" alt="calendarsuccess" loading="lazy" />
  <img class="h-6 md:ml-[0] ml-[13px] mr-[7px] md:mt-0 mt-[15px] w-6" src="../images/img_upload.svg" alt="upload" loading="lazy" /> */}
</div>

{/* Tablet */}

<div class="bg-white-A700 tablet messageBBx flex  flex-row font-lato md:gap-0  justify-end mb-1 mt-[0px] outline outline-[1px] outline-black-900_3f p-1.5 rounded-[10px] shadow-bs1 w-full">
  <div className="w-[95%]">
  <input class="text-left w-full mt-[5px] text-black-900 text-lg font-lato font-normal"
          placeholder="Type a message..."
          type="text"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
       

  </div>

  <img class="h-6 md:ml-[0]  mt-[10px] w-6" src="../images/img_save_black_900.svg" type="submit" alt="save" loading="lazy" onClick={sendMsg} />
  {imagePreview && <img class="h-10 md:ml-[5px] ml-[10px] md:mt-0 mt-[15px] w-10" src={imagePreview} alt="Image Preview" />}
  <label htmlFor="imageUpload">
          <FaImages style={{ height: '24px', width: '24px', marginTop: '10px', marginLeft: '10px', marginRight:"10px" }} />
        </label>
        <input
          type="file"
          id="imageUpload"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
{documentName && <p className='mt-[10px] ml-[2px]'>{documentName}</p>}
 <label htmlFor="fileUpload">
        <IoDocuments className="ipad-docu"  style={{ height: '24px', width: '24px', marginTop: '15px', marginLeft: '10px' }} />
      </label>
      <input
        type="file"
        id="fileUpload"
        accept=".pdf,.doc,.docx"
        onChange={handleDocumentChange}
        style={{ display: 'none' }}
      />
  {/* <img class="h-6 md:ml-[0] ml-[11px] md:mt-0 mt-[15px] w-6" src="../images/img_calendarsuccesssvgrepocom.svg" alt="calendarsuccess" loading="lazy" />
  <img class="h-6 md:ml-[0] ml-[13px] mr-[7px] md:mt-0 mt-[15px] w-6" src="../images/img_upload.svg" alt="upload" loading="lazy" /> */}
</div>
{/* Mobile */}
      
       <div style={{zIndex:"1999999999999"}} class="bg-white-A700 messageBBx mobile flex  flex-row font-lato  items-start justify-end mb-1 mt-[0px] outline outline-[1px] outline-black-900_3f p-4 rounded-[20px] shadow-bs1 w-full">

  <label htmlFor="imagUpload">
          <FaImages style={{ height: '24px', width: '24px', marginTop: '4px', marginLeft: '0px' }} />
        </label>
        {imagePreview && <img class="h-8 md:ml-[5px] ml-[10px] md:mt-0 mt-[0px] w-10" src={imagePreview} alt="Image Preview" />}
        <input
          type="file"
          id="imagUpload"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
 <label htmlFor="fileUpload">
        <IoDocuments   style={{ height: '24px', width: '24px', marginTop: '5px', marginLeft: '5px', marginRight: '5px' }} />
      </label>
     
      <input
        type="file"
        id="fileUpload"
        accept=".pdf,.doc,.docx"
        onChange={handleDocumentChange}
        style={{ display: 'none' }}
      />
  <div className="w-[90%]">

  <input class="text-left w-full md:mt-0 mt-[10px]  text-black-900 text-lg font-lato font-normal"
          placeholder="Type a message..."
          type="text"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
       

  </div>
  {documentName && <p className='mt-[4px] mr-[4px]'>{documentName}</p>}
  <img class="h-6 md:ml-[0] mr-[8px]  mt-[4px] w-6" src="../images/img_save_black_900.svg" type="submit" alt="save" loading="lazy" onClick={sendMsg} />
  
  {/* <img class="h-6 md:ml-[0] ml-[11px] md:mt-0 mt-[15px] w-6" src="../images/img_calendarsuccesssvgrepocom.svg" alt="calendarsuccess" loading="lazy" />
  <img class="h-6 md:ml-[0] ml-[13px] mr-[7px] md:mt-0 mt-[15px] w-6" src="../images/img_upload.svg" alt="upload" loading="lazy" /> */}
</div>
     
      
    </div>
  );
}

export default SendMessage;
