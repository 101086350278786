import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from 'axios';
import BASE_URL from '../components/constant';
import { IoCameraReverse } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProfileAbout = () => {
  const { saveUserData, getUserData } = useContext(AuthContext);
  // const [userData, setUser] = useState([]);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [profile, setprofile] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState();
  const [country, setCountry] = useState("");
  const [newProfileImage, setNewProfileImage] = useState(null);
  const [uid, SetUid] = useState("");
  useEffect(() => {
    const userData = getUserData();
    console.log("userData.phone:", userData.phone); // Log userData.phone
    SetUid(userData.id);
    setGender(userData.gender);
    setName(userData.name);
    setEmail(userData.email);
    if (userData.phone) {
      // Remove leading "+" sign if present in phone number
      const formattedPhoneNumber = userData.phone.startsWith('+') ? userData.phone.slice(1) : userData.phone;
      setMobileNumber(formattedPhoneNumber);
      console.log(userData.phone);
    }// Check this line
    // console.log(userData.phone);
    setCountry(userData.country);
    setprofile(userData.profile_image);
    // setUser(userData);
    // console.log(userData.profile_imag);
  }, [getUserData]);

  // State variables to store user data

  const countryNames = {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, Democratic Republic of the",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea, Democratic People's Republic of",
    KR: "Korea, Republic of",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia, the Former Yugoslav Republic of",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Moldova, Republic of",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    AN: "Netherlands Antilles",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    SH: "Saint Helena",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan, Province of China",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe"
};
  // Function to handle the update profile button click
  const handleUpdateProfile = () => {
    // Save or process the user data as needed
    const userDatas = {
      name: name,
      gender:gender,
      ///email:email,
      phone: mobileNumber,
      country: country,
      profile_image: profile,
    };


    const formData = new FormData();

    // Append the file to the FormData object
    formData.append('profile_image', newProfileImage);

    // Append other user data to the FormData object
    formData.append('name', name);
       formData.append('gender', gender);
    formData.append('phone', mobileNumber);
    formData.append('country', country);

    // Configure the Axios request
    const config = {
      method: 'post',
      url: `${BASE_URL}/user_api.php/?id=${uid}&_method=PATCH`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };

    // Make the Axios request
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        //setNewProfileImage(null);
        saveUserData(userDatas);
        toast.success("User Updated Sucessfully");
      })
      .catch((error) => {
        console.log(error);
      });
    // };
    //     const userData = {
    //         name,
    //         email,
    //         mobileNumber,
    //         country,
    //         profileImage: newProfileImage,
    //       };

    //     // You can use this userData as needed (e.g., save to context, make an API call, etc.)
    //     console.log("User Data:", userData);
    //     setNewProfileImage(null);

    //   // Axios call to update the user profile
    //   axios.post(`http://localhost/user_api.php/?id=${uid}&_method=PATCH`, userData)
    //     .then(response => {
    //       console.log('User profile updated successfully:', response.data);
    //       // Reset the newProfileImage state to null or perform any other necessary actions
    //       setNewProfileImage(null);
    //     })
    //     .catch(error => {
    //       console.error('Error updating user profile:', error);
    //     });

  };
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Display the selected image before updating
      const reader = new FileReader();
      reader.onload = (e) => {
        setprofile(e.target.result);
      };
      reader.readAsDataURL(file);

      // Set the newProfileImage state with the selected file
      setNewProfileImage(file);
    }
  };

  return (
    <>
      <div className="about-profile-main">
       
        <div className="profile-pic">
          <img
            className="h-[184px] imgborder md:h-auto sm:m-auto md:m-auto sm:mt-10  ml-[30px]  md:mt-10 mt-[86px] rounded-[50%] w-[184px]"
            src={profile}
            alt="ellipseThirtyThree_One"
            loading="lazy"
          />

          <label className="change-cicn" >
            <input
              id="profileImageInput"
              type="file"
              accept="image/*"
              style={{ display: "none", }}
              onChange={handleProfileImageChange}
            />
            <IoCameraReverse style={{ position: "", fontSize: "30px", margin: "auto" }} />
          </label>
        </div>

        <div className="profile-data">
          <div className="pfield">
            <label className="text-left ml-2 mt-10 capitalize text-[18.2px] text-gray-700 w-auto font-normal font-poppins">
              Name
            </label>
            <input
              style={{ border: '2px solid #e0effe' }}
              className=" profile-inpt  placeholder:text-gray-700 text-left text-sm w-full bg-transparent border-0"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Full Name"
            />
          </div>
          <div className="pfield">
            <label className="text-left ml-2 mt-10 capitalize text-[18.2px] text-gray-700 w-auto font-normal font-poppins">
              Email
            </label>
            <input
              style={{ border: '2px solid #e0effe' }}
              className=" profile-inpt  placeholder:text-gray-700 text-left text-sm w-full bg-gray-200	 border-0"
              type="email"
              readOnly
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </div>
          <div className="pfield">
            <label className="text-left ml-2 mt-10 capitalize text-[18.2px] text-gray-700 w-auto font-normal font-poppins">
              Mobile Number
            </label>
            <input
              style={{ border: '2px solid #e0effe' }}
              className=" profile-inpt  placeholder:text-gray-700 text-left text-sm w-full bg-transparent border-0"
              type="number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              placeholder="Mobile Number"
            />
          </div>
          <div className="pfield">
            <label className="text-left ml-2 mt-10 capitalize text-[18.2px] text-gray-700 w-auto font-normal font-poppins">
              Gender
            </label>
            <select
              style={{ border: '2px solid #e0effe' }}
              className="profile-inpt placeholder:text-gray-700 text-left text-sm w-full bg-transparent border-0"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            
          </div>
          <div className="pfield">
  <label className="text-left ml-2 mt-10 capitalize text-[18.2px] text-gray-700 w-auto font-normal font-poppins">
    Country
  </label>
  <select
    style={{ border: '2px solid #e0effe' }}
    className="profile-inpt text-left text-sm w-full bg-transparent border-0"
    value={country}
    onChange={(e) => setCountry(e.target.value)}
  >
    <option value="">Select Country</option>
    {Object.entries(countryNames).map(([code, name]) => (
      <option key={code} value={name}>
        {name}
      </option>
    ))}
  </select>
</div>
          <div className="text-center">
            <button
              onClick={handleUpdateProfile}
              className="cursor-pointer mt-10 mb-20 font-semibold min-w-[158px] rounded-[25px] text-center text-xl  p-3 bg-red-400 text-white-A700"
            >
              Update Profile
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileAbout;
