import React, { useRef, useState,useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link } from "react-router-dom";
import axios from 'axios'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../Pages/HomeBlog.css'
import BASE_URL from '../components/constant';
import { Markup } from 'interweave';

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

const Homeblog = () => {
  const swiperReference = useRef();
  const swiperRefmobile = useRef();

    // const blogs=[
    //     {title:'How to develop website?', imag:'../images/img_rectangle9422.png', content:'Web development refers to the overall process of creating websites or web...'},
    //     {title:'How to develop website?', imag:'../images/img_rectangle9422_211x310.png', content:'Web development refers to the overall process of creating websites or web...'},
    //     {title:'How to develop website?', imag:'../images/img_rectangle9422_1.png', content:'Web development refers to the overall process of creating websites or web...'},
    //     {title:'How to develop website?', imag:'../images/img_rectangle9422_211x310.png', content:'Web development refers to the overall process of creating websites or web...'},
    //     {title:'How to develop website?', imag:'../images/img_rectangle9422_1.png', content:'Web development refers to the overall process of creating websites or web...'},
    //     {title:'How to develop website?', imag:'../images/img_rectangle9422.png', content:'Web development refers to the overall process of creating websites or web...'},
       

    // ];
    const [blogs, setBlog] = useState([]);

    useEffect(() => {
      // Fetch data from your API using Axios
      axios
        .get(`${BASE_URL}/blogs_api.php`)
        .then(response => {
          setBlog(response.data);
          // Initialize the swiperRefs array based on the fetched data length
          // swiperRefs.current = Array(response.data.length).fill(null).map(() => useRef());
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }, []);
  
    // const chunkArray = (arr, size) => {
    //   return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
    //     arr.slice(index * size, (index + 1) * size)
    //   );
    // };
    function truncateString(str, maxLength) {
      return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
    }
  return (
    <>


   <div className=''>
    <p className='text-center desktop  text-4xl sm:text-[32px] mt-40 sm:mt-10 sm:mb-10 mb-20 md:text-[34px] text-blue_gray-900 font-inter font-semibold'>Read Our Recent Blogs</p>
    </div>
    <div className='blogs-container desktop'>
    <div style={{position:'relative', width:"99%"}}>
      <Swiper
       slidesPerView={3}
        spaceBetween={60}
        pagination={{
          clickable: true,
        }}
        loop={true}
        mousewheel={true}
        keyboard={true}
        initialSlide={3}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        onBeforeInit={(swiper) => {
          swiperReference.current = swiper;
        }}
        className="mySwiper"
      >
      <div className='home-blogs'>
      {blogs.map((item) => (
        <SwiperSlide>
      <div className='blog-main'>
        <div className='blog-image'>
            <img style={{borderRadius:"16px", borderBottomRightRadius:"0px", borderBottomLeftRadius:"0px"}} src={item.image}/>
        </div>
        <div className='blogbdr'>
        <div className='blog-content'>
        <h1>{item.title}</h1>
        <Markup content={truncateString(item.description, 150)} /> 
        </div>
        <div className='blogmore'>
            <Link to={`/Pages/Blog/${item.id}`} className='text-right md:ml-[0] ml-[5px] w-[100%] mt-1.5 text-red-400 text-xs font-bold font-inter'>Read More</Link>
        </div>
        </div>
      </div>
      </SwiperSlide>
      ))}
     
      </div>
        
      </Swiper>
      
      <button className='swiper-button-prev video-bnt' 
        style={{    position: "absolute", left: "-8%", top:"40%", background: "#EC4F5C", width: "36px", height: "35.997px"}}  onClick={() => swiperReference.current?.slidePrev()}></button>
        <button className='swiper-button-next video-bnt' 
        style={{    position: "absolute", right: "-8%", top:"40%", background: "#EC4F5C", width: "36px", height: "35.997px"}} onClick={() => swiperReference.current?.slideNext()}></button>
        </div>
      </div>
      <div className=' mobile'>
    <div className='px-4' style={{position:'relative'}}>
      <Swiper
       slidesPerView={1.5}
        spaceBetween={60}
        pagination={{
          clickable: true,
        }}
        loop={true}
        mousewheel={true}
        keyboard={true}
        initialSlide={3}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        onBeforeInit={(swiper) => {
          swiperRefmobile.current = swiper;
        }}
        style={{    height:" 310px"}}
        className="mySwiper"
      >
      <div className='home-blogs'>
      {blogs.map((item) => (
        <SwiperSlide>
      <div className='blog-main'>
        <div className='blog-image'>
            <img style={{borderRadius:"16px", borderBottomRightRadius:"0px", borderBottomLeftRadius:"0px"}} src={item.image}/>
        </div>
        <div className='blogbdr'>
        <div className='blog-content'>
        <h1>{item.title}</h1>
        <Markup content={truncateString(item.description, 45)} /> 
        </div>
        <div className='blogmore'>
            <Link to={`/Pages/Blog/${item.id}`} className='text-right md:ml-[0] ml-[5px] w-[100%] mt-1.5 text-red-400 text-xs font-bold font-inter'>Read More</Link>
        </div>
        </div>
      </div>
      </SwiperSlide>
      ))}
     
      </div>
        
      </Swiper>
             </div>
      </div>
      {/* tablet */}
      
    </>
  );
}
export default Homeblog;
