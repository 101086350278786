import React, { useState, useEffect } from "react";
import axios from "axios";
import BASE_URL from '../components/constant';
const Founder= () =>{
  const [founderData, setFounderData] = useState({});

  useEffect(()=>{
    axios.get(`${BASE_URL}/founder_api.php`)
    .then(res => {
      console.log(res.data);
      setFounderData(res.data); // Assuming the response has a services array
    })
    .catch(err=>console.log(err));
    },[])
    return (
        <>
            <div className="flex flex-col items-center justify-start sm:mt-[40px] md:mt-[20px]  mt-[120px] mx-auto md:px-0 w-full">
<div className="flex md:flex-col flex-row md:gap-10 items-start justify-between w-full">
  <div className="flex md:flex-1 flex-col items-start justify-start w-[46%] md:w-full">
    <div className="flex h-8 justify-end md:ml-[0] ml-[3px] relative w-[32%]">

      <p
        className="absolute h-full inset-[0] justify-center md:text-left  m-auto ml-[0px] md:text-2xl sm:text-[22px] text-[26px] text-center text-red-400 w-max"
        size="txtLatoSemiBold26"
      >
        About the Founder
        <span className='service-underline' > <span style={{color:"white"}}>About the Founde </span> </span>
      </p>
    </div>
   <div className="founderContent">
    <h1
      className="mt-[27px] text-[34px] sm:text-[35px] md:text-[30px] leading-[36px] font-lato text-gray-900 font-[600]"
    >
      <span className="text-gray-900 font-lato text-left ">
       {founderData.founder_title}
      </span>
      <span className="underline font-lato text-[36px] font-bold leading-[36px]">Ultra</span>
      <span className="text-amber-A400 underline font-lato font-semibold">
        vux
      </span>
    </h1>
    <p
      className="mt-2 sm:mt-4 text-gray-900 text-[16px] sm:text-[16px] sm:pr-[4px] pr-[48px]"
      size="txtLatoRegular18Gray900_1"
    >
      <>
      {founderData.founder_description}
      </>
    </p>
    </div>
    <a href="/Pages/About"
      className="cursor-pointer bg-red-400 sm:p-[10px] p-[15px] md:p-[10px] text-white-A700 font-semibold leading-[normal] min-w-[162px] sm:mt-[15px] md:mt-[15px] mt-[49px] rounded-[25px] text-center text-xl"
      color="red_400"
      size="lg"
      variant="fill"
    >
      Learn More
    </a>
  </div>
  <div className="flex md:flex-1 flex-col font-poppins gap-3 items-center justify-start md:mt-0 mt-0.5 w-1/2 md:w-full">
    <img
      className="h-[100%] md:h-auto object-cover w-full"
      src={founderData.founder_image}
      alt="rectangle171"
    />
    <div className="flex desktop sm:flex-col flex-row sm:gap-5 items-center justify-evenly w-[99%] md:w-full">
      <div className="bg-white-A700 flex flex-col items-center justify-between h-[126px] mb-0.5 p-[11px] shadow-bs7 w-[47%] sm:w-full">
        <div className="m-auto items-start justify-start  w-[80%] md:w-full">
          <p
            className="text-3xl sm:text-[26px] md:text-[28px] text-gray-800 sumit-yadav"
            size="txtCaveatRomanRegular30"
          >
             {founderData.founder_name}
          </p>
          {/* <p
            className="mt-1.5 text-gray-800 text-xl"
            size="txtLatoSemiBold20Gray800"
          >
            Sumit Yadav
          </p> */}
          <div className="flex flex-row font-lato gap-[10px] items-center ">
            <p
              className="text-gray-800 text-sm"
              size="txtLatoRegular14"
            >
              Founder -
            </p>
            <a href={founderData.founder_social} target="_blank" rel="noopener noreferrer">
            <img
              className="h-[30px] md:h-auto object-cover w-[30px]"
              src="../images/img_instagram_30x30.png"
              // href={founderData.founder_social}
              alt="instagram"
            />
            </a>
           
            <a href={founderData.founder_linkedin} 
             className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-light_blue-800"
              target="_blank" rel="noopener noreferrer">
            <img src="/../images/img_bxllinkedin.svg" alt="LinkedIN"/>
            </a>
            {/* <img
              className="h-[30px] md:h-auto object-cover w-[30px]"
              src="../images/img_whatsapp.png"
              alt="whatsapp"
            /> */}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-start w-[54%] sm:w-full">
        <div className="bg-red-A200 flex flex-row gap-[10px] items-center expericen justify-center p-5 rounded-[1px] w-full">
          <img
            className="h-[84px] w-[84px]"
            src="../images/img_experience1.svg"
            alt="experienceOne"
          />
          <p
            className="leading-[40.00px] sm:text-[29px] md:text-[31px] text-[33px] text-white "
            size="txtPoppinsRegular33"
          >
            <span className="md:text-[46px] sm:text-[40px] text-slate-100 font-lato text-left text-[50px] font-bold">
              5+
            </span>
            <span className="text-slate-100 ml-[4px]	 font-lato text-left font-normal">
               Years
            </span> 
            
            <span className="text-slate-100	 font-poppins text-center font-normal">
              <>
                {" "}
               
              </>
            </span>
            <span className="text-slate-100	 font-lato text-center font-normal">
              Experience{" "}
            </span>
          </p>
        </div>
      </div>
    </div>
    {/* tablet */}
    <div className="flex tablet sm:flex-col flex-row sm:gap-5 items-center justify-evenly w-[99%] md:w-full">
      <div className="bg-white-A700 flex flex-col items-center justify-between h-[126px] mb-0.5 p-[11px] shadow-bs7 w-[47%] sm:w-full">
        <div className="m-auto items-start justify-start  w-[80%] md:w-full">
          <p
            className="text-3xl sm:text-[26px] md:m-auto  md:text-[28px] text-gray-800 sumit-yadav"
            size="txtCaveatRomanRegular30"
          >
             {founderData.founder_name}
          </p>
          {/* <p
            className="mt-1.5 text-gray-800 text-xl"
            size="txtLatoSemiBold20Gray800"
          >
            Sumit Yadav
          </p> */}
          <div className="flex flex-row font-lato md:justify-center	 gap-[10px] items-center ">
            <p
              className="text-gray-800 text-sm"
              size="txtLatoRegular14"
            >
              Founder -
            </p>
            <a href={founderData.founder_social} target="_blank" rel="noopener noreferrer">
            <img
              className="h-[30px] md:h-auto object-cover w-[30px]"
              src="../images/img_instagram_30x30.png"
              // href={founderData.founder_social}
              alt="instagram"
            />
            </a>
           
            <a href={founderData.founder_linkedin} 
             className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-light_blue-800"
              target="_blank" rel="noopener noreferrer">
            <img src="/../images/img_bxllinkedin.svg" alt="LinkedIN"/>
            </a>
            {/* <img
              className="h-[30px] md:h-auto object-cover w-[30px]"
              src="../images/img_whatsapp.png"
              alt="whatsapp"
            /> */}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-start w-[54%] sm:w-full">
        <div className="bg-red-A200 flex flex-row gap-[10px] items-center expericen justify-center p-5 rounded-[1px] w-full">
          <img
            className="h-[84px] w-[84px]"
            src="../images/img_experience1.svg"
            alt="experienceOne"
          />
          <p
            className="leading-[40.00px] sm:text-[29px] md:text-[31px] text-[33px] text-white "
            size="txtPoppinsRegular33"
          >
            <span className="md:text-[46px] sm:text-[40px] text-slate-100 font-lato text-left text-[50px] font-bold">
              5+
            </span>
            <span className="text-slate-100 ml-[4px]	 font-lato text-left font-normal">
               Years
            </span> 
            
            <span className="text-slate-100	 font-poppins text-center font-normal">
              <>
                {" "}
               
              </>
            </span>
            <br className="tablet" />
            <span className="text-slate-100	 font-lato text-center font-normal">
              Experience{" "}
            </span>
          </p>
        </div>
      </div>
    </div>
    {/* tablet */}
{/* mobile */}
<div className="flex mobile w-full">
<div className="flex sm:bg-neutral-100 flex-col items-start justify-center sm:items-center sm:justify-center md:gap-[10px] md:pl-[5%]  mb-0.5 w-[59%] ">
          <p
            className="text-3xl sm:text-[26px] md:text-[28px] text-gray-800 sumit-yadav"
            size="txtCaveatRomanRegular30"
          >
            Sumit Yadav
          </p>
          <div className="flex flex-row font-lato gap-[4px] items-left sm:items-center sm:justify-center w-full">
            <p
              className="text-gray-800 text-sm"
              size="txtLatoRegular14"
            >
              Founder -
            </p>
            <img
              className="h-[30px] md:h-auto object-cover w-[30px]"
              src="../images/img_instagram_30x30.png"
              alt="instagram"
            />
            <a href={founderData.founder_social} 
             className="flex h-6 items-center justify-center w-6 rounded-[50%] bg-light_blue-800"
              target="_blank" rel="noopener noreferrer">
            <img src="/../images/img_bxllinkedin.svg" alt="LinkedIN"/>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center justify-start w-[54%] ">
        <div className="bg-red-A200 flex flex-row gap-[10px] sm:gap-[5px] md:gap-[20px] items-center  p-4 rounded-[1px] w-full">
          <img
            className="h-[42px] w-[41px] md:h-[60px] md:w-[60px] "
            src="../images/img_experience1.svg"
            alt="experienceOne"
          />
          <p
            className="leading-[20.00px] sm:text-[18px] md:text-[18px] text-[33px] text-white"
            size="txtPoppinsRegular33"
          >
            <span className="md:text-[46px] sm:text-[18px] text-slate-100 font-lato text-left text-[50px] font-bold">
              5+
            </span>
            <span className="text-slate-100 ml-[4px]	 font-lato text-left font-normal">
               Years
            </span> 
            <span className="text-slate-100	 font-poppins text-left font-normal">
              <>
                {" "}
                <br />
              </>
            </span>
            <span className="text-slate-100	 font-lato text-left font-normal">
              Experience{" "}
            </span>
          </p>
        </div>
      </div>
</div>
{/* mobile */}

  </div>
</div>
</div>
        </>
    )
};

export default Founder;
