

import React, { useState, useEffect,useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import BASE_URL from '../components/constant';
import { Swiper, SwiperSlide } from 'swiper/react';


const ProfileOrders = () => {
  const { saveUserData,getUserData } = useContext(AuthContext);
    // const [userData, setUser] = useState([]);

//   const [uid, SetUid] = useState("");
//   useEffect(() => {
//     const userData = getUserData();
//     SetUid(userData.id);
// }, [getUserData]);


  const [status, setStatus] = useState('In Progress');
  const tableStyle = {
    border: '1px solid #828282',
    borderCollapse: 'collapse',
    width: '100%',
    borderRadius: '4px 0 0 0',
  };

  const cellStyle = {
    border: '1px solid #828282',
    padding: '8px',
  };

  const firstRowStyle = {
    backgroundColor: '#E5E5E5',
  };
  

  const [ordersData, setOrdersData] = useState([]);
  const [pgordersData, setPGOrdersData] = useState([]);
  const [comordersData, setComOrdersData] = useState([]);

  useEffect(() => {
    const users = getUserData();// Log uid after it's set
      const apiUrlStarted = `${BASE_URL}/api_orders.php?status=Started&client_id=${users.id}`;
      const apiUrlInProgress = `${BASE_URL}/api_orders.php?status=In Progress&client_id=${users.id}`;
      const apiUrlCompleted = `${BASE_URL}/api_orders.php?status=Completed&client_id=${users.id}`;
  
      const fetchOrdersData = async (apiUrl, setDataFunction) => {
          try {
              const response = await axios.get(apiUrl);
              const data = response.data;

              console.log(data);
              setDataFunction(data || []);
          } catch (error) {
              console.error('Error fetching orders data:', error);
          }
      };
  
      fetchOrdersData(apiUrlStarted, setOrdersData);
      fetchOrdersData(apiUrlInProgress, setPGOrdersData);
      fetchOrdersData(apiUrlCompleted, setComOrdersData);
  }, [getUserData]);
  // useEffect(() => {
  //   // Replace this with your actual API endpoint
  //   const apiUrl = `${BASE_URL}/api_orders.php?status=Started&client_id=${uid}`;

  //   const fetchOrdersData = async () => {
  //     try {
  //       const response = await axios.get(apiUrl);
  //       const data = response.data;

  //       // Assuming your API response has an array of orders
  //       return data || [];
  //     } catch (error) {
  //       throw new Error('Error fetching orders data');
  //     }
  //   };

  //   fetchOrdersData()
  //     .then((apiData) => {
  //       setOrdersData(apiData);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching orders data:', error);
  //     });
  // }, [uid]);
  // useEffect(() => {
  //   // Replace this with your actual API endpoint
  //   const apiUrl = `${BASE_URL}/api_orders.php?status=In Progress&client_id=${uid}`;

  //   const fetchOrdersData = async () => {
  //     try {
  //       const response = await axios.get(apiUrl);
  //       const data = response.data;

  //       // Assuming your API response has an array of orders
  //       return data || [];
  //     } catch (error) {
  //       throw new Error('Error fetching orders data');
  //     }
  //   };

  //   fetchOrdersData()
  //     .then((apiData) => {
  //       setPGOrdersData(apiData);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching orders data:', error);
  //     });
  // }, [uid]);
  // useEffect(() => {
  //   // Replace this with your actual API endpoint
  //   const apiUrl = `${BASE_URL}/api_orders.php?status=Compeleted&client_id=${uid}`;

  //   const fetchOrdersData = async () => {
  //     try {
  //       const response = await axios.get(apiUrl);
  //       const data = response.data;

  //       // Assuming your API response has an array of orders
  //       return data || [];
  //     } catch (error) {
  //       throw new Error('Error fetching orders data');
  //     }
  //   };

  //   fetchOrdersData()
  //     .then((apiData) => {
  //       setComOrdersData(apiData);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching orders data:', error);
  //     });
  // }, [uid]);

  const generateTableRows = (data) => {
    const keysToDisplay = ['name', "service_name", 'starting_date', 'delivering_date', 'price', 'status'];

    const defaultHeaderRow = ['Order Name', 'Service', 'Starting Date', 'Delivery Date', 'Price', 'Status'];
    // Extract the keys you want to display
    if (!Array.isArray(data)) {
      // If data is not an array, return an empty array
      return (
        <>
          {/* Default header row */}
          <tr>
            {defaultHeaderRow.map((cellData, colIndex) => (
              <th key={colIndex} style={firstRowStyle}>
                {cellData}
              </th>
            ))}
          </tr>
          <tr key="mm">
            {defaultHeaderRow.map((cellData, colIndex) => (
              <th key={colIndex} style={cellStyle}>
                No data available
              </th>
            ))}
          </tr>


        </>
      );
    }

    return (
      <>
        {/* Default header row */}
        <tr>
          {defaultHeaderRow.map((cellData, colIndex) => (
            <th key={colIndex} style={firstRowStyle}>
              {cellData}
            </th>
          ))}
        </tr>

        {/* Dynamic data rows */}
        {data.map((rowData, rowIndex) => (
          <tr key={rowIndex}>
            {keysToDisplay.map((key, colIndex) => (
              <td key={colIndex} style={cellStyle}>
                {rowData[key]}
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };
  //  const [showFirstDiv, setShowFirstDiv] = useState(true);

  const handleButtonClick = (divToShow) => {
    // Map divToShow to corresponding status
    let newStatus;
    switch (divToShow) {
     
      case 'second':

        newStatus = 'In Progress';
        break;
      case 'third':

        newStatus = 'Completed';
        break;
      default:

        newStatus = 'Started';
        break;
    }

    // Update the status and trigger a re-fetch
    setStatus(newStatus);
  };

  return (
    <>
      <div className="orders porheight">
      <div className="desktop">
       
        <button
          className="cursor-pointer mr-5 mb-10 font-semibold min-w-[128px] rounded-[25px] shadow-bs1 text-center text-xl  p-3 bg-red-400 text-white-A700"
          style={{ backgroundColor: status === 'In Progress' ? '#EC4F5C' : 'white', color: status === 'In Progress' ? 'white' : 'black' }}
          onClick={() => handleButtonClick('second')}
        >
          In Progress
        </button>
        <button
          className="cursor-pointer mb-10 font-semibold min-w-[128px] rounded-[25px] shadow-bs1 text-center text-xl  p-3 bg-red-400 text-white-A700"
          style={{ backgroundColor: status === 'Completed' ? '#EC4F5C' : 'white', color: status === 'Completed' ? 'white' : 'black' }}
          onClick={() => handleButtonClick('third')}
        >Completed
        </button>
        </div>
        {/* Tablet */}
        <div className="tablet">
       
        <button
          className="cursor-pointer mr-5 mb-10 font-semibold min-w-[128px] rounded-[25px] shadow-bs1 text-center text-xl  p-3 bg-red-400 text-white-A700"
          style={{ backgroundColor: status === 'In Progress' ? '#EC4F5C' : 'white', color: status === 'In Progress' ? 'white' : 'black' }}
          onClick={() => handleButtonClick('second')}
        >
          In Progress
        </button>
        <button
          className="cursor-pointer mb-10 font-semibold min-w-[128px] rounded-[25px] shadow-bs1 text-center text-xl  p-3 bg-red-400 text-white-A700"
          style={{ backgroundColor: status === 'Completed' ? '#EC4F5C' : 'white', color: status === 'Completed' ? 'white' : 'black' }}
          onClick={() => handleButtonClick('third')}
        >Completed
        </button>
        </div>
        {/* Moble */}
        <div className="mobile">
        <Swiper
       slidesPerView={2.5}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
        mousewheel={true}
        keyboard={true}
        className="mySwiper"
      >
      
      <SwiperSlide>
      <button
          className="cursor-pointer mr-5 mb-10 font-semibold min-w-[128px] rounded-[25px] shadow-bs1 text-center text-xl  p-3 bg-red-400 text-white-A700"
          style={{ backgroundColor: status === 'In Progress' ? '#EC4F5C' : 'white', color: status === 'In Progress' ? 'white' : 'black' }}
          onClick={() => handleButtonClick('second')}
        >
          In Progress
        </button>
      </SwiperSlide>
      <SwiperSlide>
      <button
          className="cursor-pointer mb-10 font-semibold min-w-[128px] rounded-[25px] shadow-bs1 text-center text-xl  p-3 bg-red-400 text-white-A700"
          style={{ backgroundColor: status === 'Completed' ? '#EC4F5C' : 'white', color: status === 'Completed' ? 'white' : 'black' }}
          onClick={() => handleButtonClick('third')}
        >Completed
        </button>
    </SwiperSlide>
      </Swiper>
        </div>
        
        <div className="table-container">
          <table style={tableStyle} className="">
            <tbody>
              {status === "Started" && generateTableRows(ordersData)}
              {status === "Completed" && generateTableRows(comordersData)}
              {status === "In Progress" && generateTableRows(pgordersData)}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ProfileOrders;
