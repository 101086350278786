import React from 'react';
import { useLocation } from 'react-router-dom';

 
import './thankyou.css'
import { Link } from 'react-router-dom';


const Meeting = ()=>{

    const location = useLocation();
    const data = location.state;
    return(

        <section className='thankyou-cont'>
             

             <div className="thanks-iner">

             <div className="item text-item">
                    <h3>Thanks You!</h3>
                    Your meeting has been successfully scheduled. You will receive the meeting link shortly via WhatsApp or email.
                    <div className='link'>
                        <Link to="/"> Back to home</Link>
                    </div>
                </div>

                <div className="item">
                <img src="../images/thankyou.jpg" className=' thanks-img img-fluid' alt="image" />
                </div>
                
             </div>

            
     
            
            </section>

        
    )
}


export default Meeting;