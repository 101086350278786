import React from "react";
import Service from './HomeServices';
import Client from './Clients';
import Blog from './HomeBlogs';
import Tops from './TopRated';

const Services= () =>{
    
    return (
        <>
         
        <Service/>
        <div className="ww90" style={{paddingTop:"10px"}}>
        <Client/>
        
        <div className="desktop">
        <Blog/>
        <Tops/>
        </div>
      </div>    
        </>
    )
};
export default Services;