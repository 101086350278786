import React, { useState,useEffect,useContext } from "react";
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/react';
import { Link } from "react-router-dom";
import { Navigation } from 'swiper/modules';
import { getCountryFromIP } from "./services/AuthServices";
import 'swiper/css';
import ReactPlayer from 'react-player'
import 'swiper/css/navigation';
import './Hero.css';
import './styles/tailwind.css';
import imgs from "./components/SlideData";
import axios from 'axios';
import BASE_URL from '../src/components/constant';
const Hero=() =>{
  const [video, setVideo] = useState([]);
  const [vidindex,setVidIndex] = useState(0);
  const [visitorCount, setVisitorCount] = useState(0);
 
  useEffect(() => {
    function getOs() {
      const platform = navigator.platform.toLowerCase();
      
      if (platform.includes('win')) {
        return 'Windows';
      } else if (platform.includes('mac')) {
        return 'Mac';
      } else if (platform.includes('linux')) {
        return 'Linux';
      } else if (platform.includes('iphone') || platform.includes('ipad')) {
        return 'iOS';
      } else if (platform.includes('android')) {
        return 'Android';
      } else {
        return 'Unknown';
      }
    }
    
    const fetchVisitorInfo = async () => {
        try {
            const cachedVisitorInfo = JSON.parse(localStorage.getItem('visitorInfo'));
            const today = new Date().toDateString();

            if (cachedVisitorInfo && cachedVisitorInfo.lastVisitDate === today) {
                // Visitor info is present in cache for today's date
                setVisitorCount(cachedVisitorInfo.count);
            } else {
                // Visitor info is not cached for today's date, make the API request
                const country = await getCountryFromIP();
                const currentDate = new Date();
const formattedDate = currentDate.toISOString().slice(0, 19).replace('T', ' '); // Format: YYYY-MM-DD HH:mm:ss

const os = getOs(); // Assuming getOs() function returns the OS information

const userdata = { os, country: country, visit_date: formattedDate };
                
                const config = {
                    method: 'post',
                    url: `${BASE_URL}/visitor.php`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: userdata,
                };

                // Make the Axios request
                const response = await axios(config);
                console.log("Visitor count:", response.data);

                // Update visitor count and last visit date in the cache
                const updatedVisitorInfo = {
                    count: response.data.count,
                    lastVisitDate: today,
                };
                localStorage.setItem('visitorInfo', JSON.stringify(updatedVisitorInfo));

                // Update state with the new visitor count
                setVisitorCount(response.data.count);
            }
        } catch (error) {
            console.error('Error fetching visitor info:', error);
        }
    };

    // Fetch visitor information when component mounts
    fetchVisitorInfo();
}, []);

  useEffect(()=>{
    //console.log(vidindex);
    axios.get(`${BASE_URL}/home_video_api.php`)
    .then(res => {
      //console.log(res.data);
      setVideo(res.data); // Assuming the response has a services array
    })
    .catch(err=>console.log(err));
    },[])
// slider code

const thumb = [
  { id: 0, value: "images/img_home.svg", label: "Home" },
  { id: 1, value: "images/program-vector.svg", label: "Programming & Tech" },
  { id: 2, value: "images/img_frame.svg", label: "Writing & Translation" },
  { id: 3, value: "images/img_frame_blue_gray_900_02.svg", label: "Video & Animation" },
  { id: 4, value: "images/vector-graphic.svg", label: "Graphics & Design" },
];
const  [sliderData, setSliderData] = useState(imgs[0])
const handleClick=(index)=>{
   // console.log(index);
    const slider =imgs[index];
   
    // vidindex=index;
    console.log(vidindex);
    setSliderData(slider);
   
    console.log(vidindex);

}
useEffect(() => {
  // Auto-rotate the slider after 3 seconds
  const intervalId = setInterval(() => {
    const nextIndex = (sliderData.id + 1) % imgs.length;
    handleClick(nextIndex);
  }, 5000);

  // Clear the interval when the component is unmounted or the sliderData changes
  return () => clearInterval(intervalId);
}, [sliderData]);

// modal box
// State to manage modal visibility
const [isModalOpen, setModalOpen] = useState(false);

// Function to open the modal
const [currentVideoUrl, setCurrentVideoUrl] = useState('');

// Function to handle opening the modal and setting the current video URL
const openModal = (videoUrl) => {
  setCurrentVideoUrl(videoUrl);
  setModalOpen(true);
};

// Function to close the modal and clear the current video URL
const closeModal = () => {
  setCurrentVideoUrl('');
  setModalOpen(false);
};


// modal box
// mobile Slider

// mobile Slider

    return(
        <>
        
{/* slider code */}
<div className="App">
{/* slide container */}
        <div className="slide-container sm:h-[570px] md:max-h-[750px]">
        <div className= {`hero-txt md:w-full md:mt-[0px]  ${
    sliderData.id === 0
      ? 'pd'
      : sliderData.id === 1
      ? 'pd1'
      : sliderData.id === 2
      ? 'pd2'
      : sliderData.id === 3
      ? 'pd3'
      : sliderData.id === 4
      ? 'pd4'
      : ''
  }`}>
        <div className="flex flex-row gap-[15px] items-center justify-start w-auto">
                    <hr className={sliderData.id === 0 ? "bg-gray-900 h-0.5 w-[30px]" : "lnot"} />
                    <span
                      className= {sliderData.id === 0 ? "text-gray-900 text-lg w-auto w-[280px]" : "lnot"}
                      size="txtLatoRegular18Gray900_1"
                    >
                      {sliderData.caption}
                    </span>
                  </div>
                  <div className="flex flex-col gap-2.5 sm:gap-2 sm:mb-0  items-start justify-center w-auto md:w-full mt-2 mb-4">
                    <h1
                      className= {`md:text-4xl sm:text-[37px] font-lato text-black-900 text-left ${
    sliderData.id === 0
      ? 'headong sm:text-[46px]'
      : sliderData.id === 1
      ? 'headong1 '
      : sliderData.id === 2
      ? 'headong2'
      : sliderData.id === 3
      ? 'headong3'
      : sliderData.id === 4
      ? 'headong4'
      : ''
  }`}
                      size="txtLatoBold60Black900"
                    >
                      <span className="text-blue_gray-900_02 font-lato text-left ">
                       
                          {sliderData.title}
                          <br />
                       
                      </span>
                      </h1>
                      <h1
                      className={sliderData.id === 0 ? "md:text-5xl headong1	font-lato text-black-900 text-left typing-text" : "md:text-5xl headong	font-lato text-black-900 text-left"}
                      size="txtLatoBold60Black900"
                    >
                      <span className= {sliderData.id === 0 ? "text-green-A700 font-lato text-left font-bold " : "lnot"}>
                        {sliderData.sp1}-
                      </span>
                      <span className={sliderData.id === 0 ? "text-amber-A400 font-lato text-left font-bold  " : "lnot"}>
                        {sliderData.sp2}
                      </span>
                      <span className={sliderData.id === 0 ? "text-gray-900 font-lato text-left font-bold " : "lnot"}>
                        {" "}
                      </span>
                      <span className= {sliderData.id === 0 ? "text-red-400 font-lato text-left font-bold " : "lnot"}>
                        {sliderData.sp3}.
                      </span>
                    </h1>
                    <p
                      className="text-gray-900 text-lg md:pr-[18%] sm:pr-[5%] sm:text-[16px] md:text-[16px] font-lato text-left w-auto mt-[-1%] sm:mb-[4%] mb-[2%]"
                      size="txtLatoRegular18Gray900_1"
                    >
                      {sliderData.content}
                    </p>
                    {sliderData.id === 2 ? <><div className="desktop"><br/></div></> : ""}
                    
                    <p
                      className={sliderData.id === 2 ? "text-gray-900 text-lg md:pr-[18%] sm:pr-[5%] sm:text-[16px] md:text-[16px] font-lato text-left w-auto mt-[-4%] mb-[4%]" : "h-[0]"}
                      size="txtLatoRegular18Gray900_1"
                    >
                      {sliderData.content2}
                    </p>
                  </div>
                  <div className="btm-slide mb-4   flex  md:gap-10 gap-[30px] items-start justify-start  ">
                  <Link
                          className="cursor-pointer font-semibold leading-[normal] min-w-[169px] rounded-[27px] shadow-bs5 text-center text-xl sm:text-[16px] sm:min-w-[140px] sm:p-[8px] p-[15px] bg-red-400 text-white-A700"
                          color="red_400"
                          size="lg"
                          variant="fill"
                          to="/Pages/Contact" 
                        >
                          Chat With Us
                        </Link>
                        <div className="video-btn video-btn flex flex-row gap-[15px] sm:m-auto sm:ml-[0px] sm:gap-[8px] items-center justify-start sm:justify-center w-auto">
                        <a
                            className="flex h-[50px] sm:h-[40px] sm:w-[40px] items-center justify-center rounded-[50%] w-[50px] rounded-[50%] p-3 bg-red-400 text-white-A700"
                            shape="circle"
                            color="red_400"
                            size="md"
                            variant="fill"
                          >
                            <img onClick={() => openModal(video[sliderData.id].video)}
                              className="h-[26px] ml-[7px]"
                              src="images/img_overflowmenu.svg"
                              alt="overflowmenu"
                            />
                          </a>
                        
                          <button onClick={() => openModal(video[sliderData.id].video)}>  <span
                            className="text-blue_gray-900_02 sm:text-[14px] sm:font-bold text-xl w-auto w-[100px]"
                            size="txtLatoSemiBold20Bluegray90002"
                          >
                            Play Video
                          </span></button>
                          {isModalOpen && (
  <div className="main-vidbg">
    <div className="modal-video">
      <div className="modal-cntetn sm:mt-[50%] sm:w-[90%] ">
        {/* <ReactPlayer
          className='video-hw home-page-video'
          url={currentVideoUrl} // Use the currentVideoUrl state variable as the URL
          controls
          playsinline
          preload="metadata" // Add preload metadata here
          style={{ width: "100%" }}
        /> */}
       {/* <video  
       className='video-hw home-page-video'
        controls
        playsinline
        preload="metadata" // Add preload metadata here
          style={{ width: "100%", background:"white", }}
          poster="https://cdn.shopify.com/s/files/1/0108/4473/3521/files/Untitled_design_25_db00c630-c5f3-44f6-a9cb-ff815a3130a0.png"
          >
      <source src={currentVideoUrl} type="video/mp4" />
    </video> */}
    <iframe id="ytplayer" type="text/html" className='video-hw home-page-video'
  src={currentVideoUrl}
  frameborder="0"></iframe>
        <button className="video-model-close" onClick={closeModal}>X</button>
      </div>
    </div>
  </div>
)}
     
                          </div>
                  </div>
        </div>
        <div className="hero-banner">
      <img src={sliderData.value} height="" width="" style={{margin:"auto"}} className={` ${
    sliderData.id === 0
      ? 'himg1'
      : sliderData.id === 1
      ? 'himg2'
      : sliderData.id === 2
      ? 'himg3'
      : sliderData.id === 3
      ? 'himg4'
      : sliderData.id === 4
      ? 'himg5'
      : ''
  }`} />
      
      </div>
      </div>
      <div className="flex_row desktop">
        {/* Custom Thumbnails Outside the Loop */}
        <div className={sliderData.id === 0 ? "clicked" : "thumbnails"}  onClick={() => handleClick(0)}>
         <div className="thumb_img mt-6">
          <img
            className="h-[33px] w-[33px]"
            src={thumb[0].value}
            height="70"
            width="100"
          />
          </div>
          <div className={sliderData.id === 0 ? "clickedp ttext sm:mt-2 mt-6" : "ttext sm:mt-2 mt-6"}>
          <p className="md:text-2xl sm:text-[22px] text-[26px] text-center  thumb_text">Home</p>
        </div>
            </div>
            <div className={sliderData.id === 1 ? "clicked" : "thumbnails"}  onClick={() => handleClick(1)}>
         <div className="thumb_img mt-6">
          <img
            className="h-[33px] w-[33px]"
            src={thumb[1].value}
            height="70"
            width="100"
          />
          </div>
          <div className={sliderData.id === 1 ? "clickedp ttext mt-6 sm:mt-2" : "ttext mt-6 sm:mt-2"}>
          <p className="md:text-2xl sm:text-[22px] text-[26px] text-center  thumb_text">Programming & Tech</p>
        </div>
            </div>
        
            <div className={sliderData.id === 2 ? "clicked" : "thumbnails"}  onClick={() => handleClick(2)}>
         <div className="thumb_img mt-6">
          <img
            className="h-[33px] w-[33px]"
            src={thumb[2].value}
            height="70"
            width="100"
          />
          </div>
          <div className={sliderData.id === 2 ? "clickedp ttext mt-6" : "ttext mt-6"}>
          <p className="md:text-2xl sm:text-[22px] text-[26px] text-center  thumb_text">Writing & Translation</p>
        </div>
            </div>

            <div className={sliderData.id === 3 ? "clicked" : "thumbnails"}  onClick={() => handleClick(3)}>
         <div className="thumb_img mt-6">
          <img
            className="h-[33px] w-[33px]"
            src={thumb[3].value}
            height="70"
            width="100"
          />
          </div>
          <div className={sliderData.id === 3 ? "clickedp ttext mt-6" : "ttext mt-6"}>
          <p className="md:text-2xl sm:text-[22px] text-[26px] text-center  thumb_text">Video & Animation</p>
        </div>
            </div>

            <div className={sliderData.id === 4 ? "clicked" : "thumbnails"}  onClick={() => handleClick(4)}>
         <div className="thumb_img mt-6">
          <img
            className="h-[33px] w-[33px]"
            src={thumb[4].value}
            height="70"
            width="100"
          />
          </div>
          <div className= {sliderData.id === 4 ? "clickedp ttext mt-6" : "ttext mt-6"}>
          <p className="md:text-2xl sm:text-[22px] text-[26px] text-center  thumb_text">Graphics & Design</p>
        </div>
            </div>
        
      </div>
                            <div className="mobile">
      <Swiper
        spaceBetween={20}
        slidesPerView={2.5}
        onSlideChange={(swiper) => handleClick(swiper.activeIndex)}
      >
        {thumb.map((item) => (
          <SwiperSlide key={item.id}>
            <div className={sliderData.id === item.id ? "clicked" : "thumbnails"} onClick={() => handleClick(item.id)}>
              <div className="thumb_img mt-6">
                <img className="h-[33px] w-[33px]" src={item.value} alt={`thumbnail-${item.id}`} />
              </div>
              <div className={sliderData.id === item.id ? "clickedp ttext mt-6 sm:mt-2" : "ttext mt-6 sm:mt-2"}>
                <p className="md:text-2xl sm:text-[18px] text-[26px] text-center thumb_text">{item.label}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

    </div>
    <div className="tablet ">
      <Swiper
        spaceBetween={20}
        slidesPerView={5}
        onSlideChange={(swiper) => handleClick(swiper.activeIndex)}
      >
        {thumb.map((item) => (
          <SwiperSlide key={item.id}>
            <div className={sliderData.id === item.id ? "clicked" : "thumbnails"} onClick={() => handleClick(item.id)}>
              <div className="thumb_img mt-6">
                <img className="h-[33px] w-[33px]" src={item.value} alt={`thumbnail-${item.id}`} />
              </div>
              <div className={sliderData.id === item.id ? "clickedp ttext mt-6" : "ttext mt-6"}>
                <p className="text-[16px]  text-center thumb_text">{item.label}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

    </div>
    </div>
        </>
    )
}
export default Hero;