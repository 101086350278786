import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, collection, addDoc, query, onSnapshot, orderBy } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCLdm0ZLqovS-wW_OVYAHWUyQDT5Lngfjc",
  authDomain: "ultravux-ae3f1.firebaseapp.com",
  projectId: "ultravux-ae3f1",
  storageBucket: "ultravux-ae3f1.appspot.com",
  messagingSenderId: "86232179772",
  appId: "1:86232179772:web:00683a4a6822316979cf39",
  measurementId: "G-XPTB9SF7RY"
};

const app = initializeApp(firebaseConfig);

 const auth = getAuth(app);
 const firestore = getFirestore(app);
 const analytics = getAnalytics(app);
 const provider = new GoogleAuthProvider();
 const storage = getStorage(app);

export { auth, firestore, provider,analytics,storage };