import React, { useState, useEffect } from "react";
import "./Contact.css";
import axios from 'axios';
import Top from "./TopRated";
import BASE_URL from '../components/constant';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { MdError } from "react-icons/md";
import { collection, addDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate= useNavigate();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [meeting, setMeeting] = useState([]);
  const [phone, setPhone] = useState('');
  const [budget, setBudget] = useState('');
  const [currency, setCurrency] = useState('');
  const [contacts, setContact] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setname] = useState('');
  const [msg, setMsg] = useState('');
  const [service1, setServices1] = useState([]);
  const [service2, setServices2] = useState([]);
  const [service3, setServices3] = useState([]);
  const [service4, setServices4] = useState([]);

  // Get the current date and time
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();
  currentDate.setDate(currentDate.getDate() - 1);

  const addDataToFirestore = async (msg) => {
    try {
      // Add data to a collection named 'exampleCollection'
      const docRef = await addDoc(collection(firestore, "notifications"), {
        message: msg,
        notify: true,
        // Add more key-value pairs as needed
      });

      console.log('Document added with ID: ', docRef.id);
      window.location.reload();
    } catch (error) {
      console.error('Error adding document: ', error);
      window.location.reload();
    }
  };
  useEffect(() => {
    axios.get(`${BASE_URL}/general_settings.php`)
      .then(res => {
        //console.log(res.data);
        setContact(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, [])
  useEffect(() => {
    axios.get(`${BASE_URL}/meetings_api.php`)
      .then(res => {
        console.log(res.data);
        setMeeting(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, [])
  const getContact = (index) => {
    // Check if services array is not empty and index is valid

    console.log(contacts);
    if (contacts != null) {
      return contacts;
    } else {
      return {
        contact_no: "123456789",
        whatsapp_no: "987654321",
        address: "123 Main Street",
        email: "example@example.com",
        fb: "facebook",
        insta: "instagram",
        twitter: "twitter",
        linkedin: "linkedin",
        youtube: "youtube"
      };
    };

  };
  useEffect(() => {
    console.log(BASE_URL);
    axios.get(`${BASE_URL}/services_api.php?categories_id=1`)
      .then(res => {
        console.log(res.data);
        setServices1(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, []);
  useEffect(() => {
    console.log(BASE_URL);
    axios.get(`${BASE_URL}/services_api.php?categories_id=2`)
      .then(res => {
        console.log(res.data);
        setServices2(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, []);
  useEffect(() => {
    console.log(BASE_URL);
    axios.get(`${BASE_URL}/services_api.php?categories_id=3`)
      .then(res => {
        console.log(res.data);
        setServices3(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, []);
  useEffect(() => {
    console.log(BASE_URL);
    axios.get(`${BASE_URL}/services_api.php?categories_id=4`)
      .then(res => {
        console.log(res.data);
        setServices4(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, []);
  const Servic = [
    "Programming & Tech",
    "Writing & Translation",
    "Video & Animation",
    "Graphics & Design",
  ];
  const onOptionChangeHandler = (event) => {
    setData(event.target.value);
    console.log(
      "User Selected Value - ",
      event.target.value
    );


  };

  const [dataS, setSData] = useState(undefined);

  // const SServic = [
  //     "Full Website Creation",
  //     "Customization",
  //     "Tech Support",
  //     "Convert Site to App",
  // ];
  const onSOptionChangeHandler = (event) => {
    setSData(event.target.value);
    console.log(
      "User Selected Value - ",
      event.target.value
    );
  };
  const isValidEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const SimpleSend = async (e) => {
    e.preventDefault();
    if (!email) {
      alert('Please fill in the email field');
      return;
    }
    // if (!isValidEmail(email)) {
    //   alert('Please fill in a valid email address');
    //   return;
    // }
    if (!name) {
      alert('Please Enter your Name ');
      return;
    }
    setLoading(true);
    console.log({ email: email, name: name, Msg: name });
    const userData = {
      client_name: name,
      client_email: email,
      status: "open",
      message: msg
    };
    console.log(userData);
    try {
      const response = await axios.post(`${BASE_URL}/chat_simple.php`, userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response);
      // Check if the API request was successful (status code 2xx)
      if (response.status >= 200 && response.status < 300) {

        setLoading(false);
        console.log('API Response:', response.data);
        addDataToFirestore(`${name} Sent a Quick Message in 5min Chat`,);
        navigate('/Pages/thankyou');

        //redirect algo
        // window.location.reload();
      } else {
        setLoading(false);
        console.error('API Request failed with status:', response.status);
        alert('An error occurred while processing your request. Please try again later.');
      }

    } catch (error) {
      setLoading(false);
      console.error('An unexpected error occurred:', error.message);
      alert('An unexpected error occurred. Please try again later. ', error.message);
    }
  };

  const scheduleMeeting = async (e) => {
    e.preventDefault();
    if (!email) {
      alert('Please fill in the email field');
      return;
    }
    if (!isValidEmail(email)) {
      alert('Please fill in a valid email address');
      return;
    }
    if (!name) {
      alert('Please Enter your Name ');
      return;
    }
    if (!phone) {
      alert('Please Enter your Contact No ');
      return;
    }
    if (!budget) {
      alert('Please Enter your Budget for this project');
      return;
    }
    if (!currency) {
      alert('Please Enter your Currency Like $ , Є , INR , £ ');
      return;
    }
    if (!data) {
      alert('Please Select a Service');
      return;
    }
    if (!dataS) {
      alert('Please Select a Sub Service');
      return;
    }
    if (!selectedDateTime) {
      alert('Please Select a Date & Time ');
      return;
    }
    const date = format(selectedDateTime, 'yyyy-MM-dd'); // Get the date part
    let time1 = format(selectedDateTime, 'HH:mm:ss'); // Get the time part in 24-hour format

    // let time = format(selectedDateTime, 'HH:mm:ss'); // Get the time part in 24-hour format
    let time = formatLocaltoUtcTime(time1);

    // Convert the time to UTC by subtracting the time zone offset in minutes



    // Concatenate the date and UTC time to get the UTC date and time
    // const utcDateTime = `${date} ${utcTime}`;

    const userData = {
      client_email: email,
      client_name: name,
      message: msg,
      phone: phone,
      budget: budget,
      currency: currency,
      category_id: data,
      service_name: dataS,
      scheduled_date: date,
      scheduled_time: time
    };
    setLoading(true);
    console.log(userData);
    try {
      const response = await axios.post(`${BASE_URL}/meetings_api.php`, userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response);
      // Check if the API request was successful (status code 2xx)
      if (response.status >= 200 && response.status < 300) {

        setLoading(false);
        console.log('API Response:', response.data);
        const dataTosend={
          date: date,
          time:time1
        }
        // alert(`Your Meeting has been successfully Scheduledad ${date} :  ${time1}. You will receive a meeting link via email soon.`);

        navigate('/Pages/meeting' , { state: dataTosend } );
        addDataToFirestore(`${name} Schedule A Meeeting on ${date} :  ${time1}`,);
        //window.location.reload();
      } else {
        setLoading(false);
        console.error('API Request failed with status:', response.status);
        alert('An error occurred while processing your request. Please try again later.');
      }

    } catch (error) {
      setLoading(false);
      console.error('An unexpected error occurred:', error.message);
      alert('An unexpected error occurred. Please try again later. ', error.message);
    }
  };
  const [selectedDateTime, setSelectedDateTime] = useState( );

  const handleChange = (date) => {
    // Round the minutes to the nearest 30
    date.setMinutes(Math.round(date.getMinutes() / 30) * 30);
    setSelectedDateTime(date);

    // const date = format(date, 'yyyy-MM-dd'); // Get the date part
    let time = format(date, 'HH:mm:ss'); // Get the time part in 24-hour format
    formatLocaltoUtcTime(time);
    // Convert the date and time to UTC

  };
  function formatLocaltoUtcTime(localTime) {
    // Parse the local time string
    const [timePart, amPm] = localTime.split(' ');
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    // Determine the time zone offset in minutes
    const timezoneOffsetInMinutes = new Date().getTimezoneOffset();

    // Calculate the UTC time by adding the time zone offset
    const utcHours = hours + (amPm === 'PM' ? 12 : 0); // Adjust hours if PM
    const utcDateTime = new Date(Date.UTC(0, 0, 0, utcHours, minutes + timezoneOffsetInMinutes, seconds));

    // Format the UTC date and time
    const utcTime = utcDateTime.toISOString().substr(11, 8); // Get time part from ISO string
    console.log(utcTime);
    return utcTime;
  }
  function formatUtcToLocalTime(scheduled_time, scheduled_date) {
    // console.log
    const dateParts = scheduled_date.split('-').map(Number);
    const timeParts = scheduled_time.split(':').map(Number);

    // Validate date and time parts
    // if (dateParts.length !== 3 || timeParts.length !== 3) {
    //     console.error('Invalid date or time format');
    //     return null;
    // }

    const [year, month, day] = dateParts;
    const [hours, minutes, seconds] = timeParts;
    const utcDateTime = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

    // Format the UTC date to the local time zone
    const localTime = utcDateTime.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
    console.log(localTime);
    return localTime;


    // if (isNaN(year) || isNaN(month) || isNaN(day) || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    //     console.error('Invalid date or time values');
    //     return null;
    // }

    // // Create UTC date time
    // const utcDateTime = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

    // // Format local time
    // const localTime = utcDateTime.toLocaleTimeString('en-US', { timeZone: 'UTC', hour12: true });

    // return localTime;
  }
  // const unavailableTimeSlots = [9, 10, 15];

  // const filterUnavailableTimes = (time) => {
  //   const hours = time.getHours();
  //   return !unavailableTimeSlots.includes(hours);
  // };
  const unavailableDateTimes = meeting.map((meetingItem) => {
    const [year, month, day] = meetingItem.scheduled_date.split('-').map(Number);

    let time = formatUtcToLocalTime(meetingItem.scheduled_time, meetingItem.scheduled_date);
    const [hours, minutes, seconds] = time.split(':').map(Number);

    // Create Date object using parsed date and time
    const dateTime = new Date(year, month - 1, day, hours, minutes, seconds);

    return dateTime;
  });

  // Function to filter unavailable date-times
  const filterUnavailableDateTimes = (time) => {
    // Check if the time is in the unavailableDateTimes array
    return !unavailableDateTimes.some((unavailableTime) => time.getTime() === unavailableTime.getTime());
  };
  return (
    <>
      <div className="ww90">
        <div className="main-cinat">
          <div className="contact-left">

            <div class="bg-red-50_02 flex flex-row gap-[5px] w-[180px] items-center justify-start px-2.5 py-[5px] "><div class="bg-red-400 h-px w-[14%]"></div><a href="javascript:" class="text-center text-red-400  text-xl w-auto"><p class="text-left  font-lato font-semibold">Contact us</p></a><div class="bg-red-400 h-px w-[14%]"></div></div>
            <p className="text-left md:text-5xl text-6xl mt-10 mb-10 text-blue_gray-900 w-auto font-bold font-lato">
              Get in touch
            </p>
            <img className="mobile" src="../images/4111240_Mesadetrabajo1.svg" />
            <p class="text-left text-blue_gray-900 text-lg font-lato font-normal">Your customers are online. You know that, and we know that. Let Ultravux help you with your digital media strategies or website today. Get started by getting in touch with our team. <br />Our digital team is always ready to help. Give us a call, send us an email, or fill out the form below.</p>
          </div>
          <div className="contact-right">
            <img className="desktop" src="../images/4111240_Mesadetrabajo1.svg" />
            <img className="tablet" src="../images/4111240_Mesadetrabajo1.svg" />
          </div>

        </div>

        {/* social bar */}
        <div class="gap-[55.64px]  desktop  grid  grid-cols-3 items-start justify-start mt-[82px]   mx-auto  w-full">


          <a href={`https://api.whatsapp.com/send?phone=${getContact(0).whatsapp_no}`} target="_blank" rel="noopener noreferrer">
            <div class="bg-white-A700 flex flex-1 flex-col md:col-span-1 gap-[18.55px] items-center justify-center p-[27.82px] sm:px-5 rounded shadow-bs w-full">
              <img class="h-[33px] w-[33px]" src="../images/img_biwhatsapp.svg" alt="biwhatsapp" loading="lazy" />
              <p class="text-left sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-gray-900 w-auto font-lato font-semibold" >WhatsApp Call</p>
            </div>
          </a>
          <a href={`tel:${getContact(0).contact_no}`} target="_blank" rel="noopener noreferrer">
            <div class="bg-white-A700 flex flex-1 flex-col gap-[18.55px] items-center justify-center p-[27.82px] sm:px-5 rounded shadow-bs w-full">
              <img class="h-[33px] w-[25px]" src="../images/img_fluentcall24regular.svg" alt="fluentcall24reg" loading="lazy" />
              <p class="text-left sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-gray-900 w-auto font-lato font-semibold">Phone call</p>
            </div>
          </a>
          <div class="bg-white-A700 flex flex-1 flex-col gap-[18.55px] items-center justify-center p-[27.82px] sm:px-5 rounded shadow-bs w-full">
            <p class="text-left sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-gray-900 w-auto font-lato font-semibold">Also Contact Us:</p>
            <div class="flex flex-row gap-[13.91px] items-start justify-start w-auto">

              <a href={`mailto:${getContact(0).email}`} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] p-1 bg-red-500">
                  <img class="h-[22px]" src="../images/img_mdigmail.svg" alt="mdigmail" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).insta} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-red-400 text-white-A700">
                  <img class="h-[17px]" src="../images/img_info.svg" alt="info" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).fb} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] p-1 bg-light_blue-A400">
                  <img class="h-[18px]" src="../images/img_dashiconsfacebookalt.svg" alt="dashiconsfacebo" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).linkedin} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-light_blue-800">
                  <img class="h-[18px]" src="../images/img_bxllinkedin.svg" alt="bxllinkedin" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).twitter} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-blue_gray-900_02">
                  <img class="h-[18px]" src="../images/img_antdesigntwitteroutlined.svg" alt="antdesigntwitte" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).youtube} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-red-400 text-white-A700">
                  <img class="h-[18px]" src="../images/img_biyoutube.svg" alt="biyoutube" loading="lazy" />
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* Tablet */}
        <div class="gap-[10.64px] tablet  grid grid-cols-4  items-start justify-start mt-[82px]  mx-auto w-full">


          <a href={`https://api.whatsapp.com/send?phone=${getContact(0).whatsapp_no}`} target="_blank" rel="noopener noreferrer">
            <div class="bg-white-A700 flex flex-1 col-span-1 flex-col md:col-span-1 gap-[18.55px] items-center justify-center p-[10.82px] rounded shadow-bs w-full">
              <img class="h-[33px] w-[33px]" src="../images/img_biwhatsapp.svg" alt="biwhatsapp" loading="lazy" />
              <p class="text-left sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-gray-900 w-auto font-lato font-semibold" >WhatsApp Call</p>
            </div>
          </a>

          <div class="bg-white-A700 flex flex-1 flex-col col-span-2 gap-[18.55px] items-center justify-center p-[12.80px] sm:px-5 rounded shadow-bs w-full">
            <p class="text-left sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-gray-900 w-auto font-lato font-semibold">Also Contact Us:</p>
            <div class="flex flex-row gap-[13.91px] items-start justify-start w-auto">

              <a href={`mailto:${getContact(0).email}`} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] p-1 bg-red-500">
                  <img class="h-[22px]" src="../images/img_mdigmail.svg" alt="mdigmail" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).insta} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-red-400 text-white-A700">
                  <img class="h-[17px]" src="../images/img_info.svg" alt="info" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).fb} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] p-1 bg-light_blue-A400">
                  <img class="h-[18px]" src="../images/img_dashiconsfacebookalt.svg" alt="dashiconsfacebo" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).linkedin} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-light_blue-800">
                  <img class="h-[18px]" src="../images/img_bxllinkedin.svg" alt="bxllinkedin" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).twitter} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-blue_gray-900_02">
                  <img class="h-[18px]" src="../images/img_antdesigntwitteroutlined.svg" alt="antdesigntwitte" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).youtube} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-red-400 text-white-A700">
                  <img class="h-[18px]" src="../images/img_biyoutube.svg" alt="biyoutube" loading="lazy" />
                </button>
              </a>
            </div>
          </div>
          <a href={`tel:${getContact(0).contact_no}`} target="_blank" rel="noopener noreferrer">
            <div class="bg-white-A700 flex flex-1 flex-col col-span-1 gap-[18.55px] items-center justify-center p-[10.82px] sm:px-5 rounded shadow-bs w-full">
              <img class="h-[33px] w-[25px]" src="../images/img_fluentcall24regular.svg" alt="fluentcall24reg" loading="lazy" />
              <p class="text-left sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-gray-900 w-auto font-lato font-semibold">Phone call</p>
            </div>
          </a>
        </div>
        {/* mobile */}
        <div class="gap-[25.64px] mobile  grid grid-cols-1 items-start justify-start mt-[82px]   mx-auto  w-full">
          <a href={`https://api.whatsapp.com/send?phone=${getContact(0).whatsapp_no}`} target="_blank" rel="noopener noreferrer">
            <div class="bg-white-A700 flex flex-1 flex-col md:col-span-1 gap-[18.55px] items-center justify-center p-[27.82px] sm:px-5 rounded shadow-bs w-full">
              <img class="h-[33px] w-[33px]" src="../images/img_biwhatsapp.svg" alt="biwhatsapp" loading="lazy" />
              <p class="text-left sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-gray-900 w-auto font-lato font-semibold" >WhatsApp Call</p>
            </div>
          </a>
          <a href={`tel:${getContact(0).contact_no}`} target="_blank" rel="noopener noreferrer">
            <div class="bg-white-A700 flex flex-1 flex-col gap-[18.55px] items-center justify-center p-[27.82px] sm:px-5 rounded shadow-bs w-full">
              <img class="h-[33px] w-[25px]" src="../images/img_fluentcall24regular.svg" alt="fluentcall24reg" loading="lazy" />
              <p class="text-left sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-gray-900 w-auto font-lato font-semibold">Phone call</p>
            </div>
          </a>
          <div class="bg-white-A700 flex flex-1 flex-col gap-[18.55px] items-center justify-center p-[27.82px] sm:px-5 rounded shadow-bs w-full">
            <p class="text-left sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-gray-900 w-auto font-lato font-semibold">Also Contact Us:</p>
            <div class="flex flex-row gap-[13.91px] items-start justify-start w-auto">

              <a href={`mailto:${getContact(0).email}`} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] p-1 bg-red-500">
                  <img class="h-[22px]" src="../images/img_mdigmail.svg" alt="mdigmail" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).insta} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-red-400 text-white-A700">
                  <img class="h-[17px]" src="../images/img_info.svg" alt="info" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).fb} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] p-1 bg-light_blue-A400">
                  <img class="h-[18px]" src="../images/img_dashiconsfacebookalt.svg" alt="dashiconsfacebo" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).linkedin} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-light_blue-800">
                  <img class="h-[18px]" src="../images/img_bxllinkedin.svg" alt="bxllinkedin" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).twitter} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-blue_gray-900_02">
                  <img class="h-[18px]" src="../images/img_antdesigntwitteroutlined.svg" alt="antdesigntwitte" loading="lazy" />
                </button>
              </a>
              <a href={getContact(0).youtube} target="_blank" rel="noopener noreferrer">
                <button class="flex h-[29px] items-center justify-center rounded-[14px] w-[29px] rounded-[50%] p-1 bg-red-400 text-white-A700">
                  <img class="h-[18px]" src="../images/img_biyoutube.svg" alt="biyoutube" loading="lazy" />
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* social bar */}
        <div className="forms-main">
          <div className="chat-left">
            <form>
              <p className="text-center  sm:text-[31.759999999999998px] md:text-[33.76px] text-[35.76px] text-red-A200 w-auto font-medium font-poppins">
                <span className="text-red-A200 font-lato text-left font-semibold">Chat Now </span><span class="md:text-[21.84px] sm:text-[15.84px] text-red-A200 font-lato text-left text-[23.84px] font-normal">24*7 ( Reply in 2 minutes )</span></p>
              <p className="text-red-A200 flex font-lato text-[16px] sm:text-[12px] sm:text-left text-center font-semibold"><span><MdError style={{ color: "#00d648", fontSize: "20px" }} /></span> <span style={{ color: "#00d648" }}>Register now or sign in to engage in real-time conversation with our knowledgeable team on our website."</span></p>
              <input className="text-left mt-8 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[19px] sm:pr-5 pr-[35px] py-4 rounded sm:text-[19.84px] md:text-[21.84px] text-[23.84px] text-gray-600_02 w-[100%] font-lato font-normal"
                type="text" placeholder="Name" onChange={(e) => setname(e.target.value)} />
              <input className="text-left mt-8 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[19px] sm:pr-5 pr-[35px] py-4 rounded sm:text-[19.84px] md:text-[21.84px] text-[23.84px] text-gray-600_02 w-[100%] font-lato font-normal"
                type="number" placeholder="Contact Number" onChange={(e) => setEmail(e.target.value)} />

              <textarea className="bg-gray-100_01 mt-8 text-left sm:text-[19.84px] md:text-[21.84px] text-[23.84px] text-gray-600_02  font-lato font-normal flex flex-col h-[152px] md:h-auto items-start justify-start outline outline-[0.5px] outline-gray-300_05 p-[19.87px] rounded w-[100%] sm:w-full"
                placeholder="Message (Optional)" name="postContent" rows={4} cols={40} onChange={(e) => setMsg(e.target.value)} />
              <button className="cursor-pointer sm:rounded-3xl sm:p-[8px] mt-8 font-semibold sm:text-[19.84px] md:text-[21.84px] text-[23.84px] text-center w-[100%] rounded p-[15px] bg-red-A200 text-white-A700" onClick={SimpleSend}>Send</button>
            </form>
          </div>
          <div className="appoint-right sm:mt-10">
            <form>
              <p className="text-center sm:text-[26.66px] md:text-[28.66px] text-[30.66px] text-red-A200 w-auto font-lato font-semibold">Schedule a meeting</p>
              <input className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
                placeholder="Name" type="text"
                onChange={(e) => setname(e.target.value)} />
              <input className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
                placeholder="Contact Number" type="phone"
                onChange={(e) => setPhone(e.target.value)} />
              <input className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
                placeholder="Email" type="email"
                onChange={(e) => setEmail(e.target.value)} />



               <DatePicker
  selected={selectedDateTime}
  onChange={handleChange}
  showTimeSelect
  dateFormat="yyyy-MM-dd h:mm aa"
  timeFormat="h:mm aa"
  timeIntervals={30}
 
  minDate={currentDate}
  filterTime={filterUnavailableDateTimes}
  minTime={
    selectedDateTime && selectedDateTime.toDateString() === currentDate.toDateString()
      ? { hours: currentHour, minutes: currentMinute }
      : null
  }
  maxTime={
    selectedDateTime && selectedDateTime.toDateString() === currentDate.toDateString()
      ? { hours: 23, minutes: 59 }  
      : null
  }
  timeCaption="Time"
  placeholderText="Select Date & Time"
  className="text-left bg-gray-100_01 mt-2 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
/>


              <select className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
                onChange={onOptionChangeHandler}>
                <option>Service</option>
                {Servic.map((options, indexs) => {
                  return (
                    <option key={indexs}>
                      {options}
                    </option>
                  );
                })}
              </select>
              {data === "Programming & Tech" && (
                <select
                  className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
                  onChange={onSOptionChangeHandler}
                >
                  <option>Sub-Service</option>
                  {service1.map((option) => (
                    <option key={option.title}>{option.title}</option>
                  ))}
                </select>
              )}

              {data === "Writing & Translation" && (
                <select
                  className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
                  onChange={onSOptionChangeHandler}
                >
                  <option>Sub-Service</option>
                  {service2.map((option) => (
                    <option key={option.title}>{option.title}</option>
                  ))}
                </select>
              )}

              {data === "Video & Animation" && (
                <select
                  className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
                  onChange={onSOptionChangeHandler}
                >
                  <option>Sub-Service</option>
                  {service3.map((option) => (
                    <option key={option.title}>{option.title}</option>
                  ))}
                </select>
              )}

              {data !== "Programming & Tech" && data !== "Writing & Translation" && data !== "Video & Animation" && (
                <select
                  className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
                  onChange={onSOptionChangeHandler}
                >
                  <option>Sub-Service</option>
                  {service4.map((option) => (
                    <option key={option.title}>{option.title}</option>
                  ))}
                </select>
              )}


              <input className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
                placeholder="Budget" type="text"
                onChange={(e) => setBudget(e.target.value)} />

              <input className="text-left mt-4 bg-gray-100_01 justify-center outline outline-[0.5px] outline-gray-300_05 pl-[17px] sm:pr-5 pr-[35px] py-3.5 rounded sm:text-[16.44px] md:text-[18.44px] text-[20.44px] text-gray-600_02 w-[100%] font-lato font-normal"
                placeholder="Currency ($)" type="text"
                onChange={(e) => setCurrency(e.target.value)} />

              <textarea className="bg-gray-100_01 mt-4 text-left sm:text-[19.84px] md:text-[21.84px] text-[23.84px] text-gray-600_02  font-lato font-normal  h-[152px] md:h-auto items-start justify-start outline outline-[0.5px] w-[100%] outline-gray-300_05 p-[19.87px] rounded w-[100%] sm:w-full"
                placeholder="Message (Optional)" name="postContent" rows={4} cols={40} onChange={(e) => setMsg(e.target.value)} />

              <button className="cursor-pointer sm:rounded-3xl mt-4 font-semibold sm:text-[19.84px] md:text-[21.84px] text-[23.84px] text-center w-[100%] rounded p-[15px] sm:p-[8px] bg-red-A200 text-white-A700"
                type="submit" onClick={scheduleMeeting}
              >Lets Meet</button>
            </form>
          </div>
        </div>
        <div className="desktop">
          <Top />
        </div>
      </div>
    </>
  )
};
export default Contact;