const imgs=[
    {id:0, 
    value:"images/Designcommunity-pana1.svg", 
    caption: "Set Your Worth To The World,",
    sp1:"One",
    sp2:"Stop",
    sp3:"Shop",
    content: "Ultravux is a place where our clients feel looked after and cared for..",
    title:"We are your,",
    Vid:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
    },
    {id:1, 
    value:"images/Programming&Tech.svg",
    content: "Let our competent developers and designers build your dreams. We’re creative in designing your ideas.",
    title:"Our agency is the best in programming and designing.",
    Vid:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
    },
    {id:2,
    value:"images/Poetry-pana1.svg",
    title: "Writing & Translation Services ",
    content: "Involve generating a context in the English language as accurately as that of the original.",
    content2: "Let Ultravux fuel your brand and use engaging and compelling content to enhance lead volume. ",
    Vid:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
  },
    {id:3,
    value:"images/Framsdse.svg",title: "Everybody has a story to tell. Yours is more powerful than you know. ",
    content: "Ultravux brings your brand to life and makes the intangible tangible.",
    Vid:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
    },
    {id:4,
    value:"images/Frame1244.svg",
    title: "Graphics and design are a process of visual communication. ",
    content: "With such services, you can solve any concern by using illustration, photography, and typography. Let ultravux help convey your brand’s story.",
    Vid:'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
    },
  ];
  export default imgs;