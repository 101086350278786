import React,{useState,useEffect } from "react";
import axios from 'axios'
import BASE_URL from '../components/constant';
const Terms = ()=>{
  const [contacts, setContact] = useState([]);
  useEffect(()=>{
    axios.get(`${BASE_URL}/general_settings.php`)
    .then(res => {
      console.log(res.data);
      setContact(res.data); // Assuming the response has a services array
    })
    .catch(err=>console.log(err));
    },[])
    const getContact = (index) => {
      console.log(contacts);
      if (contacts!=null ) {
        return contacts;
      }else{
      return {contact_no: "123456789",
      whatsapp_no: "987654321",
      address: "123 Main Street",
      email: "example@example.com",
      fb: "facebook",
      insta: "instagram",
      twitter: "twitter",
      linkedin: "linkedin",
      youtube: "youtube"};}; 
    };
    return(
    <>
        <div className="ww90" style={{paddingBottom:"5%", paddingTop:"4%"}}>
        <div className="termfr">
            <div className="terms-left ">
                <p className="terms-title1 sm:text-[45px] sm:font-bold	 sm:text-center md:text-center">
                ULTRAVUX 
                </p>
                <p className="terms-title2 sm:text-[34px] sm:text-center md:text-center sm:mb-4">
                Terms and Conditions  
                </p>
                <div className="terms-right mobile">
                <img src="../images/PrivacyPolicy.svg"/>
            </div>
            <div className="terms-right  tablet">
                <img className="w-full mt-4" src="../images/PrivacyPolicy.svg"/>
            </div>
                <p class="text-left text-justify text-blue_gray-900 mt-8 mt-20 text-lg sm:text-[16px] w-full font-lato font-normal">Welcome to UltraVux website, Digital Agency Company – www.ultravux.com. These terms and conditions (“Terms and Conditions”) apply to the Site, and all of its divisions, subsidiaries, and affiliate operated Internet sites, which reference these Terms and Conditions. </p>
            </div>
            <div className="terms-right desktop">
                <img src="../images/PrivacyPolicy.svg"/>
            </div>
            </div>
            <p class="text-left text-justify	 mt-[2%] sm:text-[16px] text-blue_gray-900 text-center text-lg font-lato font-normal">This website is own and operated by UltraVux Digital agency company Throughout the site, the terms "we", "us" and "our" refer to ultraVux. UltraVux Digital Agency Company offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.

            <br/><br/>These Terms of Service (“Terms”) are provided to guide your use of and relationship with our Site, web pages, platforms, applications, products and services. In these Terms, you may be addressed as “you”, “your” or “User”. The rules that follow are really important and form part of the Terms and Conditions of Service (“Terms”). If you don’t agree with these rules or any other portion of the Terms, please don’t use the Service.

             <br/><br/>By visiting UltraVux Digital Agency site and/ or purchasing something from us, you engage in our "Service" and agree to be bound by the following terms and conditions ("Terms of Service", "Terms"), including those additional terms and conditions and policies referenced herein and/or available by hyperlink.</p>
             <div className="flex flex-col gap-[37.09px] sm:gap-[15.09px] md:gap-[15.09px]  items-start justify-start mt-20 sm:mt-8 md:mt-8 mx-auto md:px-0 sm:px-0 w-full">
  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
    <p className="text-left text-4xl sm:text-[24px] md:text-[34px] text-blue_gray-900 w-auto font-lato font-semibold">Definitions of Terms</p>
    <p className="text-left text-blue_gray-900 text-lg sm:text-[16px] font-lato font-normal">
      <span className="text-gray-900 font-lato text-left font-normal">“User Website” – </span>
      <span className="text-blue_gray-900 font-lato text-left font-normal">The website of the User, where the Goods are disposed, advertised and where sale/purchase or other agreements are entered into between the User and the End Customer. www.ultravux.com<br /><br /></span>
      <span className="text-gray-900 font-lato text-left font-normal">"Services"</span>
      <span className="text-blue_gray-900 font-lato text-left font-normal"> means, means collectively any online facilities, tools, services or information that Ultra Vux Digital Company makes available through the website either now or in the future;<br /></span>
    </p>
  </div>

  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
    <p className="text-left text-4xl  sm:text-[24px] md:text-[34px] text-blue_gray-900 w-auto font-lato font-semibold">Our Service</p>
    <p className="text-left max-w-[1224px] md:max-w-full text-blue_gray-900 text-lg sm:text-[16px] font-lato font-normal">UltraVux is an all-in-one platform to discover the best digital services agencies in the world and we offer the following service Programming & Tech, Graphics & Design, Video & Animation And Writing & Translation.</p>
  </div>

  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
    <p className="text-left text-4xl sm:text-[24px] md:text-[34px] text-blue_gray-900 w-auto font-lato font-semibold">Refund Policy</p>
    <p className="text-left text-blue_gray-900 text-lg sm:text-[16px] w-auto font-lato font-normal">UltraVux Digital Agency Company issue (No refund) but we tend to revise project till buyer satisfaction (T&C Applied). </p>
  </div>

  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
    <p className="text-left text-4xl sm:text-[24px] md:text-[34px] text-blue_gray-900 w-auto font-lato font-semibold">Online Store Terms</p>
    <p className="text-left text-blue_gray-900 text-lg sm:text-[16px] w-auto font-lato font-normal">We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction.</p>
  </div>

  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
    <p className="text-left text-4xl sm:text-[24px] md:text-[34px] text-blue_gray-900 w-auto font-lato font-semibold">General Conditions</p>
    <p className="text-left max-w-[1222px] md:max-w-full text-blue_gray-900 text-lg sm:text-[16px] font-lato font-normal">You agree that you will not, without our prior written consent, reproduce, duplicate, copy, sell, resell or exploit any part of the Service, use of the Service, access to the Service or any contact on the website through which the service is given.</p>
  </div>

  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
    <p className="text-left text-4xl sm:text-[24px] md:text-[34px] text-blue_gray-900 w-auto font-lato font-semibold">Warranty Disclaimer</p>
    <p className="text-left max-w-[1222px] md:max-w-full text-blue_gray-900 text-lg sm:text-[16px] font-lato font-normal">YOU AGREE YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, ULTRA VUX DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE OF THE SERVICES. </p>
  </div>

  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
    <p className="text-left text-4xl sm:text-[24px] md:text-[34px] text-blue_gray_900 w-auto font-lato font-semibold">Limitation of Liability</p>
    <p className="text-left max-w-[1222px] md:max-w-full text-blue_gray-900 text-lg sm:text-[16px] font-lato font-normal">IN NO EVENT SHALL ULTRA VUX, ITS SUPPLIERS, OR SERVICE PROVIDERS, OR THEIR OFFICERS, MEMBERS, DIRECTORS, EMPLOYEES, CONTRACTORS, OR AGENTS, BE LIABLE FOR LOST PROFITS OR ANY SPECIAL, INDIRECT, PUNITIVE, INCIDENTAL.</p>
  </div>

  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
    <p className="text-left text-4xl sm:text-[24px] md:text-[34px] text-blue_gray_900 w-auto font-lato font-semibold">Governing Law and Jurisdiction</p>
    <p className="text-left text-blue_gray_900 text-lg sm:text-[16px] w-auto font-lato font-normal">The Site shall be governed by the laws of the India including federal copyright and trademark laws, and the laws of the state of New Delhi, India. </p>
  </div>

  <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
    <p className="text-left text-4xl sm:text-[24px] md:text-[34px] text-blue_gray_900 w-auto font-lato font-semibold">Complaints, Feedbacks or Comments</p>
    <p className="text-left text-blue_gray_900 text-lg sm:text-[16px] w-auto font-lato font-normal">Please contact us at {getContact(0).email} </p>
  </div>
</div>
        </div>
    </>
    )
};
export default Terms;