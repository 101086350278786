// BlogsPost.jsx
import React, { useState,useEffect } from 'react';

// import BlogsData from "../components/BlogsData";
import { Link } from 'react-router-dom';
import BASE_URL from '../components/constant';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios'
import { Markup } from 'interweave';
import HomeClient from '../Pages/Clients';
import {Autoplay} from 'swiper/modules';


const BlogsPost = () => {
  const [BlogsData, setBlog] = useState([]);

  useEffect(() => {
    // Fetch data from your API using Axios
    axios
      .get(`${BASE_URL}/blogs_api.php`)
      .then(response => {
        setBlog(response.data);
        // Initialize the swiperRefs array based on the fetched data length
        // swiperRefs.current = Array(response.data.length).fill(null).map(() => useRef());
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  function truncateString(str, maxLength) {
    return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
  }
  return (
    <>
      <div className="ww90" style={{paddingBottom:"8%",paddingTop:"4%"}}>
      {/* Tablet */}
<div className='tablet'>
<div class="a_heading mb-10 md:mb-10 sm:mb-2 w-[130px]">
  <div class="a_line-12"></div>
  <div class="a_about-us">Blogs</div>
  <div class="a_line-13"></div>
</div>
              <Swiper 
              slidesPerView={2.5}
        spaceBetween={60}
        loop={true}
        autoplay={{
        delay: 1500,
      }}
      modules={[Autoplay]}

        >
              {BlogsData.map((item) => (
              <SwiperSlide>
              <div key={item.id} className="Blog-cont bg-white-A700 blogbdr  justify-start pb-7 sm:pb-4 rounded-[24px] shadow-bs1 w-full">
              <img src={item.image} alt={item.title} style={{borderRadius:"16px", borderBottomRightRadius:"0px", borderBottomLeftRadius:"0px"}} />
             
             
              <div className="p-2">
                <p className="text-left sm:text-2xl md:text-[16px] text-[28px] text-black-900 font-lato font-semibold">{item.title}</p>
                <Markup className='text-left text-[12px]' content={truncateString(item.description, 65)} /> 
            
                <Link className="text-left text-red-400 mt-2.5 mr-2 text-[12px] font-bold font-lato blog-read" to={`/Pages/Blog/${item.id}`}>Read More</Link>
              </div>
              </div>
              </SwiperSlide>
              ))}
              </Swiper>

              </div>
              {/* Tablet */}
        <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 sm:gap-8">
          {BlogsData.map((item) => (
            <>
            <div key={item.id} className="Blog-cont bg-white-A700 blogbdr mobile justify-start pb-7 sm:pb-4 rounded-[24px] shadow-bs1 w-full">
              <img src={item.image} alt={item.title} style={{borderRadius:"16px", borderBottomRightRadius:"0px", borderBottomLeftRadius:"0px"}} />
             
             
              <div className="p-4">
                <p className="text-left sm:text-2xl md:text-[26px] text-[28px] text-black-900 font-lato font-semibold">{item.title}</p>
                <Markup content={truncateString(item.description, 65)} /> 
            
                <Link className="text-left text-red-400 mt-2.5 font-bold font-lato blog-read" to={`/Pages/Blog/${item.id}`}>Read More</Link>
              </div>
              </div>
              
              

              <div key={item.id} className="Blog-cont bg-white-A700 desktop  justify-start pb-7 sm:pb-0 rounded-[24px] shadow-bs1 w-full">
              <img src={item.image} alt={item.title} style={{borderRadius:"16px", borderBottomRightRadius:"0px", borderBottomLeftRadius:"0px"}} />
             
             
              <div className="p-4">
                <p className="text-left sm:text-2xl md:text-[26px] text-[28px] text-black-900 font-lato font-semibold">{item.title}</p>
                <Markup content={truncateString(item.description, 60)} /> 
            
                <Link className="text-left text-red-400 mt-2.5 font-bold font-lato blog-read" to={`/Pages/Blog/${item.id}`}>Read More</Link>
              </div>
              </div>
            </>
            
              
          ))}
        </div>
        
      </div>
    </>
  );
};

export default BlogsPost;
