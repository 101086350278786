import { createContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      console.log(user);
    });

    return () => {
      unsub();
    };
  }, []);
  const saveUserData = (userData) => {
    // Update the current user state
    setCurrentUser((prevUser) => ({
      ...prevUser,
      ...userData,
    }));

    // Save user data to local storage
    localStorage.setItem("userData", JSON.stringify({ ...currentUser, ...userData }));
  };

  const getUserData = () => {
    // Retrieve user data from local storage
    const storedUserData = localStorage.getItem("userData");
    return storedUserData ? JSON.parse(storedUserData) : {};
  };
  const emptyCurrentUser = () => {
    // Reset the current user state to an empty object
    setCurrentUser({});
    // Optionally, remove user data from local storage
    localStorage.removeItem("userData");
  };
  return (
    <AuthContext.Provider value={{ currentUser,saveUserData, getUserData, emptyCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
