// PortfolioRow.js
import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/react';
import { NavLink, Link } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import ReactPlayer from 'react-player'
import 'swiper/css';
import 'swiper/css/navigation';
import '../Pages/Portfolio.css';

const PortfolioRow = ({ items }) => {
  
  const swiperRef = useRef(null);
  

  return (
<div className="port-Main flex mt-10 gap-10 sm:w-full">
  {items.map((tech, index) => (
    <div key={index} className="main-slide port-slde">
      <Swiper
        navigation={true}
        modules={[Navigation]}
        loop={true}
        className="portSwiper"
      >
        {tech.list_images && Array.isArray(tech.list_images) && tech.list_images.map((image, imageIndex) => (
          <SwiperSlide key={imageIndex}>
             {image.toLowerCase().includes('.mp4') || image.toLowerCase().includes('.avi') || image.toLowerCase().includes('.mkv') ? (
              <>
      {/* <video style={{ height: '100%', maxHeight:"450px", width: '100%', borderRadius:"24px" }} controls>
        <source src={image} type="video/mp4" />
      </video> */}
      <ReactPlayer
      className='video-hw portfolio-page-video'
      url={image}
      controls
      style={{width:"100%"}}
      playsinline
    />
    </>
    ) : (
      <img className="round_con mobile-port-reposnic" style={{height:" 280px" , width:" 100%"}} src={image} alt={`${tech.name} Image ${imageIndex + 1}`} />
    )}
          </SwiperSlide>
        ))}
      </Swiper>
      <a href={`/Pages/Service/${tech.category.id}/${tech.title}`}>
      <p className="text-center mt-4 sm:text-[18.25px] md:text-[24.25px] text-[22.25px] text-blue_gray-900 w-auto font-lato font-semibold">
        {tech.title}
      </p>
      </a>
    </div>
  ))}
</div>


  //   <div className="port-Main flex mt-10 gap-10">


  //   {items.map((tech, index) => (
  //     <div key={index} className="main-slide">
  //       <Swiper
  //        navigation={true}
  //         modules={[Navigation]}
  //         className="portSwiper"
  //       >
  //         <SwiperSlide>
  //           <img className="round_con" src={tech.list_images[0]} alt={`${tech.name} Image 1`} />
  //         </SwiperSlide>
  //         <SwiperSlide>
  //           <img className="round_con" src={tech.list_images[1]} alt={`${tech.name} Image 2`} />
  //         </SwiperSlide>
  //         <SwiperSlide>
  //           <img className="round_con" src={tech.list_images[2]} alt={`${tech.name} Image 3`} />
  //         </SwiperSlide>
  //       </Swiper>
       
  //       <p className="text-center mt-4 sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-blue_gray-900 w-auto font-lato font-semibold">
  //         {tech.title}
  //       </p>
      
  //     </div>
  //   ))}
  // </div>
  );
};

export default PortfolioRow;