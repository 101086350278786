import React, { useRef, useState, useEffect } from 'react';
import "./HomePortfolio.css";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ReactPlayer from 'react-player'

import 'swiper/css';
import 'swiper/css/navigation';
import './Pages/HomeServices.css';
import axios from 'axios'
import BASE_URL from '../src/components/constant';

const HomePortfolio= () =>{
  const swiperRefs = useRef([]);
  const [portfolioData, setPortfolioData] = useState([]);
  const servicename =(id) =>  {
    console.log(id);
 return id == 1
    ? 'Full Website Creation'
    :id ===2
    ? 'Articles & Blog Posts'
    : id == 3
    ? 'Video Editing'
    : 'Logo Design';
   } ;
  useEffect(() => {
    // Fetch data from your API using Axios
    axios
      .get(`${BASE_URL}/categories_api.php`)
      .then(response => {
        setPortfolioData(response.data);
        // Initialize the swiperRefs array based on the fetched data length
        // swiperRefs.current = Array(response.data.length).fill(null).map(() => useRef());
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
      arr.slice(index * size, (index + 1) * size)
    );
  };
    return (
        <>
     

             <div className="flex flex-col items-center justify-start max-w-[100%] mt-32 md:mt-10 mx-auto md:px-0 w-full">
          <div className="flex flex-col gap-[46px] md:gap-[0px] items-center justify-start w-full">
            <div className="flex flex-col gap-2.5 items-center justify-start w-[81%] md:w-full">
              <div className="flex h-[58px] justify-end relative w-[100%]">

                <p
                  className="absolute h-full inset-[0] justify-center m-auto text-5xl sm:text-[38px] md:text-[44px] text-center text-red-400 w-max"
                  size="txtLatoBold48"
                >
                  Portfolio
                  {/* <span className='service-underline'>&nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span> */}
                  <span className='service-underline'> <span style={{color:"white"}}> Portfoli</span> </span>

                </p>
              </div>
              <p
                className="desktop ipadbtms leading-[150.00%] text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl"
                size="txtLatoRegular24"
              >
                <>
                Explore our portfolio of past projects to discover what we can achieve for your business. Ready to make a change? Let's collaborate and bring your vision to life!{" "}
                </>
              </p>
              <p
                className="mobile leading-[150.00%] text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl"
                size="txtLatoRegular24"
              >
               <span>Look through the portfolio of previous projects to get an idea
                  of what we can do for your business.</span>
                  <span>
                  <br></br>
                  Are you ready to make a change? Let’s build this thing
                  together!
                  </span>
              </p>
              <p
                className="tablet leading-[150.00%] text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl"
                size="txtLatoRegular24"
              >
               <span>Look through the portfolio of previous projects to get an idea
                  of what we can do for your business.</span>
                  <span>
                  <br></br>
                  Are you ready to make a change? Let’s build this thing
                  together!
                  </span>
              </p>
              </div>
               {chunkArray(portfolioData, 2).map((row, rowIndex) => (
        <div className="Pcont sm:px-[20px]" key={rowIndex}>
          {row.map((category) => (
            <div className="Pitem rond shadow-bs9" key={category.id}>
              <div className="Pmain">
                <div className="Sinside">
                  <div style={{ position: 'relative' }}>
                    <Swiper
                      modules={[Navigation]}
                      freeMode={true}
                       loop={true}
                      onBeforeInit={(swiper) => {
                        // swiperRefs.current[rowIndex * 2 + category.id] = swiper;
                        if (!swiperRefs.current[rowIndex]) {
                          swiperRefs.current[rowIndex] = [];
                        }
                        swiperRefs.current[rowIndex][category.id] = swiper;
                    
                      }}
                    >
                      {category.images.map((image, imageIndex) => (
                        <SwiperSlide key={imageIndex}>
                          {image.toLowerCase().includes('.mp4') || image.toLowerCase().includes('.avi') || image.toLowerCase().includes('.mkv') ? (
                            <>
                            {/* <video className='tabprtheig' playsinline style={{ maxHeight: '400px', objectFit:"cover", width: '100%', borderRadius:"24px", borderBottomRightRadius:"0", borderBottomLeftRadius:"0" }} controls>
        <source src={image} type="video/mp4" />
      </video> */}
      <ReactPlayer
      className='tabprtheig '
      url={image}
      controls
      style={{width:"100%"}}
      playsinline
    />
        </>
    ) : (
      <img className='tabprtheig' src={image} style={{ maxHeight: '400px', width: '100%', borderRadius:"16px", borderBottomRightRadius:"0", borderBottomLeftRadius:"0"}} alt={`Image ${imageIndex + 1}`} />
    )}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <button className='swiper-button-prev video-bnt' 
        style={{    position: "absolute", left: "-17px", top:"55%", background: "#EC4F5C", width: "30px", height: "30px"}}  onClick={()  => swiperRefs.current[rowIndex][category.id]?.slidePrev()}></button>
        <button className='swiper-button-next video-bnt' 
        style={{    position: "absolute", right: "-17px", top:"55%", background: "#EC4F5C", width: "30px", height: "30px"}} onClick={() =>  swiperRefs.current[rowIndex][category.id]?.slideNext()}></button>
      
                  </div>
          
                  <a href={`/Pages/Service/${category.id}/${servicename(category.id)}`}>
                    <p className="text-left sm:text-[25.81px] sm:pb-[16px] mt-6 mb-6 md:text-[27.81px] md:py-[20px] md:mt-[0px] text-[29.81px] text-center text-gray-900 font-lato font-semibold">
                      {category.name}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
            
              </div>
              </div>
        </>
        )
    };
    export default HomePortfolio;