import React from "react";
import "./HomeWhy.css"

const usHome  =()=>{
    const cardData =[
        {imgsrc:"images/business1.svg", title:"Expert Members"},
        {imgsrc:"images/img_businessdealconverted.svg", title:"100% Satisfaction"},
        {imgsrc:"images/customer_support3.svg", title:"24/7 Support"},
        {imgsrc:"images/Group 1304.svg", title:"Dedicated To Work"},

    ];
    return (
        <> <div className="flex flex-col items-center justify-start max-w-[1224px] mt-[136px] md:mt-10 mx-auto md:px-0 w-full">
        <div className="flex flex-col gap-9 items-center justify-start w-full sm:gap-0 md:gap-0  sm:mb-4">
          <div className="flex flex-col gap-5 items-center justify-start w-auto md:w-full">
            <div className="h-[34px] relative w-[100%]">

              <p
                className="text-left absolute h-full inset-[0] justify-center m-auto sm:text-2xl md:text-[26px] text-[28px] text-red-400 w-max font-lato font-semibold"
                size="txtLatoSemiBold28"
              >
                Why Us
                <span className='service-underline'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span>
              </p>
            </div>
            <p
              className="text-left text-4xl sm:text-[32px] md:text-[34px] text-gray-900 w-auto font-lato font-semibold"
              size="txtLatoSemiBold36Gray900_1"
            >
              Why Choose Us
            </p>
            <p
              className="text-left leading-[150.00%] max-w-[1000px] md:max-w-full text-2xl md:text-[22px] text-center text-gray-900 sm:text-xl font-lato font-normal"
            
            >
              When you choose us, you're hiring an experienced team of editors, graphic
              designers, developers, programmers, Animators and content
              writers. We approach/handle every project with a conceptual mindset, seeking innovative solutions to effectively convey your brand's story.
            </p>
            <br></br>
          </div>
          <div className="flex main-why">
{/* Ist Row */}
          <div className="wrow1 shadow-bs9 rounded-[13px]">
            <img src={cardData[0].imgsrc}/>
            <p className="text-left  sm:text-[25.81px] text-center sm:mb-[0px] md:mb-[0px]	mb-8 mt-2 md:text-[27.81px] text-[29.81px] text-gray-900 font-bold font-lato">
                {cardData[0].title}
            </p>
          </div>
          <div className="wrow1 shadow-bs9 rounded-[13px]">
            <img src={cardData[1].imgsrc}/>
            <p className="text-left  sm:text-[25.81px] text-center	sm:mb-[0px] md:mb-[0px] mb-8 mt-2 md:text-[27.81px] text-[29.81px] text-gray-900 font-bold font-lato">
                {cardData[1].title}
            </p>
          </div>
          </div>
  {/* 2nd Row */}
  <div className="flex main-why md:mt-[-10%] md:mt-[0%]">
  <div className="wrow2 shadow-bs9 rounded-[13px] ">
  <img src={cardData[2].imgsrc}/>
            <p className="text-left  sm:text-[25.81px] text-center sm:mb-[0px] md:mb-[0px] 	mb-8 mt-2 md:text-[27.81px] text-[29.81px] text-gray-900 font-bold font-lato">
                {cardData[2].title}
            </p>
  </div>
  <div className="wrow2 shadow-bs9 rounded-[13px]">
  <img src={cardData[3].imgsrc}/>
            <p className="text-left  sm:text-[25.81px] text-center sm:mb-[0px] md:mb-[0px] 	mb-8 mt-2 md:text-[27.81px] text-[29.81px] text-gray-900 font-bold font-lato">
                {cardData[3].title}
            </p>
  </div>
  </div>
  <div className="text-center sm:mt-[10px] mt-10 mb-10">
  <a href="/Pages/Why" class="home-btn font-semibold  min-w-[179px] rounded-[27px] shadow-bs5 text-center text-xl   bg-red-400 text-white-A700">Learn More</a>
  </div>
          
          </div>
          </div>
      </>
    )
};

export default usHome;