import React, { useState,useEffect,useContext } from "react";
import ProfileAbout from "./ProfileAbout";
import ProfileOrders from "./ProfileOrders";
import ProfileMesages from "./ProfileMessages";
import Invoice from "./Invoices";
import Support from "./Support";
import { useNavigate } from 'react-router-dom';
import { signOut } from '../services/AuthServices';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Profile=()=>{
    

  const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [deskactiveTab, desksetActiveTab] = useState(10);
    const [mobileactiveTab, mobilesetActiveTab] = useState(100);
  

    const handleTabClick = (tabNumber) => {
      setActiveTab(tabNumber);
    };
    const  deskhandleTabClick= (tabNumber) => {
      desksetActiveTab(tabNumber);
    };
    const mobilehandleTabClick = (tabNumber) => {
      mobilesetActiveTab(tabNumber);
    };

    useEffect(() => {
      if (activeTab === 60 || deskactiveTab === 6 || mobileactiveTab === 600) {
        signOuts();
      }
    }, [activeTab, deskactiveTab, mobileactiveTab]);
    
   
  
    const signOuts = async () => {

      await signOut(navigate);
    
    };
    return(
    <>
     <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          draggable
          closeOnClick
          pauseOnHover
          theme="colored" />
    {/* Tablet */}
    <div className="tablet" style={{ display: 'flex', flexDirection: 'row', paddingTop:"0", paddingBottom:"0", width:"100%" }}>
      <div style={{ display: 'flex', flexDirection: 'column', background:'#F2F9FF', marginTop:"1px" }}>
        <div
          onClick={() => handleTabClick(1)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: activeTab === 1 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: activeTab === 1 ? '#C3E3FF' : 'transparent',
          }}
        >
         <p className="text-left  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
         About me</p>
        </div>
        <div
          onClick={() => handleTabClick(2)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: activeTab === 2 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: activeTab === 2 ? '#C3E3FF' : 'transparent',
          }}
        >
         <p className="text-left  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
         Orders</p>
        </div>
        <div
          onClick={() => handleTabClick(3)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: activeTab === 3 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: activeTab === 3 ? '#C3E3FF' : 'transparent',
          }}
        >
          <p className="text-left  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
         Messages</p>
        </div>
        <div
          onClick={() => handleTabClick(4)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: activeTab === 4 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: activeTab === 4 ? '#C3E3FF' : 'transparent',
          }}
        >
          <p className="text-left  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
          Invoices</p>
        </div>
        <div
          onClick={() => handleTabClick(5)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: activeTab === 5 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: activeTab === 5 ? '#C3E3FF' : 'transparent',
          }}
        >
          <p className="text-left  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
          Support & Help</p>
        </div>
        <div
          onClick={() => handleTabClick(60)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: activeTab === 60 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: activeTab === 60 ? '#C3E3FF' : 'transparent',
          }}
        >
          <p className="text-left logout  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
          Log out</p>
        </div>
      </div>

      <div className="profile-right">
        {activeTab === 1 && 
        <ProfileAbout/>}
        {activeTab === 2 && 
        <ProfileOrders/>
        }
        {activeTab === 3 && 
        <ProfileMesages/>
        }
        {activeTab === 4 && 
       <Invoice/>
        }
        {activeTab === 5 && 
        <Support/>
        }
         {/* {activeTab === 5 && 
        <Support/>
        } */}
       
      </div>
    </div>
   {/* Desktop */}
     <div className="ww90 desktop" style={{ display: 'flex', flexDirection: 'row', paddingTop:"0", paddingBottom:"0", width:"100%" }}>
      <div style={{ display: 'flex', flexDirection: 'column', background:'#F2F9FF', marginTop:"1px" }}>
        <div
          onClick={() => deskhandleTabClick(10)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: deskactiveTab === 10 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: deskactiveTab === 10 ? '#C3E3FF' : 'transparent',
          }}
        >
         <p className="text-left  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
         About me</p>
        </div>
        <div
          onClick={() => deskhandleTabClick(20)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: deskactiveTab === 20 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: deskactiveTab === 20 ? '#C3E3FF' : 'transparent',
          }}
        >
         <p className="text-left  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
         Orders</p>
        </div>
        <div
          onClick={() => deskhandleTabClick(30)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: deskactiveTab === 30 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: deskactiveTab === 30 ? '#C3E3FF' : 'transparent',
          }}
        >
          <p className="text-left  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
         Messages</p>
        </div>
        <div
          onClick={() => deskhandleTabClick(40)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: deskactiveTab === 40 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: deskactiveTab === 40 ? '#C3E3FF' : 'transparent',
          }}
        >
          <p className="text-left  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
          Invoices</p>
        </div>
        <div
          onClick={() => deskhandleTabClick(50)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: deskactiveTab === 50 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: deskactiveTab === 50 ? '#C3E3FF' : 'transparent',
          }}
        >
          <p className="text-left  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
          Support & Help</p>
        </div>
        <div
          onClick={() => deskhandleTabClick(6)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: deskactiveTab === 6 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: deskactiveTab === 6 ? '#C3E3FF' : 'transparent',
          }}
        >
          <p className="text-left logout  border-b border-black-900_3f border-solid justify-center pl-[18px] sm:pr-5 pr-[35px] py-[18px] text-black-900 text-lg w-[291px] font-lato font-normal">
          Log out</p>
        </div>
      </div>

      <div className="profile-right">
        {deskactiveTab === 10 && 
        <ProfileAbout/>}
        {deskactiveTab === 20 && 
        <ProfileOrders/>
        }
        {deskactiveTab === 30 && 
        <ProfileMesages/>
        }
        {deskactiveTab === 40 && 
       <Invoice/>
        }
        {deskactiveTab === 50 && 
        <Support/>
        }
         {/* {activeTab === 5 && 
        <Support/>
        } */}
       
      </div>
    </div>
    {/* Mobile */}
    <div className="mobile" style={{paddingLeft:"5%", marginTop:"2%"}}>
    <Swiper
       slidesPerView={3.1}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
        mousewheel={true}
        keyboard={true}
        className="mySwiper"
      >
        <SwiperSlide>
        <div
          onClick={() => mobilehandleTabClick(100)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: mobileactiveTab === 100 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: mobileactiveTab === 100 ? '#C3E3FF' : '#F2F9FF',
            borderRadius:"24px",
            marginBottom:"10px",
          }}
        >
         <p className="text-center   w-full justify-center py-[5px] px-[4px] text-black-900 text-[14px]  font-lato font-semibold">
         About me</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          onClick={() => mobilehandleTabClick(200)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: mobileactiveTab === 200 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: mobileactiveTab === 200 ? '#C3E3FF' : '#F2F9FF',
            borderRadius:"24px",
            marginBottom:"10px",
          }}
        >
         <p className="text-center   w-full justify-center py-[5px] px-[4px] text-black-900 text-[14px]  font-lato font-semibold">
         Orders</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          onClick={() => mobilehandleTabClick(300)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: mobileactiveTab === 300 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: mobileactiveTab === 300 ? '#C3E3FF' : '#F2F9FF',
            borderRadius:"24px",
            marginBottom:"10px"
          }}
        >
         <p className="text-center   w-full justify-center py-[5px] px-[4px] text-black-900 text-[14px]  font-lato font-semibold">
         Messages</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          onClick={() => mobilehandleTabClick(400)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: mobileactiveTab === 400 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: mobileactiveTab === 400 ? '#C3E3FF' : '#F2F9FF',
            borderRadius:"24px",
            marginBottom:"10px"
          }}
        >
         <p className="text-center  w-full justify-center py-[5px] px-[4px] text-black-900 text-[14px]  font-lato font-semibold">
         Invoices</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          onClick={() => mobilehandleTabClick(500)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: mobileactiveTab === 500 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: mobileactiveTab === 500 ? '#C3E3FF' : '#F2F9FF',
            borderRadius:"24px",
            marginBottom:"10px"
          }}
        >
         <p className="text-center w-full justify-center py-[5px] px-[4px] text-black-900 text-[14px]  font-lato font-semibold">
         Support & Help</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div
          onClick={() => mobilehandleTabClick(600)}
          style={{
            padding: '0px',
            cursor: 'pointer',
            borderRight: mobileactiveTab === 600 ? '2px solid #C3E3FF' : '2px solid transparent',
            background: mobileactiveTab === 600 ? '#C3E3FF' : '#F2F9FF',
            borderRadius:"24px",
            marginBottom:"10px",
            }}
        >
         <p className="text-center  w-full justify-center py-[5px] px-[4px] text-black-900 text-[14px]  font-lato font-semibold">
          Log out</p>
        </div>
      </SwiperSlide>
      </Swiper>
    </div>
    <div className="profile-right mobile">
        {mobileactiveTab === 100 && 
        <ProfileAbout/>}
        {mobileactiveTab === 200 && 
        <ProfileOrders/>
        }
        {mobileactiveTab === 300 && 
        <ProfileMesages/>
        }
        {mobileactiveTab === 400 && 
       <Invoice/>
        }
        {mobileactiveTab === 500 && 
        <Support/>
        }
         {/* {activeTab === 5 && 
        <Support/>
        } */}
       
      </div>

    </>)
};

export default Profile;