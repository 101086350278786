import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Nav from './Nav';
import Footer from './Fotter';
import Hero from './Hero';
import Founder from './Pages/Founder';
import HomeServices from './Pages/HomeServices';
import HomePortfolio from "./HomePortfolio";
import UsHome from './UsHome';
import HomeClient from './Pages/Clients';
import Rated from './Pages/TopRated';
import Homeblog from './Pages/HomeBlogs';

const Home =() =>{
    return(
        <>
        
        <div className='ww90' style={{paddingTop:'0%',}}>
            <Hero/>
<Founder/>
<HomeServices/>
<HomePortfolio/>
<UsHome/>
<HomeClient/>
<div className='desktop'>
<Homeblog/>

<Rated/>
</div>
</div>
    
        </>
    )
};

export default Home;