import React, { useState,useContext } from 'react'
import { auth,firestore,provider } from '../firebase';
import { addDoc, collection, namedQuery } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { loginUser, handleGoogleLogin,retrieveDataFromFirestore ,updateUserData,deleteDocumentByEmail } from '../services/AuthServices';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../context/AuthContext";



import BASE_URL from '../components/constant';
import axios from 'axios';
// import { toast } from 'react-toastify';


const Login = () => {
    const { saveUserData, getUserData, emptyCurrentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   if (!email) {
  //       alert('Please fill in the email field');
  //       return;
  //     }
  //     if (!isValidEmail(email)) {
  //       alert('Please fill in a valid email address');
  //       return;
  //     }
  //     if (!password) {
  //       alert('Please fill in the password field');
  //       return;
  //     }
  //   try {
  //     const userCredential = await signInWithEmailAndPassword(auth, email, password);
  //     const user = userCredential.user;

     
  //     if (user.emailVerified) {
  //       console.log('User logged in successfully:', user);
  //       console.log('User logged in:', user);
  //       const userData = {
      
  //         password: password,
  //         email: email,
      
  //       };
       
  //       loginUser(userData)
  //       .then(async (response) => {
  //           if (response && response.status === 404) {
  //               alert("Login failed:User not found");
  //           }else if(response.status===205){
  //             console.log("Login failed:invalid Credientials");
  //             try {
  //               const list = await retrieveDataFromFirestore();
            
  //               // Replace 'desiredEmail@example.com' with the email you want to check
  //               // const emailToFind = 'desiredEmail@example.com';
            
  //               // Check if email is in the list
  //               const foundDocument = list.find(doc => doc.email === email);
            
  //               if (foundDocument) {
  //                 console.log('Email found in the list:', foundDocument);
  //                 updateUserData(email,user.uid,password,); 
                 
  //                 loginUser(userData)
  //       .then(async (response) => {
  //           if (response && response.status === 404) {
  //               alert("Login failed:User not found");
  //           }else if(response.status===205){
  //             console.log("Login failed:invalid Credientials");
  //             try {
  //               const list = await retrieveDataFromFirestore();
            
  //               // Replace 'desiredEmail@example.com' with the email you want to check
  //               // const emailToFind = 'desiredEmail@example.com';
            
  //               // Check if email is in the list
  //               const foundDocument = list.find(doc => doc.email === email);
            
  //               if (foundDocument) {
  //                 console.log('Email found in the list:', foundDocument);
  //                 updateUserData(email,user.uid,password,foundDocument.id); 
  //                 loginUser(userData);
  //               } else {
  //                 console.log('Email not found in the list.');
  //               }
  //             } catch (error) {
  //               console.error('Error checking email in the list:', error);
  //             }

    
  //             // alert("Login failed:invalid Credientials");
  //           }else{
  //             console.log(response.user_details);
  //             saveUserData(response.user_details);
  //               navigate('/Profile/Profile');
  //           }
         
  //       })
  //       .catch((error) => {
  //         // if (error.message === 'auth/user-not-found') {
  //         //     alert('Email not found. Please check your email address.');
  //         //   } else if (error.message === 'auth/wrong-password') {
  //         //     alert('Invalid password. Please check your password.');
  //         //   } else if (error.message === 'auth/invalid-login-credentials') {
  //         //     alert('Invalid login credentials. Please check your email and password.');
  //         //   } else {
  //             console.error('Login failed:', error.message,error.code);
  //         //   }
  //       });
  //               } else {
  //                 console.log('Email not found in the list.');
  //               }
  //             } catch (error) {
  //               console.error('Error checking email in the list:', error);
  //             }

    
  //             // alert("Login failed:invalid Credientials");
  //           }else{
  //             console.log(response.user_details);
  //             saveUserData(response.user_details);
  //               navigate('/Profile/Profile');
  //           }
         
  //       })
  //       .catch((error) => {
  //         // if (error.message === 'auth/user-not-found') {
  //         //     alert('Email not found. Please check your email address.');
  //         //   } else if (error.message === 'auth/wrong-password') {
  //         //     alert('Invalid password. Please check your password.');
  //         //   } else if (error.message === 'auth/invalid-login-credentials') {
  //         //     alert('Invalid login credentials. Please check your email and password.');
  //         //   } else {
  //             console.error('Login failed:', error.message,error.code);
  //         //   }
  //       });
  //       // Perform actions after successful login
  //       // Modify the return value as needed
  //     } else {
  //       console.error('Email not verified. Please verify your email.');
  //       auth.signOut();
  //       // You can handle the case where email is not verified, for example, show a message to the user
  //       alert('Email not verified. Please verify your email.');
  //     }
     
  //   } catch (error) {
  //       if (error.code === 'auth/user-not-found') {
  //           alert('Email not found. Please check your email address.');
  //         } else if (error.code === 'auth/wrong-password') {
  //           alert('Invalid password. Please check your password.');
  //         } else if (error.code === 'auth/invalid-login-credentials') {
  //           alert('Invalid login credentials. Please check your email and password.');
  //         } else {
  //           console.error('Login failed:', error.message,error.code);
  //         }
  //   //   console.error('Login failed:', error.message);
  //   }
  // };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      if (user.emailVerified) {
        // User is authenticated and email is verified
        handleSuccessfulLogin(user);
      } else {
        // Email not verified
        console.error('Email not verified. Please verify your email.');
        auth.signOut();
       toast.error('Email not verified. Please verify your email.');
        
      }
    } catch (error) {
      handleLoginError(error);
    }
  };
  
  const handleSuccessfulLogin = (user) => {
    console.log('User logged in successfully:', user);
    const userData = {
      password: password,
      email: email,
    };
  
    loginUser(userData)
      .then((response) => {
        if (response && response.status === 404) {
          toast.error('Login failed: User not found');
        } else if (response.status === 205) {
          console.log('Login failed: Invalid Credentials');
          // Check and update Firestore data if necessary
          checkAndUpdateFirestoreData(user);
        } else {
          console.log(response.user_details);
          saveUserData(response.user_details);
          navigate('/Profile/Profile');
        }
      })
      .catch((error) => {
        handleLoginError(error);
      });
  };
  
  const checkAndUpdateFirestoreData = async (user) => {
    try {
      const list = await retrieveDataFromFirestore();
      const foundDocument = list.find((doc) => doc.email === email);
  
      if (foundDocument) {
        console.log('Email found in the list:', foundDocument);
        updateUserData(email, user.uid, password, foundDocument.id);
        loginUser({
          password: password,
          email: email,
        });
      } else {
        console.log('Email not found in the list.');
      }
    } catch (error) {
      console.error('Error checking email in the list:', error);
      handleLoginError(error);
    }
  };
  
  const handleLoginError = (error) => {
    if (error.code === 'auth/user-not-found') {
      toast.error('Email not found. Please check your email address.');
    } else if (error.code === 'auth/wrong-password') {
      toast.error('Invalid password. Please check your password.');
    } else if (error.code === 'auth/invalid-login-credentials') {
      toast.error('Invalid login credentials. Please check your email and password.');
    } else {
      console.error('Login failed:', error.message, error.code);
    }
  };
  
  const isValidEmail = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};
 

const handleGoogleLogins = async () => {
  await  handleGoogleLogin(navigate,saveUserData);
};

    return (
        <>
            <div className="ww90" style={{paddingBottom:"8%", marginBottom:"100px"}}>
                <div className="log-main">
                <ToastContainer />
                    <div className="log-left desktop">
                        <img src="../images/Completedsteps-pana.svg" />
                    </div>
                    <div className="log-left tablet">
                        <img src="../images/Completedsteps-pana.svg" />
                    </div>
                    <div className="log-right">

                        <form method="get">
                            <div className="flex sm:flex-1 flex-col gap-[36.4px] items-center justify-center w-[455px] sm:w-full md:w-full ">
                                <div className="flex flex-col items-start justify-start w-full">
                                    <p className="text-left sm:text-[26.28px] md:text-[28.28px] text-[30.28px] text-gray-900_09 w-auto font-poppins font-semibold">Login to your account</p>
                                </div>
                                <div className="flex flex-col gap-[27.3px] items-start justify-start w-full">
                                    <div className="flex flex-col gap-[13.65px] items-start justify-start w-full">
                                        <div className="flex flex-col items-start justify-start w-full">
                                            <p className="text-left capitalize text-[18.2px] text-gray-700 w-auto font-normal font-poppins">Email</p>
                                        </div>
                                        <div className="flex flex-col h-[54px] md:h-auto items-start justify-start w-[455px] sm:w-full md:w-full">
                                            <div className="w-full rounded-[9px] outline outline-[1.5px] outline-blue-50_04 text-gray-700 pb-3.5 pt-[18px] px-3.5">
                                                <input className="p-0 placeholder:text-gray-700 text-left text-sm w-full bg-transparent border-0" type="email" value={email} placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-[13.65px] items-start justify-start w-full">
                                        <div className="flex flex-row items-start justify-between w-full">
                                            <p className="text-left capitalize flex-1 text-[18.2px] text-gray-700 w-auto font-normal font-poppins">Password</p>
                                            <a href="/Pages/Reset" className="">Forgot？</a>
                                        </div>
                                        <div className="flex flex-col items-start justify-start w-[455px] sm:w-full md:w-full">
                                            <div className="flex w-full rounded-[9px] border border-gray-400_01 border-solid text-gray-400_01 p-[13px]">
                                                <input className="p-0 placeholder:text-gray-400_01 text-left text-sm w-full bg-transparent border-0"  type={showPassword ? 'text' : 'password'} name="inputtext" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                {showPassword ? (
          <IoMdEyeOff onClick={togglePasswordVisibility} style={{fontSize:"24px"}} />
        ) : (
          <IoMdEye onClick={togglePasswordVisibility} style={{fontSize:"24px"}}/>
        )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-[27.3px] items-center justify-start w-full">
                                    <button className="cursor-pointer font-semibold h-[59px] rounded-[9px] text-base text-center w-[100%] sm:w-auto sm:rounded-[50px] sm:pl-[10%] sm:pr-[10%] sm:h-[49px] sm:pt-[3%]  p-[15px] bg-red-400 text-white-A700"  onClick={handleLogin }>Login now</button>
                                    <div className="flex flex-col items-start justify-start w-auto">
                                        <p className="text-left capitalize text-[18.2px] text-red-400 w-auto font-normal font-poppins">Or</p>
                                    </div>
                                    {/* <button onClick={signInWithGoogle}> */}
      {/* Login with Google
    </button> */}
                                    <button
        className="common-pointer cursor-pointer flex items-center justify-center min-w-[455px] sm:min-w-[75%] md:min-w-[100%] rounded-[9px] sm:w-auto sm:rounded-[50px] sm:pl-[10%] sm:pr-[10%] sm:h-[49px] sm:pt-[3%]  p-[15px] bg-gray-200_03 text-blue_gray-900"
        onClick={handleGoogleLogins}
        type="button"  
      >
        <img className="h-[22px] mb-px mr-[9px]" src="../images/img_icon_google_original.svg" alt="Icon/Google - Original" loading="lazy" />
        <div className="font-semibold text-base text-left">Continue with Google</div>
      </button>
                                    <div className="flex flex-row gap-[9.1px] items-start justify-start w-auto">
                                        <a href="javascript:" className="capitalize text-[18.2px] text-gray-400_01 w-auto">
                                            <p className="text-left  font-normal font-poppins">Don't have an account?</p>
                                        </a>
                                        <a href="javascript:" className="capitalize text-[18.2px] text-red-400 w-auto">
                                            <a href="/Pages/sign" className="text-left  font-normal font-poppins">Sign up</a>
                                        </a>
                                      
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
};
export default Login;