
// import React from "react";
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './Nav';
import Home from './Home';
import Footer from './Fotter';
import axios from 'axios'
import BASE_URL from '../src/components/constant';
import { FaSquareWhatsapp } from "react-icons/fa6";



import {AuthContextProvider} from '../src/context/AuthContext'

const App=()=>{
    const [contacts, setContact] = useState([]);
    useEffect(()=>{
        axios.get(`${BASE_URL}/general_settings.php`)
        .then(res => {
          console.log(res.data);
          setContact(res.data); // Assuming the response has a services array
        })
        .catch(err=>console.log(err));
        },[])
        const getContact = (index) => {
          console.log(contacts);
          // if (contacts.length > 0 ) {
            return contacts;
          // }else{
          // return {contact_no: "123456789",
          // whatsapp_no: "987654321",
          // address: "123 Main Street",
          // email: "example@example.com",
          // fb: "facebook",
          // insta: "instagram",
          // twitter: "twitter",
          // linkedin: "linkedin",
          // youtube: "youtube"};}; 
        };
    return(
        <>  
          <AuthContextProvider>
     
      <Router>
      <a href={`https://api.whatsapp.com/send?phone=${getContact(0).whatsapp_no}`} target="_blank" rel="noopener noreferrer">
      <FaSquareWhatsapp  style={{position:"fixed", fontSize:"45px", bottom:"4%", right:"2%", color:"#25D366", background:"#fff", zIndex:"19999999999999"}} />
</a>
        <Navbar/>
    
<Footer/>
</Router>  
    </AuthContextProvider>
 
        </> 
    )
    
};


  
  export default App;