import React, {useState} from "react";
import "./Admin.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const AddService=()=>{
  // Service Table
  const [data, setData] = useState([
    { id: 1, serviceName: 'Service 1' },
    { id: 2, serviceName: 'Service 2' },
    { id: 3, serviceName: 'Service 3' },
  ]);

  const [editId, setEditId] = useState(null);

  const handleEdit = (id) => {
    setEditId(id);
  };

  const handleDelete = (id) => {
    // Handle delete logic here
    setData(data.filter(item => item.id !== id));
  };
//   service
    return(
        <>
 <div className="ww80 mt-10">
      <table className="data-table">
      <thead>
        <tr>
          <th>No</th>
          <th>Service Name</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={row.id}>
            <td>{index + 1}</td>
            <td><input type="text" value={row.serviceName}/></td>
            <td>
              <button className="edit-btn" onClick={() => handleEdit(row.id)}>Edit</button>
              <button className="delete-btn" onClick={() => handleDelete(row.id)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
      </div>
        </>
    )
}
export default AddService;