import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter , Router, Routes, Route, NavLink, Switch, Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import Home from "../Home";
import ReactPlayer from 'react-player'

import BASE_URL from '../components/constant';
import 'swiper/css';
import 'swiper/css/navigation';
import '../Pages/HomeServices.css';
import axios from 'axios'


const HomeServices= () =>{
  const isOtherPage = window.location.pathname === '/';
  const [services, setServices] = useState([]);
  const [videos, setVideo] = useState([]);
  const swiperRef = useRef();
  const [count, setCount] = useState(0);
  const [servicename, setServices1] = useState([]);
  const [servicename1, setServices2] = useState([]);
  const [servicename2, setServices3] = useState([]);
  const [servicename3, setServices4] = useState([]);
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
 


  // useEffect(() => {
  //   // Fetch data from your API using Axios
  //   axios
  //     .get(`${BASE_URL}/services_api.php`)
  //     .then(response => {
  //       setServiceData(response.data);
  //       // Initialize the swiperRefs array based on the fetched data length
  //       // swiperRefs.current = Array(response.data.length).fill(null).map(() => useRef());
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);
  useEffect(() => {
    console.log(BASE_URL);
    axios.get(`${BASE_URL}/services_api.php?categories_id=1`)
      .then(res => {
        console.log(res.data);
        setServices1(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, []); 
  useEffect(() => {
    console.log(BASE_URL);
    axios.get(`${BASE_URL}/services_api.php?categories_id=2`)
      .then(res => {
        console.log(res.data);
        setServices2(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, []); 
  useEffect(() => {
    console.log(BASE_URL);
    axios.get(`${BASE_URL}/services_api.php?categories_id=3`)
      .then(res => {
        console.log(res.data);
        setServices3(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, []); 
  useEffect(() => {
    console.log(BASE_URL);
    axios.get(`${BASE_URL}/services_api.php?categories_id=4`)
      .then(res => {
        console.log(res.data);
        setServices4(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, []); 

  // const chunkArray = (arr, size) => {
  //   return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
  //     arr.slice(index * size, (index + 1) * size)
  //   );
  // };
 
useEffect(()=>{
axios.get(`${BASE_URL}/categories_api.php`)
.then(res => {
  console.log(res.data);
  setServices(res.data); // Assuming the response has a services array
})
.catch(err=>console.log(err));
},[])
useEffect(()=>{
  axios.get(`${BASE_URL}/clients_videoreview_api.php`)
  .then(res => {
    console.log(res.data);
    setVideo(res.data); // Assuming the response has a services array
  })
  .catch(err=>console.log(err));
  },[])
  const getVideoeName = (index) => {
    // Check if services array is not empty and index is valid
    if (videos.length > 0 && index >= 0 && index < videos.length) {
      return videos[index];
    }
    return {video:"",Detail:""}; // Return an empty string or handle it as per your requirement
  };
const getServiceName = (index) => {
  // Check if services array is not empty and index is valid
  if (services.length > 0 && index >= 0 && index < services.length) {
    return services[index];
  }
  return {name:"",details:""}; // Return an empty string or handle it as per your requirement
};
const handleIncrement = () => {
  // Use the setCount function to update the count state
  setCount(count + 1);
};
const handleDecrement = () => {
  // Use the setCount function to update the count state
  setCount(count - 1);
};
const convertTo2DArray = (list, columns) => {
  const rows = [];
  for (let i = 0; i < list.length; i += columns) {
    const row = list.slice(i, i + columns);
    rows.push(row);
  }
  return rows;
};

// Use the function to convert servicename into a 2D array
const servicesInRowsAndColumns = convertTo2DArray(servicename, 2);
const servicesInRowsAndColumns1 = convertTo2DArray(servicename1, 2);
const servicesInRowsAndColumns2 = convertTo2DArray(servicename2, 2);
const servicesInRowsAndColumns3 = convertTo2DArray(servicename3, 2);
    return (
  
            <>   
            <div className=''>
           

          {isOtherPage ? (
       <>
       {/* <div class="bg-blue_gray-900_02 flex flex-1 flex-col items-center justify-center  mb-[3px] px-2.5 py-[15px] w-full">
            <div class="flex flex-col items-start justify-center w-auto md:w-full">
            <div class="flex flex-col items-center justify-start w-auto">
            <p class="text-center text-white-A700 text-[14px] leading-[150%] w-auto font-lato font-normal">Click on service that you need, to see Portfolio, Description, Details, &amp; Faq.</p></div></div></div>          */}

       <div className="flex flex-col gap-[57px] items-center justify-start  mt-[120px] md:mt-[10px] mx-auto pt-[3px] md:px-5 sm:px-0 w-full">
       <div className="flex flex-col items-center justify-start w-[70%] sm:mt-10 sm:mb-5 md:mt-10 md:mb-0 mb-20 md:w-full">
       <div className="h-[34px] relative w-[100%]">

              <p
                className="absolute h-full inset-[0] justify-center m-auto sm:text-2xl md:text-[26px] text-[28px] text-center text-red-400 w-max"
                size="txtLatoSemiBold28"
              >
                Services we provide
                <span className='service-underline'>&nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
              </p>
              
            </div>
            <p
              className="mt-[17px] text-4xl text-center sm:text-[27px] md:text-[34px] text-gray-900 font-semibold"
              size="txtLatoBold36"
            >
              The services that make your business great
         
            </p>
            
            <p
              className="mt-3 text-center sm:text-center text-gray-900 text-lg w-[602px] sm:w-full"
              size="txtLatoRegular18Gray900"
            >
              <span className="text-gray-900 font-lato font-normal">
                Are you looking for something unique and different for your
                Programming & Tech, Video & Animation, Graphics & Design, and
                Content Writing needs?{" "}
              </span>
              <a
                href="javascript:"
                className="text-gray-900 font-lato font-bold underline"
              >
                Ultravux
              </a>
              <span className="text-gray-900 font-lato font-normal">
                {" "}
                is the answer to it.
              </span>
            </p>
            </div>
            </div>
       </>
      ) : (
        <>
        <div class="bg-[#00d648]  mobile flex flex-1 flex-col items-center justify-center  mb-[3px] px-2.5  py-[5px] w-full">
            <div class="flex flex-col items-center md:w-[60%] justify-center w-auto md:w-full">
            <div class="flex flex-col items-center justify-start w-auto">
            <p class="text-center text-white-A700 text-xl sm:text-[16px] w-auto font-lato font-normal">Click on service that you need, to see Portfolio, Description, Details, &amp; FAQ.
            </p></div></div></div>
            <div class="bg-[#00d648]  tablet flex flex-1 flex-col items-center justify-center  mb-[3px] px-2.5  py-[5px] w-full">
            <div class="flex flex-col items-center md:w-[60%] justify-center w-auto md:w-full">
            <div class="flex flex-col items-center justify-start w-auto">
            <p class="text-center text-white-A700 text-xl sm:text-[16px] w-auto font-lato font-normal">Click on service that you need, to see Portfolio, Description, Details, &amp; FAQ.
            </p></div></div></div>
        <div className="flex flex-col gap-[57px] items-center justify-start  mt-[12px] p-[5%] md:mt-[10px] mx-auto  md:px-5 w-full">
        <div class="flex flex-col gap-[27px] sm:gap-[2px]  md:gap-[2px] w-[923px] m-auto items-center justify-start  mt-0 mx-auto  w-full">
        <p class="text-center md:text-5xl text-6xl text-gray-900 font-bold font-lato">Our Services</p>
        <p class="text-center text-blue_gray-900 text-center text-lg md:w-[98%] w-[923px] sm:w-full  font-lato font-normal">Are you looking for something unique and different for your programming &amp; Tech, video and animation, graphic and design, and content writing needs? Ultravux is the answer to it.</p>
        </div>
        
        </div>
        </>
      )}
          
            <div  className="flex wisd md:block sboxw wisdmmsd">
           
                <div className="image-left Simg S1rught">
                <img src="../images/img_developer2converted.svg"/>
                </div>
                <div className="text-right HOmbg progSB S1right">
                <div className="flex flex-col gap-[15px] items-center justify-start w-auto sm:w-full">
                      <p
                        className="text-left text-3xl sm:text-[28px] md:text-[34px] text-black-900 text-center w-auto font-bold font-lato"
                        size="txtLatoBold36Black900"
                      >
                        {getServiceName(0).name}
                      </p>
                      <p
                        className="leading-[30.00px]  max-w-[512px] sm:font-normal md:max-w-full md:font-semibold md:font-[18px] text-center text-black text-lg mb-6"
                        size="txtLatoRegular18Gray900"
                      >
                        {getServiceName(0).details}{" "}
                      </p>

                    </div>
                 
                   
    
                    <div className=" w-full mt-5">
  {servicesInRowsAndColumns.map((row, rowIndex) => (
    <div key={rowIndex} className="flex w-full">
      {row.map((service, columnIndex) => (
        service.category.name  === getServiceName(0).name && (
          <div key={service.id} className={`w-${columnIndex === 0 ? 'sr60' : 'sr40'} mr-6  mb-5  sm:mb-2`}>
            <a href={`/Pages/Service/1/${service.title}`}>
            <div className="text-left text-red-A200 sm:text-[12px] text-xl md:text-l font-lato font-semibold">
              {service.title}
            </div>
      </a>
          </div>
        )
      ))}
    </div>
  ))}
</div>

                    <div className="text-center mt-5 sm:mb-[20px] md:mb-[20px]">
                    <Link to="/Pages/Service/1/Full Website Creation" 
                    className=" mt-5 cursor-pointer font-semibold leading-[normal] min-w-[169px] md:text-[16px] md:px-[25px] md:py-[10px] rounded-[27px] shadow-bs5 text-center text-xl  p-[15px] sm:px-[25px] sm:py-[10px] sm:text-[16px]  bg-red-400 text-white-A700"
                    >Learn More</Link>
                    </div>
                </div>
            </div>

            <div  className="flex wisd sboxw writcont wisdmmsd desktop">
                
                <div className="text-right HOmbg writeSB">
                <div className="flex flex-col gap-[15px] items-center justify-start w-auto sm:w-full">
                      <p
                        className="text-left text-4xl sm:text-[32px] md:text-[34px] text-black-900 text-center w-auto font-bold font-lato"
                        size="txtLatoBold36Black900"
                      >
                        {getServiceName(1).name}
                      </p>
                      <p
                        className="leading-[30.00px] max-w-[512px] md:max-w-full md:font-semibold md:font-[18px] text-center text-gray-900 text-lg mb-4 mb-6"
                        size="txtLatoRegular18Gray900"
                      >
                        {getServiceName(1).details}{" "}
                      </p>

                    </div>
                    <div className="flex wisd">

                    <div className="mt-5  w-full">
                      
  {servicesInRowsAndColumns1.map((row, rowIndex) => (
    <ul key={rowIndex} className="flex w-full">
      {row.map((service, columnIndex) => (
        service.category.name  === getServiceName(1).name && (
          <li key={service.id} className={`w-${columnIndex === 0 ? 'sr60' : 'sr40'} text-left  text-red-A200 sm:text-[14px] text-xl sm:mb-1 font-lato font-semibold mb-5`} style={{ marginRight: '10px' }}>
            <a href={`/Pages/Service/2/${service.title}`}>
            <div className="text-left text-red-A200 sm:text-[12px] text-xl  md:text-l font-lato font-semibold">
              {service.title}
            </div>
      </a>
          </li>
        )
      ))}
    </ul>
  ))}
</div>

                    </div>
                    <div className="text-center mt-5 sm:mb-[20px] md:mb-[20px]">
                    <Link to="/Pages/Service/2/Articles & Blog Posts" 
                    className=" mt-5 cursor-pointer font-semibold leading-[normal] min-w-[169px] rounded-[27px] md:text-[16px] md:px-[25px] md:py-[10px] shadow-bs5 text-center text-xl  p-[15px] bg-red-400 text-white-A700"
                    >Learn More</Link>
                    </div>
                </div>
                <div className="image-left Simg S1left" >
                <img src="../images/writing_2[Converted]1.svg" style={{height:"450px"}}/>
                </div>
            </div>
            <div  className="flex wisd md:block wisdmmsd ipadair ipadh  mobile">
           
            <div className="image-left ipadair wrtieimg">
                <img src="../images/writing_2[Converted]1.svg"/>
                </div>
                <div className="text-right ipadair HOmbg writerigh">
                <div className="flex flex-col gap-[15px] items-center justify-start w-auto sm:w-full">
                <p
                        className="text-left text-4xl sm:text-[30px] md:text-[34px] text-black-900 text-center w-auto font-bold font-lato"
                        size="txtLatoBold36Black900"
                      >
                       {getServiceName(1).name}
                      </p>
                      <p
                        className="leading-[30.00px] max-w-[512px] md:max-w-full sm:font-normal	 md:font-semibold md:font-[18px] text-center text-gray-900 text-lg"
                        size="txtLatoRegular18Gray900"
                      >
                        {getServiceName(1).details}{" "}
                      </p>
                      {/* <a href='/Pages/Service/2/Articles & Blog Posts' className='flex service-readmore'>Read More <img src='../images/akar-icons_arrow-left.png'/></a> */}
                    </div>
                    <div className="flex wisd">

                    <div className="mt-5 w-full">
  {servicesInRowsAndColumns1.map((row, rowIndex) => (
    <ul key={rowIndex} className="flex w-full">
      {row.map((service, columnIndex) => (
        service.category.name  === getServiceName(1).name && (
          <li key={service.id} style={{ marginRight: '10px' }} className={`w-${columnIndex === 0 ? 'sr60' : 'sr40'} text-left text-red-A200 sm:text-[14px] text-xl sm:mb-1 font-lato font-semibold mb-5 sm:mb-2`} >
           <a href={`/Pages/Service/2/${service.title}`}>
            <div className="text-left text-red-A200 sm:text-[12px] text-xl  md:text-l font-lato font-semibold">
              {service.title}
            </div>
      </a>
          </li>
        )
      ))}
    </ul>
  ))}
</div>


                    </div>
                    <div className="text-center mt-5 sm:mb-[12px] md:mb-[20px]">
                    <Link to="/Pages/Service/2/Articles & Blog Posts" 
                    className="  mt-5 cursor-pointer font-semibold leading-[normal] min-w-[169px] md:text-[16px] md:px-[25px] md:py-[10px] rounded-[27px] shadow-bs5 text-center text-xl  p-[15px] sm:px-[25px] sm:py-[10px] sm:text-[16px]  bg-red-400 text-white-A700"
                    >Learn More</Link>
                    </div>
                </div>
               
            </div>
            {/* Tablet */}
            <div  className="flex wisd md:block wisdmmsd ipadair ipadh tablet">
           
           <div className="image-left ipadair wrtieimg">
               <img src="../images/writing_2[Converted]1.svg"/>
               </div>
               <div className="text-right ipadair HOmbg writerigh">
               <div className="flex flex-col gap-[15px] items-center justify-start w-auto sm:w-full">
               <p
                       className="text-left text-4xl sm:text-[30px] md:text-[34px] text-black-900 text-center w-auto font-bold font-lato"
                       size="txtLatoBold36Black900"
                     >
                      {getServiceName(1).name}
                     </p>
                     <p
                       className="leading-[30.00px] max-w-[512px] md:max-w-full sm:font-normal	 md:font-semibold md:font-[18px] text-center text-gray-900 text-lg"
                       size="txtLatoRegular18Gray900"
                     >
                       {getServiceName(1).details}{" "}
                     </p>
                     {/* <a href='/Pages/Service/2/Articles & Blog Posts' className='flex service-readmore'>Read More <img src='../images/akar-icons_arrow-left.png'/></a> */}
                   </div>
                   <div className="flex wisd">

                   <div className="mt-5 w-full">
 {servicesInRowsAndColumns1.map((row, rowIndex) => (
   <ul key={rowIndex} className="flex w-full">
     {row.map((service, columnIndex) => (
       service.category.name  === getServiceName(1).name && (
         <li key={service.id} style={{ marginRight: '10px' }} className={`w-${columnIndex === 0 ? 'sr60' : 'sr40'} text-left text-red-A200 sm:text-[14px] text-xl sm:mb-1 font-lato font-semibold mb-5 sm:mb-2`} >
          <a href={`/Pages/Service/2/${service.title}`}>
           <div className="text-left text-red-A200 sm:text-[12px] text-xl   md:text-l font-lato font-semibold">
             {service.title}
           </div>
     </a>
         </li>
       )
     ))}
   </ul>
 ))}
</div>


                   </div>
                   <div className="text-center mt-5 sm:mb-[12px] md:mb-[20px]">
                   <Link to="/Pages/Service/2/Articles & Blog Posts" 
                   className="  mt-5 cursor-pointer font-semibold leading-[normal] min-w-[169px] md:text-[16px] md:px-[25px] md:py-[10px] rounded-[27px] shadow-bs5 text-center text-xl  p-[15px] sm:px-[25px] sm:py-[10px] sm:text-[16px]  bg-red-400 text-white-A700"
                   >Learn More</Link>
                   </div>
               </div>
              
           </div>
        {/* Video div */}
        <div  className="flex wisd md:block sboxw wisdmmsd">

        <div className="image-left Simg">
                <img src="../images/customer_support_2.svg"/>
                </div>
                <div className="text-right HOmbg videoSB S1left">
                <div className="flex flex-col gap-[15px] items-center justify-start w-auto sm:w-full">
                      <p
                        className="text-left text-4xl sm:text-[32px] md:text-[34px] text-black-900 text-center w-auto font-bold font-lato"
                        size="txtLatoBold36Black900"
                      >
                        {getServiceName(2).name}
                      </p>

                      <p
                        className="leading-[30.00px] md:font-semibold sm:font-[16px] sm:font-normal md:font-[18px] max-w-[512px] md:max-w-full text-center text-gray-900 text-lg mb-6"
                        size="txtLatoRegular18Gray900"
                      >
{getServiceName(2).details}{" "}
                      </p>
                      {/* <a href='/Pages/Service/3/Video Editing' className='flex service-readmore mobile'>Read More <img src='../images/akar-icons_arrow-left.png'/></a> */}
                    </div>
                    <div className=" w-full mt-5">
  {servicesInRowsAndColumns2.map((row, rowIndex) => (
    <div key={rowIndex} className="flex w-full">
      {row.map((service, columnIndex) => (
        service.category.name  === getServiceName(2).name  && (
          <div key={service.id} className={`w-${columnIndex === 0 ? 'sr50' : 'sr50'} mr-0  mb-5 sm:mb-2`}>
            
            <a href={`/Pages/Service/3/${service.title}`}>
            <div className="text-left text-red-A200 sm:text-[12px] text-xl  md:text-l font-lato font-semibold">
              {service.title}
            </div>
      </a>
           
          </div>
        )
      ))}
    </div>
  ))}
</div>

                    <div className="text-center mt-5 sm:mb-[20px] md:mb-[20px]">
                    <Link to="/Pages/Service/3/Video Editing" 
                    className="   mt-5 cursor-pointer font-semibold leading-[normal] min-w-[169px] md:text-[16px] md:px-[25px] md:py-[10px] rounded-[27px] shadow-bs5 text-center text-xl  p-[15px] sm:px-[25px] sm:py-[10px] sm:text-[16px]  bg-red-400 text-white-A700"
                    >Learn More</Link>
                    </div>
                </div>
               
            </div>
            {/* Graphics */}
            <div  className="flex sboxw wisd wisdmmsd desktop">
                
                <div className="text-right HOmbg graSB" >
                <div className="flex flex-col gap-[15px] items-center justify-start w-auto sm:w-full">
                      <p
                        className="text-left text-4xl sm:text-[32px] md:text-[34px] text-black-900 text-center w-auto font-bold font-lato"
                        size="txtLatoBold36Black900"
                      >
                       {getServiceName(3).name}
                      </p>
                      <p
                        className="leading-[30.00px] md:font-semibold md:font-[18px] max-w-[512px]  md:max-w-full text-center text-gray-900 text-lg mb-6"
                        size="txtLatoRegular18Gray900"
                      >
{getServiceName(3).details}{" "}
                      </p>

                    </div>
                    <div className="w-full mt-5">
  {servicesInRowsAndColumns3.map((row, rowIndex) => (
    <div key={rowIndex} className="flex w-full">
      {row.map((service, columnIndex) => (
        service.category.name === getServiceName(3).name && (
          <div key={service.id} className={`w-${columnIndex === 0 ? 'sr60' : 'sr40'} mr-6  mb-5 sm:mb-2`}>
            <a href={`/Pages/Service/4/${service.title}`}>
            <div className="text-left text-red-A200 sm:text-[12px] text-xl  md:text-l font-lato font-semibold">
              {service.title}
            </div>
      </a>
          </div>
        )
      ))}
    </div>
  ))}
</div>

                    <div className="text-center mt-5 sm:mb-[20px] md:mb-[20px]">
                    <Link to="/Pages/Service/4/Logo Design" 
                    className=" mt-5 cursor-pointer font-semibold leading-[normal] min-w-[169px] md:text-[16px] md:px-[25px] md:py-[10px] rounded-[27px] shadow-bs5 text-center text-xl  p-[15px] bg-red-400 text-white-A700"
                    >Learn More</Link>
                    </div>
                </div>
                <div className="image-left Simg S1left">
                <img src="../images/creative_1[Converted]1.svg" style={{height:"430px"}}/>
                </div>
            </div>
            <div  className="flex wisd wisdmmsd ipadair ipadh  md:block mobile">
            
            <div className="image-left ipadair ">
                <img src="../images/creative_1[Converted]1.svg"/>
                </div>
                <div className="text-right ipadair HOmbg grapright" >
                <div className="flex flex-col gap-[15px] items-center justify-start w-auto sm:w-full">
                <p
                        className="text-left text-4xl sm:text-[32px] md:text-[34px] text-black-900 text-center w-auto font-bold font-lato"
                        size="txtLatoBold36Black900"
                      >
                        {getServiceName(3).name}
                      </p>
                      <p
                        className="leading-[30.00px] md:font-semibold sm:text-[16px] sm:font-normal md:font-[18px] max-w-[512px] md:max-w-full text-center text-gray-900 text-lg mb-6"
                        size="txtLatoRegular18Gray900"
                      >
                      {getServiceName(3).details}{" "}
                      </p>
                      {/* <a href='/Pages/Service/4/Logo Design' className='flex service-readmore'>Read More <img src='../images/akar-icons_arrow-left.png'/></a> */}
                    </div>
                    <div className="mt-5 w-full">
  {servicesInRowsAndColumns3.map((row, rowIndex) => (
    <div key={rowIndex} className="flex w-full">
      {row.map((service, columnIndex) => (
        service.category.name === getServiceName(3).name && (
          <div key={service.id} className={`w-${columnIndex === 0 ? 'sr60' : 'sr40'} mr-6  mb-5 sm:mb-2`}>
            <div className="text-left text-red-A200 sm:text-[12px] text-xl  md:text-l font-lato font-semibold">
              {service.title}
            </div>
          </div>
        )
      ))}
    </div>
  ))}
</div>

                    <div className="text-center mt-5 sm:mb-[12px] md:mb-[20px]">
                    <Link to="/Pages/Service/4/Logo Design" 
                    className="    mt-5 cursor-pointer font-semibold leading-[normal] min-w-[169px] md:text-[16px] md:px-[25px] md:py-[10px] rounded-[27px] shadow-bs5 text-center text-xl  p-[15px] sm:px-[25px] sm:py-[10px] sm:text-[16px]  bg-red-400 text-white-A700"
                    >Learn More</Link>
                    </div>
                </div>
                
            </div>
            {/* Tablet */}
            <div  className="flex wisd wisdmmsd ipadair ipadh  md:block tablet">
            
            <div className="image-left ipadair ">
                <img src="../images/creative_1[Converted]1.svg"/>
                </div>
                <div className="text-right ipadair  HOmbg grapright" >
                <div className="flex flex-col gap-[15px] items-center justify-start w-auto sm:w-full">
                <p
                        className="text-left text-4xl sm:text-[32px] md:text-[34px] text-black-900 text-center w-auto font-bold font-lato"
                        size="txtLatoBold36Black900"
                      >
                        {getServiceName(3).name}
                      </p>
                      <p
                        className="leading-[30.00px] md:font-semibold sm:text-[16px] sm:font-normal md:font-[18px] max-w-[512px] md:max-w-full text-center text-gray-900 text-lg mb-6"
                        size="txtLatoRegular18Gray900"
                      >
                      {getServiceName(3).details}{" "}
                      </p>
                      {/* <a href='/Pages/Service/4/Logo Design' className='flex service-readmore'>Read More <img src='../images/akar-icons_arrow-left.png'/></a> */}
                    </div>
                    <div className="mt-5 w-full">
  {servicesInRowsAndColumns3.map((row, rowIndex) => (
    <div key={rowIndex} className="flex w-full">
      {row.map((service, columnIndex) => (
        service.category.name === getServiceName(3).name && (
          <div key={service.id} className={`w-${columnIndex === 0 ? 'sr60' : 'sr40'} mr-6  mb-5 sm:mb-2`}>
            <div className="text-left text-red-A200 sm:text-[12px] text-xl  md:text-l font-lato font-semibold">
              {service.title}
            </div>
          </div>
        )
      ))}
    </div>
  ))}
</div>

                    <div className="text-center mt-5 sm:mb-[12px] md:mb-[20px]">
                    <Link to="/Pages/Service/4/Logo Design" 
                    className="    mt-5 cursor-pointer font-semibold leading-[normal] min-w-[169px] md:text-[16px] md:px-[25px] md:py-[10px] rounded-[27px] shadow-bs5 text-center text-xl  p-[15px] sm:px-[25px] sm:py-[10px] sm:text-[16px]  bg-red-400 text-white-A700"
                    >Learn More</Link>
                    </div>
                </div>
                
            </div>
            </div>
            
{/* video */}
{isOtherPage ? (
  <>
    <div className="flex wisd md:block sboxw wisdmmsd">
      <div className="image-left S4left">
        <img src="../images/pointing_13[Converted].svg" alt="pointing" />
      </div>
      <div className="text-right HOmbg onlyVB">
        <div className="flex flex-col gap-[15px] items-center justify-start w-auto sm:w-full">
          <p className="text-left text-4xl sm:text-[32px] md:text-[34px] text-black-900 text-center w-auto font-bold font-lato">
            Play Video
          </p>
          {/* <p>Count: {count}</p> */}
            <p className="leading-[30.00px] max-w-[512px] md:max-w-full p-[15px] h-[100%] text-center text-gray-900 text-lg">
              {getVideoeName(count).Detail}
            </p>
        
          <div className="mais video-w ">
            <div style={{ position: 'relative' }}>
            
              <Swiper
                modules={[Navigation]}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
              >

                {videos.map((video, index) => (
                  <SwiperSlide key={index}>
                    <video className="desktop" width="90%"  controls style={{ margin: 'auto', maxHeight:"280px", maxWidth:"630px", width:"90%", borderRadius:"10px" }}>
                      <source src={video.video} type="video/mp4" />
                    </video>
                    <ReactPlayer
                    ref={playerRef}
      className='mobile service-video video-hw'
      url={video.video}
      controls
      playsinline
      playing={playing && index === count} // Play only if 'playing' is true and index matches count

    />
     <ReactPlayer
     ref={playerRef}
      className='tablet ipadprovideo service-videoipad video-hw'
      url={video.video}
      controls
      playsinline
      playing={playing && index === count} // Play only if 'playing' is true and index matches count

    />
                   
                    {/* <video className="" width="630" controls style={{ margin: 'auto', maxHeight:"350px",  borderRadius:"10px" }}>
                      <source src={video.video} type="video/mp4" />
                    </video> */}
                    
                  </SwiperSlide>
                  
                ))}
              </Swiper>
              
              <button
                className="swiper-button-prev video-bnt-prec vid"
                style={{
                 
                }}
                onClick={()  =>{ 
                  // console.log(indexx);
                  if(count==0){
                 setCount(videos.length-1);
                    // console.log(indexx);
                    swiperRef.current?.slideTo(videos.length-1);
                    console.log(swiperRef.current?.realIndex)

                  }else{
                    handleDecrement();
                    swiperRef.current?.slidePrev();
                    console.log(swiperRef.current?.realIndex)

                  }
                  }} 
              ></button>
              <button
                className="swiper-button-next video-bnt-next video-bnt"
                style={{
                 
                }}
                onClick={() =>{ 
                   if(count>=videos.length-1){
                   setCount(0);
                    swiperRef.current?.slideTo(0);
                    console.log(swiperRef.current?.realIndex)
                  }else{
                // indexx!=1?  indexx=indexx+1:0;
                  handleIncrement();
                  // console.log(indexx);
                  swiperRef.current?.slideNext();
                  console.log(swiperRef.current?.realIndex)}

                }}
              ></button>
               

            </div>
          </div>
        </div>
      </div>
      
    </div>

            
  </>
):(
  <>
  </>
)}

            {/* video end */}
       
            </>
    )
};
export default HomeServices;