import React, { useRef, useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import TopRated from "./TopRated";
import { Link ,useParams,useNavigate} from 'react-router-dom';
import ReactPlayer from 'react-player'

import axios from 'axios'
import { Markup } from 'interweave';
import BASE_URL from '../components/constant';

const ReusableButton = ({ onClick, category, filter, children }) => {
 
  return (
    <div className="ser-btn">
      <button
        onClick={() => onClick(category)}
        className={
          filter === category
            ? 'cursor-pointer font-semibold h-[89px] sm:h-[60px] md:h-[50px] sm:text-[14.4px] md:text-[13px] text-[20.4px] text-center w-full rounded md:p-1 p-2 sm:px-1 bg-white-A700 shadow-bs text-red-400'
            : 'cursor-pointer font-semibold h-[89px] sm:h-[60px] md:h-[50px] sm:text-[14.4px] md:text-[13px] text-[20.4px] text-center w-full rounded md:p-1 p-2 sm:px-1 bg-white-A700 shadow-bs text-gray-900'
        }
      >
        {children}
      </button>
    </div>
  );
};

const ServiceDetails = ({ title, features }) => {
    const hasFeatures = features.some(feature => !!feature);
  return (
    <div className="bg-white-A700 border border-gray-500_7f border-solid flex sm:flex-1 flex-col gap-[36.31px] items-start justify-start px-[18.16px] py-[19.97px] rounded-[9px] w-auto sm:w-full">
      <div className="flex flex-col gap-[9.08px] items-center justify-start w-full">
        <div className="flex flex-col items-start justify-start w-auto">
          <p className="text-left capitalize sm:text-[17.79px] md:text-[19.79px] text-[21.79px] text-blue_gray-900_02 text-center w-auto font-lato font-semibold">
            {title}
          </p>
        </div>
        <div className="bg-green-A700 h-0.5 w-full"></div>
      </div>
      {hasFeatures && (
        <ul className="w-full">
          {features.map((feature, index) => (
            feature && (
              <li key={index} className="flex mt-1">
                <img src="/images/img_claritycheckline.svg" alt={`Feature ${index + 1}`} />
                <span className="text-left ml-[8px] mb-[3px] capitalize text-[14.52px] text-blue_gray-900 w-auto font-lato font-normal">
                  {feature}
                </span>
              </li>
            )
          ))}
          <Link to="/Pages/Contact" className="capitalize cursor-pointer block mt-10 font-semibold leading-[normal] text-[14.52px] text-center w-full rounded p-[15px] bg-green-A700 text-white-A700">Contact Us</Link>
        </ul>
      )}
    </div>
  );
};

// collipsable Code

// collipsable Code
const Service = () => {
  
  const { id, servicename } = useParams();

  const serviceId = parseInt(id, 10);

  const swiperRef = useRef();
  const [services, setServices] = useState([]);
  const [cat, setCate] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    console.log(BASE_URL);
    axios.get(`${BASE_URL}/services_api.php?categories_id=${serviceId}`)
      .then(res => {
        console.log(res.data);
        setServices(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, [serviceId]); // Make sure to add 'serviceId' to the dependency array so that the effect runs when 'serviceId' changes
  useEffect(() => {
    console.log(serviceId);
    axios.get(`${BASE_URL}/categories_api.php?id=${serviceId}`)
      .then(res => {
        console.log(res.data);
        setCate(res.data); // Assuming the response has a services array
      })
      .catch(err => console.log(err));
  }, [serviceId]); 
  const classNameForFaqLeft = `faq-right ${window.innerWidth < 768 ? 'mobile' : window.innerWidth <= 1024 ? 'tablet' : 'hidden'}`;


    // tabs
    const [activeTab, setActiveTab] = useState(null);
  
    const handleTabClick = (tabIndex) => {
      setActiveTab(activeTab === tabIndex ? null : tabIndex);
    };
    const getServiceName = (index) => {
      // Check if services array is not empty and index is valid
      if (services.length > 0 && index >= 0 && index < services.length) {
        return services[index];
      }
      return {name:"",title:""}; // Return an empty string or handle it as per your requirement
    };
    
    const getCateName = (index) => {
      // Check if services array is not empty and index is valid
      if (cat.length > 0 && index >= 0 && index < cat.length) {
        return cat[index];
      }
      return {name:"",details:""}; // Return an empty string or handle it as per your requirement
    };
  // paramas


  const initialFilter = getServiceName(0);
  const [filter, setFilter] = useState(servicename);
  
  // const [filter, setFilter] = useState(servicename ?? getServiceName[0].title);
  
  const handleFilterChange = (newFilter) => {
    history(`/Pages/Service/${serviceId}/${newFilter}`);
    setFilter(newFilter);
  };
  const filteredGallery = filter === 'all' ? services : services.filter(item => item.title === filter);
  return (
    <>
    
      <div className="ww90" style={{paddingTop:"4%"}}>
        <div className="flex flex-col gap-[9.27px] mb-10 items-center justify-start w-auto md:w-full">
          <p className="text-left sm:text-center sm:text-[43.51px]  md:text-[40.51px] text-[44.51px] text-gray-900 w-auto font-bold font-lato">
            {getCateName(0).name}
          </p>
          <p className="text-left leading-[27.00px] max-w-[847px] md:max-w-full text-[16.69px] text-center text-gray-900 font-lato font-normal">
          {getCateName(0).details}
            {/* Programming and tech are all around us. From the movie you stream, take-out you order, code allows daily actions in our lives. */}
          </p>
        </div>

        <div className="grid grid-cols-5 sm:grid-cols-2 sm:gap-6 gap-4">
          {services.map((item) => (
            <ReusableButton
              key={item.id}
              onClick={handleFilterChange}
              category={item.title}
              filter={filter}
            >
              {item.title}
            </ReusableButton>
          ))}
        </div>

        {/* Main Content */}
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {filteredGallery.map((item, index) => (
            <div key={item.id} className="Main-Program">
              <div className="program-left">
                <p className="text-left sm:text-center md:text-center sm:mt-6 mb-4 capitalize  sm:text-[24.68px] md:text-[30.68px] text-[32.68px] text-blue_gray-900 font-bold font-lato">
                  {item.name}
                </p>
                <div className='md:w-[90%] md:m-auto' style={{position:'relative'}}>
                <Swiper 
                 modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
         className="mySwipersoisd">
              {item.list_images.map((image, imageIndex) => (
                        <SwiperSlide key={imageIndex}>
                          {image.toLowerCase().includes('.mp4') || image.toLowerCase().includes('.avi') || image.toLowerCase().includes('.mkv') ? (
                            <>
      {/* <video style={{ height: '100%', maxHeight:"450px", width: '100%', borderRadius:"24px" }} controls>
        <source src={image} type="video/mp4" />
      </video> */}
      <ReactPlayer
      className='video-hw service-page-video'
      url={image}
      controls
      style={{width:"100%"}}
      playsinline
    />
      </>
    ) : (
      <img src={image} className='service-img' style={{ height: '100%', height:"450px", width: '100%', borderRadius:"24px" }} alt={`Image ${imageIndex + 1}`} />
    )}
                        </SwiperSlide>
                      ))}
                  {/* <SwiperSlide>
                    <img className="round_con portrcjsss" src={item.pic1} alt={`${item.name} Image 1`} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="round_con portrcjsss" src={item.pic2} alt={`${item.name} Image 2`} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img className="round_con portrcjsss" src={item.pic3} alt={`${item.list_images} Image 3`} />
                  </SwiperSlide> */}
                </Swiper>
                <button className='swiper-button-prev seviv-prev-btn' 
        style={{    position: "absolute", left: "-25px", top:"50%", }}  onClick={() => swiperRef.current?.slidePrev()}></button>
        <button className='swiper-button-next seviv-nxt-btn' 
        style={{    position: "absolute", right: "-25px", top:"50%",}} onClick={() => swiperRef.current?.slideNext()}></button>
              </div>
              <div className="program-right mobile mt-8 ">
                <ServiceDetails title="Service Details" features={item.service_details} />
              </div>
              <div className="program-right tablet mt-8 ">
                <ServiceDetails title="Service Details" features={item.service_details} />
              </div>
{/* {item.details} */}
<p className="text-left mt-10 capitalize sm:text-[33.57px] md:text-[39.57px] text-[43.57px] text-blue_gray-900 w-auto font-bold font-lato">Description</p>
<div className='sm:mx-[5px]'>
<Markup content={item.details}  /> 
</div>
                {/* <p className="text-left mt-10 capitalize sm:text-[33.57px] md:text-[39.57px] text-[43.57px] text-blue_gray-900 w-auto font-bold font-lato">
                  Description
                </p>
                <div className="descr mt-4">
                  <p>
                    {item.descp1}
                    <br/>
                    {item.descp2}
                  <br/>
                  {item.descp3}
                  </p>
                </div> */}
                {/* <b><p className="text-left capitalize text-[18.16px] text-blue_gray-900 w-auto font-bold font-lato mt-4 mb-2">
                {item.Listhead}</p></b>
                  <ul className="list-disc ml-6">
                    {Object.keys(item)
                      .filter(key => key.startsWith('l') && item[key]) // Filter keys starting with 'l' and have truthy values
                      .map((key, index) => (
                        <li className="service-list" key={index}>{item[key]}</li>
                      ))}
                      </ul>
                      <p className="list-caption mt-2">{item.caption}</p> */}
                      
              </div>
              <div className="program-right desktop">
                <ServiceDetails title="Service Details" features={item.service_details} />
              </div>
             
            </div>
          ))}
        </div>
        {/* Main Content */}
        
      </div>
      {/* conatct us button */}
      <div class="bg-white-A700 mt-20 sm:mt-5 sm:mb-5 md:mb-0 mb-20 flex flex-col items-center justify-start  sm:m-auto sm:w-[95%]  py-[18.16px] shadow-bs w-full">
                      <div class="flex flex-col items-center justify-start max-w-[1198px] mx-auto md:px-5 sm:px-0 w-full">
                      <div class="flex flex-col items-center justify-start md:justify-center w-auto md:w-full">
                      <div class="flex flex-col h-[85px] md:h-auto items-start md:items-center justify-center max-w-[1198px] px-[9.08px] py-[4.54px] w-full">
                      <p class="text-left mobile capitalize leading-[150.00%] sm:text-[16.42px] md:text-[23.42px] text-[25.42px] text-black-900 text-center font-lato font-semibold">
                      For any queries, don’t hesitate to reach out to us. You deserve the best.</p>
                      <p class="text-left tablet capitalize leading-[150.00%] sm:text-[16.42px] md:text-[23.42px] text-[25.42px] text-black-900 text-center font-lato font-semibold">
                      For any queries, don’t hesitate to reach out to us.<br/>You deserve the best.</p>
                      <p class="text-left desktop capitalize leading-[150.00%] sm:text-[16.42px] md:text-[23.42px] text-[25.42px] text-black-900 text-center font-lato font-semibold">
                      For any queries, don’t hesitate to reach out to us .<br/>You deserve the best.</p>
                     
                      </div>
                      <Link to="/Pages/Contact" class="capitalize cursor-pointer font-semibold min-w-[143px] rounded-[25px] sm:text-[17.79px] md:text-[19.79px] text-[21.79px] text-center  p-3 bg-red-400 text-white-A700">Contact Us</Link>
                      </div></div></div>
                      {/* contact us button */}
                      {/* Tabs */}
                      <div className="ww90 faq-main">
                      <div className={classNameForFaqLeft}>
                        <img style={{margin:"auto"}} src="/../images/Frame1329.svg"/>
                      </div>
                      <div className="faq-left mobile">
                      <p className="text-left capitalize mb-4 sm:text-[29.380000000000003px] md:text-[31.380000000000003px] text-[33.38px] text-black-900 w-auto font-bold font-lato">
                        FAQ
                      </p>
                      <div className="faq-container">
                        {filteredGallery.map((item, tabIndex) => (
                          <>
                          <div key={item.id} className="faq-tab">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((index) => {
                              const tabKey = `${tabIndex}-${index}`;
                            const   currentindex=index-1;
                              // Check if `ques${index}` has a value
                              if (item?.faqs?.questions?.[currentindex]) {
                                return (
                                  <React.Fragment key={tabKey}>
                                    <div className="tabs-man">
                                      <div
                                        className={`tab-header ${activeTab === tabKey ? 'tab-head-active' : ''}`}
                                        onClick={() => handleTabClick(tabKey)}
                                      >
                                        <p className="text-left capitalize sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-blue_gray-900 w-auto font-lato font-semibold">
                                          {item.faqs.questions[index-1]}
                                        </p>
                                        <img className={`arrow ${activeTab === tabKey ? 'up' : 'down'}`} src="/../images/img_arrowdown_blue_gray_900.svg"/>
                                      </div>
                                      <div className={`tab-content ${activeTab === tabKey ? 'tab-content-active' : ''}`}>
                                        <p className="text-left capitalize text-[18.55px] text-blue_gray-900 w-auto font-lato font-normal">
                                          {item.faqs.answers[index-1]}
                                        </p>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              } else {
                                return null; // Don't render if `ques${index}` has no value
                              }
                            })}
                          </div>
                          </>
                        ))}
                      </div>
                      </div>
                      <div className="faq-left tablet">
                      <p className="text-left capitalize mb-4 sm:text-[29.380000000000003px] md:text-[31.380000000000003px] text-[33.38px] text-black-900 w-auto font-bold font-lato">
                        FAQ
                      </p>
                      <div className="faq-container">
                        {filteredGallery.map((item, tabIndex) => (
                          <>
                          <div key={item.id} className="faq-tab">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((index) => {
                              const tabKey = `${tabIndex}-${index}`;
                            const   currentindex=index-1;
                              // Check if `ques${index}` has a value
                              if (item?.faqs?.questions?.[currentindex]) {
                                return (
                                  <React.Fragment key={tabKey}>
                                    <div className="tabs-man">
                                      <div
                                        className={`tab-header ${activeTab === tabKey ? 'tab-head-active' : ''}`}
                                        onClick={() => handleTabClick(tabKey)}
                                      >
                                        <p className="text-left capitalize sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-blue_gray-900 w-auto font-lato font-semibold">
                                          {item.faqs.questions[index-1]}
                                        </p>
                                        <img className={`arrow ${activeTab === tabKey ? 'up' : 'down'}`} src="/../images/img_arrowdown_blue_gray_900.svg"/>
                                      </div>
                                      <div className={`tab-content ${activeTab === tabKey ? 'tab-content-active' : ''}`}>
                                        <p className="text-left capitalize text-[18.55px] text-blue_gray-900 w-auto font-lato font-normal">
                                          {item.faqs.answers[index-1]}
                                        </p>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              } else {
                                return null; // Don't render if `ques${index}` has no value
                              }
                            })}
                          </div>
                          </>
                        ))}
                      </div>
                      </div>
                      <div className="faq-left desktop">
                      <p className="text-left capitalize mb-4 sm:text-[29.380000000000003px] md:text-[31.380000000000003px] text-[33.38px] text-black-900 w-auto font-bold font-lato">
                        FAQ
                      </p>
                      <div className="faq-container">
                        {filteredGallery.map((item, tabIndex) => (
                          <>
                          <div key={item.id} className="faq-tab">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((index) => {
                              const tabKey = `${tabIndex}-${index}`;
                            const   currentindex=index-1;
                              // Check if `ques${index}` has a value
                              if (item?.faqs?.questions?.[currentindex]) {
                                return (
                                  <React.Fragment key={tabKey}>
                                    <div className="tabs-man">
                                      <div
                                        className={`tab-header ${activeTab === tabKey ? 'tab-head-active' : ''}`}
                                        onClick={() => handleTabClick(tabKey)}
                                      >
                                        <p className="text-left capitalize sm:text-[18.25px] md:text-[20.25px] text-[22.25px] text-blue_gray-900 w-auto font-lato font-semibold">
                                          {item.faqs.questions[index-1]}
                                        </p>
                                        <img className={`arrow ${activeTab === tabKey ? 'up' : 'down'}`} src="/../images/img_arrowdown_blue_gray_900.svg"/>
                                      </div>
                                      <div className={`tab-content ${activeTab === tabKey ? 'tab-content-active' : ''}`}>
                                        <p className="text-left capitalize text-[18.55px] text-blue_gray-900 w-auto font-lato font-normal">
                                          {item.faqs.answers[index-1]}
                                        </p>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              } else {
                                return null; // Don't render if `ques${index}` has no value
                              }
                            })}
                          </div>
                          </>
                        ))}
                      </div>
                      </div>
                      <div className="faq-right desktop">
                        <img src="/../images/Frame1329.svg"/>
                      </div>
                      
                      </div>
                      <div className='ww90 desktop' style={{paddingTop:'0px', paddingBottom:"25px",}}>
                      <TopRated/>
                      </div>
                      {/* Tabs */}
                      
    </>
  );
};

export default Service;
