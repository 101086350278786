import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Link, NavLink } from "react-router-dom";
import { Navigation } from 'swiper/modules';
import '../Pages/Portfolio.css';
import { PagePort, Write, Video, Graphic } from '../Pages/Page-Portfolio';
import Clients from './Clients';
import Blogs from './HomeBlogs';
import Top from './TopRated';
import 'swiper/css';
import 'swiper/css/navigation';
const Portfolio = () => {


    return (
        <>

            <div class="bg-[#00d648] flex flex-1 flex-col items-center justify-center  mb-[3px] px-2.5 py-[15px] sm:py-[5px] w-full">
                <div class="flex flex-col items-center md:w-[60%] justify-center w-auto md:w-full">
                    <div class="flex flex-col items-center justify-start w-auto">
                        <p class="text-center text-white-A700 text-xl sm:text-[16px] sm:text-center w-auto font-lato font-normal">Click on service that you need, to see Portfolio, Description, Details, &amp; FAQ.
                        </p></div></div></div>
            <div className="ww80 " >
                <div class="flex flex-col gap-[27px] sm:gap-[2px] items-center justify-start max-w-[936px] sm:mt-[40px] md:mt-[40px] mt-[100px] mx-auto md:px-5 w-full"><p class="text-left md:text-5xl text-6xl text-blue_gray-900 font-bold font-lato">Portfolio</p><p class="text-left text-blue_gray-900 text-center text-lg font-lato font-normal"><span class="text-blue_gray-900 font-lato font-normal">Ultravux has helped numerous businesses gain success through the development and design of compelling websites, video production, logo design, Animation videos and more. Look through the portfolio of previous projects to get an idea of what we can do for your business. Are you ready to make a change?<br /> </span><span class="text-red-400 font-lato font-normal">Let’s build this thing together!<br /></span></p></div>


                <div class=" m-auto sm:mb-[10px] sm:mt-[30px] mt-20 mb-20	h-[46px] relative w-[100%] sm:w-full">

                    <p class="text-center  h-full inset-y-[0] left-[0] my-auto sm:text-[33.99px] md:text-[35.99px] text-[37.99px] text-red-400 font-bold font-lato">
                        Programming & Tech
                        <span className='service-underline'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                    </p>
                </div>
                <div className="w-[90%] m-auto" >
                    <PagePort />
                </div>
                <div class="m-auto sm:mb-[10px] sm:mt-[30px]  mt-20 mb-20 h-[41px] relative w-[100%]">

                    <p class="text-center  h-full inset-[0] justify-center m-auto sm:text-[29.380000000000003px] md:text-[31.380000000000003px] text-[33.38px] text-red-400 w-max font-bold font-lato">
                        Writing & Translation
                        <span className='service-underline'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                    </p></div>
                <div className="w-[90%] m-auto" >
                    <Write />
                </div>
                <div class="m-auto sm:mb-[10px] sm:mt-[30px] mt-20 mb-20 h-[41px] relative w-[100%]">

                    <p class="text-center  h-full inset-y-[0] left-[0] my-auto sm:text-[29.380000000000003px] md:text-[31.380000000000003px] text-[33.38px] text-red-400 font-bold font-lato">
                        Video & Animation
                        <span className='service-underline'>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                    </p></div>
                <div className="w-[90%] m-auto" >
                    <Video />
                </div>
                <div class="m-auto sm:mb-[10px] sm:mt-[30px] mt-20 mb-20 h-[41px] relative w-[100%]">

                    <p class="text-center  h-full inset-y-[0] left-[0] my-auto sm:text-[29.380000000000003px] md:text-[31.380000000000003px] text-[33.38px] text-red-400 font-bold font-lato">
                        Graphics & Design
                        <span className='service-underline'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                    </p></div>
                <div className="w-[90%] m-auto" >
                    <Graphic />
                </div>
                <hr style={{ border: "1px solid rgb(255, 61, 87)", width: "40px", margin: "auto", marginTop: "15px", marginBottom: "10%" }} />
                <Clients />
                <div className="mobile">
                    <br></br>
                    <br></br>

                </div>
                <div className='desktop'>
                    <Blogs />
                    <Top />
                </div>
            </div>



        </>
    )
}
export default Portfolio;