import React, {useState,useEffect,useContext} from "react";
import { AuthContext } from "../context/AuthContext";
import axios from 'axios';
import BASE_URL from '../components/constant';
const Invoice =()=>{
    const [invoices, setInvoice] = useState([]);
    const {getUserData } = useContext(AuthContext);

    
    useEffect(()=>{
      const users = getUserData();
        axios.get(`${BASE_URL}/invoices_api.php?client_id=${users.id}`)
        .then(res => {
          //console.log(res.data);
          setInvoice(res.data); // Assuming the response has a services array
        })
        .catch(err=>console.log(err));
        },[getUserData])
        // const [fullscreen, setFullscreen] = useState(false);

  const handleFullscreen = (file_path) => {
    console.log(`{${BASE_URL}/${file_path}}`);
    const link = document.createElement('a');
    link.href = `${BASE_URL}/${file_path}`; // Assuming invoices is an array and taking the first item
    // link.download = 'invoice.pdf';
    link.click();
  };

  const handleDownload = async (file_path) => {
   
    // const pdfUrl = file_path;

   try{
            const response = await fetch(`${BASE_URL}/invoice_download.php?file_path=${file_path}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const blob = await response.blob();
            const fileURL = window.URL.createObjectURL(blob);
    
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = "SamplePDF.pdf"; // Customize the downloaded file name
            link.click();
        } catch (error) {
            console.error("Error fetching PDF:", error.message);
        }
    
        // // using Java Script method to get PDF file
        // fetch("SamplePDF.pdf").then((response) => {
        //     response.blob().then((blob) => {
             
        //         // Creating new object of PDF file
        //         const fileURL =
        //             window.URL.createObjectURL(blob);
                     
        //         // Setting various property values
        //         let alink = document.createElement("a");
        //         alink.href = fileURL;
        //         alink.download = "SamplePDF.pdf";
        //         alink.click();
        //     });
        // });
  };
        
    
    // invoices
    return(
        <>
 <div className="invoives grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-4 sm:gap-2 mt-10 mb-20">
 {invoices.length > 0 ? (
  invoices.map((item) => (
    <div className="bg-white-A700 flex flex-1 flex-col justify-start pb-[18px] rounded-[20px] shadow-bs16 w-full" key={item.id}>
      {/* Your existing content for each invoice item */}
      <iframe src={`${BASE_URL}/${item.file_path}`} style={{ height: "100%", width: "100%", border: "none" }} />
      <p className="text-left ml-3  mt-[22px] text-[17.28px] text-black-900 font-lato font-semibold">{item.order_name}</p>
      <div className="flex flex-row gap-3.5 sm:gap-2.5 md:gap-2.5 items-start justify-end  mr-[15px] mt-[17px] sm:pr-[10px] md:pr-[10px] sm:w-full md:w-full">
       
         <button className="text-[15.55px] text-gray-900 font-bold font-lato" onClick={() => handleFullscreen(item.file_path)}>
                Open 
              </button>
              <button className="text-[15.55px] text-red-400 font-bold font-lato" onClick={() =>handleDownload(item.file_path)}>
                Download
              </button>
      </div>
    </div>
  ))
) : (
  <p>No invoices yet.</p>
)}

            
        </div>
        </>
    )
}
export default Invoice;