import React, { useState } from "react";
// import { sendPasswordResetEmail} from "firebase/auth";
import {signInWithEmailAndPassword, sendPasswordResetEmail,} from "firebase/auth";
import { auth,  } from "../firebase";
import { addDataToFirestore} from '../services/AuthServices';
import { ToastContainer, toast } from 'react-toastify';
// const forgotPassword = (Email) => {

   
// };
const Reset=()=>{
  const [email, setEmail] = useState('');

  // const [resetSent, setResetSent] = useState(false);

  const handleResetPassword = async () => {
    try {
      console.log("reset email sent to " + email);
      sendPasswordResetEmail(auth, email, null)
          .then(() => {
              toast.success("reset email sent to " + email);
              addDataToFirestore(email);
          })
          .catch(function (e) {
              console.log(e);
          });
      // await auth.sendPasswordResetEmail(email);
      
    } catch (error) {
      console.error('Error sending password reset email:', error.message);
      toast.error('Error sending password reset email:', error.message);
    }
  };

    return(<>
         <div className="ww90" style={{paddingTop:"4%", paddingBottom:"8%"}}>
                <div className="log-main">
                <ToastContainer />
                    <div className="log-left">
                    <img src="../images/Mypassword-pana1.svg" />
                    </div>
                    <div className="log-right">
                    <div className="flex sm:flex-1 flex-col gap-[36.24px] items-center justify-center md:mt-0 mt-[115px] rounded-[11px] w-[453px] sm:w-full">
  <div className="flex flex-col items-start justify-start w-full">
    <p className="text-left sm:text-[27.5px] md:text-[29.5px] text-[31.5px] text-gray-900_09 w-auto font-poppins font-semibold">Reset Your Password</p>
  </div>
  <div className="flex flex-col gap-[27px] items-start justify-start w-full">
    <div className="flex flex-col gap-[13.5px] items-start justify-start w-full">
      <div className="flex flex-col items-start justify-start w-full">
        <p className="text-left capitalize text-gray-700 text-lg w-auto font-normal font-poppins">Email</p>
      </div>
      <div className="flex flex-col h-[54px] md:h-auto items-start justify-start w-[450px] md:w-full">
        <div className="w-full rounded-[9px] outline outline-[1.5px] outline-blue-50_04 text-gray-700 pb-3.5 pt-[18px] px-3.5">
           <input
                      className="font-poppins p-0 placeholder:text-gray-700 text-left text-sm w-full bg-transparent border-0"
                      type="text"
                      name="email_One"
                      placeholder="youremail@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    /> </div>
      </div>
    </div>
   
   
  </div>
  <div className="flex flex-col gap-[27px] items-center justify-start w-full">
  <button
                  className="cursor-pointer font-poppins font-semibold h-[54px] rounded-[9px] text-base text-center w-[450px] p-[15px] bg-red-400 text-white-A700"
                  onClick={handleResetPassword}
                >
                  Reset Password
                </button> <div className="flex flex-row gap-[9px] items-start justify-start w-auto">
      <p className="text-left capitalize text-gray-400_01 text-lg w-auto font-normal font-poppins">Already Know Your Password ?</p>
      <a href="/Pages/login" className="text-left capitalize text-lg text-red-400 w-auto font-normal font-poppins">Log in</a>
    </div>
  </div>
</div>

                    </div>
                    </div>
                    </div>
    </>)
};

export default Reset;