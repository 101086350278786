import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Clients.css'
import BASE_URL from '../components/constant';
import axios from 'axios'
import { Navigation, Autoplay, Pagination, Mousewheel, Keyboard ,EffectBlur } from 'swiper/modules';

const HomeClient = () =>{
  const swiperRef = useRef();
  const csutomswiperRef = useRef();
  const sccsutomswiperRef = useRef();


  const [clients, setClients] = useState([2]);
  const [reww, setTestimonials] = useState([1]);
  useEffect(()=>{
    axios.get(`${BASE_URL}/clients_api.php`)
    .then(res => {
      console.log(res.data);
      setClients(res.data); // Assuming the response has a services array
    })
    .catch(err=>console.log(err));
    },[])
    
    useEffect(()=>{
      axios.get(`${BASE_URL}/testimonials_api.php`)
      .then(res => {
        console.log(res.data);
        let testimonialsData = res.data;

        // Duplicate the array if its length is 3
        if (testimonialsData.length === 3) {
          testimonialsData = [...testimonialsData, ...testimonialsData];
        }
  
        setTestimonials(testimonialsData);
       // Assuming the response has a services array
      })
      .catch(err=>console.log(err));
      },[])

   
    return(
        <>
        
<div class="flex flex-col md:gap-10 gap-[65px] items-center justify-start max-w-[1224px] md:mb-5  mt-[90px] md:mt-[15px] mx-auto md:px-5 w-full">
  <p class="text-left text-4xl sm:text-[32px] md:text-[34px] text-blue_gray-900 font-lato font-semibold">Our Client’s</p>
  <div class="desktop ipadclient-gap sm:flex-col flex-row gap-[29.85px] grid sm:grid-cols-1 md:grid-cols-3 grid-cols-6 justify-start w-auto md:w-full" orientation="horizontal">
    {clients.map((item) => (
        <div className="bg-white-A700 flex flex-col h-[99px] md:h-auto items-center justify-center p-[9.95px] rounded-[9px] shadow-bs4 w-[150px] transform transition-transform hover:scale-125">
        <img className="h-[59px]  md:h-auto object-contain  w-[61px] sm:w-full " src={item.image} alt="1200x600wa" loading="lazy"/>
        </div>
      ))}
      
  </div>
  
</div>
<div className='mobile'>
<Swiper slidesPerView={2.5}
        spaceBetween={20}
        freeMode={true}
        loop={true}
        
        autoplay={{
        delay: 1500,
      }}
        className='slidr-mocb'
        >
  {clients.map((items) => (
  <SwiperSlide >
  <div class="bg-white-A700 flex flex-col h-[80px]  items-center justify-center p-[9.95px] rounded-[9px] shadow-bs4 w-[100%] transform transition-transform hover:scale-105">
  <img class="h-[80px] p-[20px] md:h-auto object-cover rounded-[12px] w-[100px] sm:w-full " src={items.image} alt="1200x600wa" loading="lazy"/>
  </div>
  </SwiperSlide>
  ))}
  </Swiper>
  </div>
  <div className='tablet'>
<Swiper slidesPerView={3.5}
        spaceBetween={20}
        freeMode={true}
        loop={true}
        autoplay={{
        delay: 1500,
      }}
        className='slidr-mocb'
        >
  {clients.map((items) => (
  <SwiperSlide >
  <div class="bg-white-A700 flex flex-col h-[80px]  items-center justify-center p-[9.95px] rounded-[9px] shadow-bs4 w-[100%] transform transition-transform hover:scale-105">
  <img class="h-[80px] p-[20px] md:h-auto object-cover rounded-[12px] w-[100px] sm:w-full " src={items.image} alt="1200x600wa" loading="lazy"/>
  </div>
  </SwiperSlide>
  ))}
  </Swiper>
  </div>

<div className="Testimonials">
<div class="flex flex-col gap-[25px] h-full justify-start mt-[54px] mb-8 ">
<div className="head-main-T flex mt-36 md:mt-4">
<div className="qoutation">
<img src="../images/qoutation.png"/>
</div>
<div class="flex testhead relative ">
<div className="bg-pink-100_01 testline w-[190px] mt-[24px]"></div>
<p class="text-left absolute h-full inset-[0] justify-center m-auto sm:text-2xl md:text-[26px] text-[28px] text-red-400 w-max font-lato font-semibold">
Testimonials</p>
</div>
<div className="qoutation2">
<img src="../images/quotation2.png"/>
</div>
</div>
<p class="text-center sm:text-[22.87px] md:text-[24.87px] text-[26.87px] text-blue_gray-900 font-bold font-lato">What clients say about us?</p>
</div>
{/* testimomials */}

<div className='rev-cont desktop' >
<div style={{position:'relative'}}>
    <Swiper
    slidesPerView={3}
      spaceBetween={30}
      centeredSlides={false}
      loop={true}
      autoplay={{
        delay: 4500,
      }}
      pagination={{
        clickable: true,
      }}
      initialSlide={1}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination,Autoplay, Mousewheel, Keyboard]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="my"
      >
          {reww.map((item) => (
        <SwiperSlide  className='nsausyuyyyy'>
        
          <div className='slide-content' >
           <div className='review-main'>
        <div className='testimonial-content'>
        <p className='review'>
        {item.review}
        {/* <a href='' className='review-link'> see more....</a> */}
        </p>
        </div>
        <div className='testimonial-images'>
        <img src="../images/Ellipse.png"/>
        <img src="../images/Ellipse2.png"/>
        <img className='review-pic'  src={item.client_image}/>
        </div>
        <div className='text-center'><h1 className='pname'>{item.client_name}</h1></div>
        </div>
          </div>
        </SwiperSlide>
      ))} 
      </Swiper>
    <button className='swiper-button-prev video-bnt' 
        style={{    position: "absolute", left: "-24px", top:"16%", background: "#EC4F5C", width: "36px", height: "35.997px"}}  onClick={() => swiperRef.current?.slidePrev()}></button>
        <button className='swiper-button-next video-bnt' 
        style={{    position: "absolute", right: "-24px", top:"15%", background: "#EC4F5C", width: "36px", height: "35.997px"}} onClick={() => swiperRef.current?.slideNext()}></button>
        </div>
        </div>
      

      <div className='rev-cont mobile'>
      <div style={{position:'relative'}}>
<Swiper
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 4500
                  }}
        pagination={{
          clickable: true,
        }}
        freeMode={true}
        initialSlide={2}
        loop={true}
        navigation={false}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination,Autoplay, Mousewheel, Keyboard]}
        onBeforeInit={(swiper) => {
          csutomswiperRef.current = swiper;
        }}
        className=""
      >
{reww.map((item) => (
    <SwiperSlide>
        <div className='review-main'>
        <div className='testimonial-content'>
        <p className='review'>
        {item.review}
        {/* <a href='' className='review-link'> see more....</a> */}
        </p>
        </div>
        <div className='testimonial-images'>
        <img src="../images/Ellipse.png"/>
        <img src="../images/Ellipse2.png"/>
        <img className='review-pic' src={item.client_image}/>
        </div>
        <div className='text-center'><h1 className='pname'>{item.name}</h1></div>
        </div>
        </SwiperSlide>   
      ))}
      </Swiper>
      <button className='swiper-button-prev video-bnt testibtn-mobi-next' 
        style={{    position: "absolute", top:"60%", background: "#EC4F5C", width: "36px", height: "35.997px"}}  onClick={() => csutomswiperRef.current?.slidePrev()}></button>
        <button className='swiper-button-next video-bnt testibtn-mobi-prev' 
        style={{    position: "absolute", right: "10px", top:"60%", background: "#EC4F5C", width: "36px", height: "35.997px"}} onClick={() => csutomswiperRef.current?.slideNext()}></button>
    
      </div></div>

      {/* Tablet */}
      <div className='rev-cont tablet'>
      <div style={{position:'relative'}}>
<Swiper
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 4500
                  }}
        pagination={{
          clickable: true,
        }}
        freeMode={true}
        initialSlide={2}
        loop={true}
        navigation={false}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination,Autoplay, Mousewheel, Keyboard]}
        onBeforeInit={(swiper) => {
          sccsutomswiperRef.current = swiper;
        }}
        className=""
      >
{reww.map((item) => (
    <SwiperSlide>
        <div className='review-main'>
        <div className='testimonial-content'>
        <p className='review'>
        {item.review}
        {/* <a href='' className='review-link'> see more....</a> */}
        </p>
        </div>
        <div className='testimonial-images'>
        <img src="../images/Ellipse.png"/>
        <img src="../images/Ellipse2.png"/>
        <img className='review-pic' src={item.client_image}/>
        </div>
        <div className='text-center'><h1 className='pname'>{item.name}</h1></div>
        </div>
        </SwiperSlide>   
      ))}
      </Swiper>
      <button className='swiper-button-prev video-bnt' 
        style={{    position: "absolute", left: "10px", top:"60%", background: "#EC4F5C", width: "36px", height: "35.997px"}}  onClick={() => sccsutomswiperRef.current?.slidePrev()}></button>
        <button className='swiper-button-next video-bnt' 
        style={{    position: "absolute", right: "10px", top:"60%", background: "#EC4F5C", width: "36px", height: "35.997px"}} onClick={() => sccsutomswiperRef.current?.slideNext()}></button>
    
      </div></div>  
      
</div>
        </>
    )
};
 export default HomeClient;