import React,{useState,useEffect } from "react";
import axios from 'axios'
import BASE_URL from '../components/constant';

const Policy =()=>{
  const [contacts, setContact] = useState([]);
  useEffect(()=>{
    axios.get(`${BASE_URL}/general_settings.php`)
    .then(res => {
      console.log(res.data);
      setContact(res.data); // Assuming the response has a services array
    })
    .catch(err=>console.log(err));
    },[])
    const getContact = (index) => {
      console.log(contacts);
      if (contacts!=null ) {
        return contacts;
      }else{
      return {contact_no: "123456789",
      whatsapp_no: "987654321",
      address: "123 Main Street",
      email: "example@example.com",
      fb: "facebook",
      insta: "instagram",
      twitter: "twitter",
      linkedin: "linkedin",
      youtube: "youtube"};}; 
    };
    return(
        <>
            <div className="ww90" style={{paddingTop:"2%"}}>
        <div className="termfr">
            <div className="terms-left">
                <p className="terms-title1 sm:text-[45px] sm:font-bold	md:text-center sm:text-center">
                ULTRAVUX 
                </p>
                <p className="terms-title2 sm:text-[36px] md:text-center sm:text-center sm:mb-4">
                Privacy Policy  
                </p>
                <div className="terms-right mobile">
                <img src="../images/07PrivacyPolicy.svg"/>
            </div>
            <div className="terms-right tablet">
                <img className="w-full mt-4" src="../images/07PrivacyPolicy.svg"/>
            </div>
                <p class="text-left sm:text-center text-blue_gray-900 md:mt-0 mt-20 text-lg sm:text-[16px] md:text-[16px] w-full font-lato font-normal">
                UltraVux is committed to protecting your privacy online. This Privacy Policy endeavors to describe to you our practices regarding the personal information we collect from users on our website (the hereinafter referred to as“Site”), and the services offered through the Site. If you have any question about our Privacy Policy, our collection practices, the processing of user information, or if you would like to report a security violation to us directly, please contact us at {getContact(0).email}
</p>
            </div>
            <div className="terms-right desktop">
                <img src="../images/07PrivacyPolicy.svg"/>
            </div>
            </div>
            <div className="flex flex-col md:gap-[10px] gap-[75px] sm:gap-[10px] items-start justify-start  mt-10 mx-auto md:px-0 sm:px-0 w-full">
  <div className="flex flex-col md:gap-10 gap-[111.27px] sm:gap-[10.27px] md:gap-[10.27px] items-start justify-start w-auto md:w-full">
    <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
      <p className="text-left text-4xl sm:text-[24px] md:text-[24px] text-blue_gray-900 w-auto font-lato font-semibold">What Data We Collect</p>
      <p className="text-left text-gray-900 text-lg sm:text-[16px] md:text-[16px] font-lato font-normal">
        <span className="text-gray-900 font-lato text-left sm:text-center font-normal">General Data: </span>
        <span className="text-blue_gray-900 font-lato text-left sm:text-center font-normal">
          The use of our services will automatically create information that will be collected. For example, when you use our Services, your geographic location, how you use the Services, information about the type of device you use, your Open Device Identification Number, date/time stamps for your visit, your unique device identifier, your browser type, operating system, Internet Protocol (IP) address, and domain name are all collected. This information is generally used to help us deliver the most relevant information to you, administer and improve the Site.<br />
          <span className="text-gray-900 font-lato text-left font-normal">Country Name:</span>
          <span className="text-blue_gray-900 font-lato text-left sm:text-center font-normal"> UltraVux digital agency company collect buyer country name and other relevance information<br /></span>
          <span className="text-gray-900 font-lato text-left font-normal">Log Files:</span>
          <span className="text-blue_gray-900 font-lato text-left sm:text-center font-normal">
            As is true of most websites, we gather certain information automatically and store it in log files. This information includes IP addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data. We use this information to maintain and improve the performance of the Services.<br /></span>
          <span className="text-gray-900 font-lato text-left font-normal">Cookies:</span>
          <span className="text-blue_gray-900 font-lato text-left sm:text-center font-normal">
            “Cookies” are small pieces of information (text files) that a website sends to your computer’s hard drive while you are viewing the website. These text files can be used by websites to make the user experience more efficient. The law states that we can store these cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies, we need your permission.
          </span>
        </span>
      </p>
    </div>

    <div className="flex flex-col gap-[37.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
      <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
        <p className="text-left text-4xl sm:text-[24px] md:text-[24px] text-blue_gray-900 w-auto font-lato font-semibold">Use of Your Personal Information</p>
        <p className="text-left sm:text-center max-w-[1224px] md:max-w-full text-blue_gray-900 text-lg sm:text-[16px] md:text-[16px] font-lato font-normal">In general, personal information you submit to us is used either to respond to requests that you make, aid us in serving you better, or market our Services. We use your personal information in the following ways:</p>
      </div>
      <div>
      {/* <p className="text-left text-4xl sm:text-[24px] md:text-[24px] text-blue_gray-900 w-auto font-lato font-semibold">Refund Policy</p> */}
      </div>

      <div className="mobile">
      <ul>
        <li className="flex">
        <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig" loading="lazy" />
        <span className="text-left ml-2 mb-2 text-blue_gray-900 text-l w-auto font-lato font-normal">Operate, maintain, and improve our site(s), products, and services;</span>
        </li>
        <li className="flex">
        <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig" loading="lazy" />
        <span className="text-left ml-2 mb-2 text-blue_gray-900 text-l w-auto font-lato font-normal">Respond to comments and questions and provide customer service;</span>
        </li>
        <li className="flex">
        <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig" loading="lazy" />
        <span className="text-left ml-2 mb-2 text-blue_gray-900 text-l w-auto font-lato font-normal">Link or combine user information with other personal information we get from third parties, to help understand your needs and provide you with better service;</span>
        </li>
        <li className="flex">
        <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig" loading="lazy" />
        <span className="text-left ml-2 mb-2 text-blue_gray-900 text-l w-auto font-lato font-normal">Develop, improve, and deliver marketing and advertising for the Services;</span>
        </li>
      </ul>
      </div>
      <div className="tablet">
      <ul>
        <li className="flex">
        <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig" loading="lazy" />
        <span className="text-left ml-2 mb-2 text-blue_gray-900 text-l w-auto font-lato font-normal">Operate, maintain, and improve our site(s), products, and services;</span>
        </li>
        <li className="flex">
        <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig" loading="lazy" />
        <span className="text-left ml-2 mb-2 text-blue_gray-900 text-l w-auto font-lato font-normal">Respond to comments and questions and provide customer service;</span>
        </li>
        <li className="flex">
        <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig" loading="lazy" />
        <span className="text-left ml-2 mb-2 text-blue_gray-900 text-l w-auto font-lato font-normal">Link or combine user information with other personal information we get from third parties, to help understand your needs and provide you with better service;</span>
        </li>
        <li className="flex">
        <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig" loading="lazy" />
        <span className="text-left ml-2 mb-2 text-blue_gray-900 text-l w-auto font-lato font-normal">Develop, improve, and deliver marketing and advertising for the Services;</span>
        </li>
      </ul>
      </div>

      <div className="flex desktop flex-col gap-[18.55px] items-start justify-start w-auto md:w-full">
        <div className="flex sm:flex-col flex-row gap-[9.27px] items-center justify-start w-auto sm:w-full">
          <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig" loading="lazy" />
          <p className="text-left text-blue_gray-900 text-lg w-auto font-lato font-normal">Operate, maintain, and improve our site(s), products, and services;</p>
        </div>

        <div className="flex sm:flex-col flex-row gap-[9.27px] items-center justify-start w-auto sm:w-full">
          <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig_One" loading="lazy" />
          <p className="text-left text-blue_gray-900 text-lg w-auto font-lato font-normal">Respond to comments and questions and provide customer service;</p>
        </div>

        <div className="flex md:flex-col flex-row gap-[9.27px] items-start justify-start w-auto md:w-full">
          <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig_Two" loading="lazy" />
          <p className="text-left max-w-[1191px] md:max-w-full text-blue_gray-900 text-lg font-lato font-normal">Link or combine user information with other personal information we get from third parties, to help understand your needs and provide you with better service;</p>
        </div>

        <div className="flex md:flex-col flex-row gap-[9.27px] items-center justify-start w-auto md:w-full">
          <img className="h-[22px] w-[22px]" src="../images/img_cilhandpointright.svg" alt="cilhandpointrig_Three" loading="lazy" />
          <p className="text-left text-blue_gray_900 text-lg w-auto font-lato font-normal">Develop, improve, and deliver marketing and advertising for the Services;</p>
        </div>
      </div>
    </div>
  </div>

  <div className="flex flex-col gap-0.5 items-start justify-start w-auto w-full" style={{paddingBottom:"4%"}}>
    <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px]  md:gap-[10.09px] h-[153px] md:h-auto  justify-start w-full">
      <p className="text-center text-4xl sm:text-[24px] md:text-[24px] text-blue_gray-900 w-auto font-lato font-semibold">Disclosure of Your Personal Information</p>
      <p className="text-center max-w-[100%] md:max-w-full md:text-[16px] text-blue_gray_900 text-center text-lg w-full font-lato font-normal">We disclose your personal information as described below and as described elsewhere in this Privacy Policy.</p>
    </div>

    <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] sm:mt-4  items-start justify-start w-auto md:w-full">
      <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto md:w-full">
        <p className="text-left text-4xl sm:text-[22px] md:text-[24px] text-blue_gray-900 w-[auto] sm:w-[90%] font-lato font-semibold">Third Parties Designated by You: </p>
        <p className="text-left sm:text-center text-blue_gray_900 text-lg sm:text-[16px] md:text-[16px] w-auto font-lato font-normal">When you use the Services, the personal information you provide will be shared with the third parties that you authorize to receive such information.</p>
      </div>

      <div className="flex flex-col gap-[37.09px] sm:gap-[10.09px] md:gap-[10.09px] items-start justify-start w-auto  md:w-full">
        <p className="text-left text-4xl sm:text-[22px] md:text-[24px] text-blue_gray-900 w-auto font-lato font-semibold">Third Party Service Providers: </p>
        <p className="text-left sm:text-center max-w-[1222px] md:max-w-full sm:text-[16px] md:text-[16px] text-blue_gray_900 text-lg font-lato font-normal">We may share your personal information with third party service providers to: provide you with the Services that we offer you through our Services; conduct quality assurance testing; to provide technical support; market the Services; and/or to provide other services to us.</p>
      </div>
    </div>
  </div>
</div>

            </div>
        </>
    )
};
export default Policy;