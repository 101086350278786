import React, { useRef, useState, useEffect } from 'react';
import BASE_URL from '../components/constant';
import axios from 'axios';
import "./About.css";
import Founder from './Founder';
import Clients from './Clients';
import Blogs from "./HomeBlogs";
import Top from './TopRated';


const About= () =>{
  const [team, setTeam] = useState([]);
   
  useEffect(()=>{
    axios.get(`${BASE_URL}/teams_api.php`)
    .then(res => {
      console.log(res.data);
      setTeam(res.data); // Assuming the response has a services array
    })
    .catch(err=>console.log(err));
    },[])

    // const Team =[
    //     {name:'Anika Iyengar', position:'UI/UX Designer', pic:'../images/img_rectangle9379.png'},
    //     {name:'Navya Lalla', position:'Content Writer', pic:'../images/img_rectangle9379_268x373.png'},
    //     {name:'Indira Ahuja', position:'Graphic Designer', pic:'../images/img_rectangle9379_1.png'},
    // ];
    
    // const Team2 =[
    //     {name:'Javed Pardeshi', position:'Animator Designer', pic:'../images/img_rectangle9379_2.png'},
    //     {name:'Pranay Tank', position:'Web Designer', pic:'../images/img_rectangle9379_3.png'},
    //     {name:'Zeeshan Srinivasan', position:'Motion Designer', pic:'../images/img_rectangle9379_4.png'},
    // ];
    const [expanded, setExpanded] = useState(false);
   
  const toggleExpand = () => {
    setExpanded(!expanded);};

    const [expanded2, setExpanded2] = useState(false);
       
  const toggleExpand2 = () => {
    setExpanded2(!expanded2);};
    return(
        <>
        <div className="about_main">
        <div class="a_heading mb-4">
  <div class="a_line-12"></div>
  <div class="a_about-us">About US</div>
  <div class="a_line-13"></div>
</div>
<div className="flex gap-20 md:block ipadAirFlex">
<div className="cont_left">
<h1 className="aboutfh">We Are A Digital Media Agency </h1>
<br className='desktop'/>
<div className="cont_right mobile">
<img src="../images/Weimage.svg"/>
</div>
<div className="cont_right tablet">
<img src="../images/Weimage.svg"/>
</div>
<p className="aboutfc">
Ultravux is one of the best digital Services agencies in New Delhi, India. We help grow your business so you can concentrate on running it. Whether you are an independent contractor searching for a website or a big organization wanting to be seen online, we can help.
 </p>
 <br/>
 <p className="aboutfc">
 Since 2019, we have been helping small & big businesses build their online presence. With years of experience in the digital media industry, we understand the steps necessary to bring your business to the next level. 
 
</p>
</div>

<div className="cont_right desktop">
<img src="../images/Weimage.svg"/>
</div>
</div>
{/* second row */}

<div className="flex mt-20 sm:mt-8 md:mt-8 md:block">
<div className="cont_right">
<div class="a_heading s2 mb-2 mobile">
  <div class="a_line-12"></div>
  <div class="a_about-us ">Built by Creative Minds </div>
  <div class="a_line-13"></div>
</div>
<div class="a_heading s2 mb-2 ipadAirFlex tablet">
  <div class="a_line-12"></div>
  <div class="a_about-us ">Built by Creative Minds </div>
  <div class="a_line-13"></div>
</div>
<h1 className="aboutfh-utlrauvx mobile">Ultravux is a vibrant, talent-driven, engaged digital Services agency  </h1>
<h1 className="aboutfh-utlrauvx tablet">Ultravux is a vibrant, talent-driven, engaged digital Services agency  </h1>

<img src="../images/BuiltMinds.svg"/>
</div>
<div className="cont_left">
<div class="a_heading s2 mb-10 ipadAirnone desktop">
  <div class="a_line-12"></div>
  <div class="a_about-us ">Built by Creative Minds </div>
  <div class="a_line-13"></div>
</div>
<h1 className="aboutfh-utlrauvx desktop ">Ultravux is a vibrant, talent-driven, engaged digital Services agency  </h1>
<br/>
<p className={expanded ? 'aboutfc' : 'aboutfc head-blur'}>
Where our team feels empowered, remarkable work is rewarded, and where ideas are encouraged. We nurture passionate, bright people who are creative and innovative in their thinking, brave and ambitious in everything they do.
 </p>
 <br/>
 
<div className="learn-more-button mt-4">
{expanded && <p className="aboutfc">We are a dynamic team of creative minds which helps brands improve their engagement, visibility, and revenue. We bring concepts and messaging to life—through app development, creative design, web development, video production ,content writing and so much more. 
Moreover, we also implement and organize advanced strategies to market your products or services online to boost your revenue and sales. From creating your next logo to redesigning your landing page or optimizing your web pages and content for search engines, Ultravux can do it all for you!
</p>}
      <button className='abour_read_more mt-4' onClick={toggleExpand}>
        {expanded ? 'Read Less' : 'Read More'}
      </button>
      
    </div>
</div>
</div>

{/* Third Row */}
<div className="flex mt-20 md:mt-8 gap-20 md:block ">
<div className="cont_right mobile">
<div class="a_heading s3 mb-2 mobile">
  <div class="a_line-12"></div>
  <div class="a_about-us ">We are your one-stop shop </div>
  <div class="a_line-13"></div>
</div>
<h1 className="aboutfh-utlrauvx mobile">Full service means a full array of capabilities. </h1>
<img src="../images/Onemage.svg"/>
</div>

<div className="cont_right tablet">
<div class="a_heading s3 mb-2 tablet">
  <div class="a_line-12"></div>
  <div class="a_about-us ">We are your one-stop shop </div>
  <div class="a_line-13"></div>
</div>
<h1 className="aboutfh-utlrauvx tablet">Full service means a full array of capabilities. </h1>
<img src="../images/Onemage.svg"/>
</div>
<div className="cont_left">
<div class="a_heading s3 mb-10 desktop">
  <div class="a_line-12"></div>
  <div class="a_about-us ">We are your one-stop shop </div>
  <div class="a_line-13"></div>
</div>
<h1 className="aboutfh-utlrauvx desktop">Full service means a full array of capabilities. </h1>
<br/>
<p className={expanded2 ? 'aboutfc' : 'aboutfc head-blur'}>
We read between the lines and everything in between to leave no stone unturned. Combining our comprehensive capabilities under one roof enables us to have eyes on everything and work optimally and efficiently.
 </p>
 <br/>
 
<div className="learn-more-button mt-4">
{expanded2 && <p className="aboutfc">You will also hear from us often. We promise to be your right-hand man and work closely with our clients to provide top-notch service and hyperreactivity. Together, we work in unison to guarantee our clients have access to the advanced knowledge they need exactly when they need it.
</p>}
      <button className='abour_read_more mt-4' onClick={toggleExpand2}>
        {expanded2 ? 'Read Less' : 'Read More'}
      </button>
      
    </div>
</div>
<div className="cont_right desktop">

<img src="../images/Onestopshopimage.svg"/>
</div>
</div>
<Founder/>
{/* About Team */}
<div className='team mt-40 mb-10 md:mt-20'>
<p class="text-left sm:text-[32.33px] md:text-[34.33px] text-[36.33px] text-blue_gray-900 text-center w-auto font-lato font-semibold">Our experienced Team</p>
<p class="text-left text-[18.16px] text-blue_gray-900 text-center w-auto font-lato font-normal">Ultravux has more than 40 employees. Here are some of our top-skilled team members.</p>
</div>

{/* <div className='flex md:block'>
      {Team.map((item) => (
        <div className='teamcard'>
<div class="bg-white-A700 flex flex-1 flex-col gap-[22px] justify-start pb-8 rounded-[15px] shadow-bs13 w-full">
<img class="h-[268px] sm:h-auto object-cover rounded-tl-[15px] rounded-tr-[15px] w-full" src={item.pic} alt="rectangle9379" loading="lazy"/>
<div class="flex flex-col gap-[11px] items-start justify-start md:ml-[0] ml-[30px]">
<p class="text-left sm:text-[20.22px] md:text-[22.22px] text-[24.22px] text-gray-900_10 font-lato font-semibold">{item.name}</p>
<p class="text-left text-[16.15px] text-gray-900_b2 font-lato font-normal">{item.position}</p></div>
</div>
</div>       
      ))}
</div>
<div className='flex md:block'>
      {Team2.map((item) => (
        <div className='teamcard'>
<div class="bg-white-A700 flex flex-1 flex-col gap-[22px] justify-start pb-8 rounded-[15px] shadow-bs13 w-full">
<img class="h-[268px] sm:h-auto object-cover rounded-tl-[15px] rounded-tr-[15px] w-full" src={item.pic} alt="rectangle9379" loading="lazy"/>
<div class="flex flex-col gap-[11px] items-start justify-start md:ml-[0] ml-[30px]">
<p class="text-left sm:text-[20.22px] md:text-[22.22px] text-[24.22px] text-gray-900_10 font-lato font-semibold">{item.name}</p>
<p class="text-left text-[16.15px] text-gray-900_b2 font-lato font-normal">{item.position}</p></div>
</div>
</div>       
      ))}
</div> */}
<div className='flex flex-wrap'>
  {team.map((item, index) => (
    <div className='teamcard' key={index}>
      <div class="bg-white-A700 ipad-employee-img flex flex-1 flex-col gap-[22px] justify-start pb-8 rounded-[15px] shadow-bs13 w-full">
        <img class="h-[268px] md:h-[520px] sm:h-auto object-cover rounded-tl-[15px] rounded-tr-[15px] w-full" src={item.image} alt={`rectangle9379_${index}`} loading="lazy" />
        <div class="flex flex-col gap-[11px] sm:gap-[0px] md:gap-[5px]  justify-start sm:items-center items-center md:ml-[0] ml-[30px]">
          <p class="text-left sm:text-[24.22px] md:text-[30.22px] text-[24.22px] text-gray-900_10 font-lato font-semibold">{item.name}</p>
          <p class="text-left text-[16.15px] text-gray-900_b2 font-lato font-normal">{item.designation}</p>
        </div>
      </div>
    </div>
  ))}
</div>

<Clients/>

<div className='desktop'>
<Blogs/>
<Top/>
</div>
</div>

        </>
    )
};

export default About;