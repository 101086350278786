import React from "react";
import './Why.css';
import Clients from './Clients';
import Blogs from './HomeBlogs';
import Top from './TopRated';

const Why= () =>{
    const whyUs =[
        {name:'Client-centric philosophy', content:'We aim to understand our customer’s brand and business goals first. ', picture:'../images/5995340-011.svg'},
        {name:'Integrity', content:'We genuinely believe in doing business with honesty and integrity. We’re not here to sell you something you do not need. We don’t promise miraculous results over a short time to levy added costs.', picture:'../images/60303331.svg'},
        {name:'Flexibility', content:'Major firms provide pre-determined packages, making you pay for items you may not need. Being a bespoke digital agency means we provide more flexibility in selecting our services and a more personalized solution. ', picture:'../images/4436051-01.svg'},
        {name:'Transparency', content:'Communication and transparency are the keys to an efficient client-agency relationship. Scheduled calls, regular check-ins, transparent processes and tools, and regular reporting guarantee you know what we’re doing and what you’re getting. ', picture:'../images/3627664-01.svg'},
        {name:'Deliver on Time', content:'We don’t make outlandish promises. Instead, we learn your business goals and brand before committing to short-term or long-term strategies. We understand that time is business money, so we always set realistic deadlines and stick to them. ', picture:'../images/5385955-011.svg'},
        {name:'Transparent Billing', content:'We make the entire billing process easy, so clients understand what they’re getting and at what price. We also provide monthly contracts and guarantee the contract language is simple to understand. ', picture:'../images/6134223-011.svg'},
    ];
    return (
        <>
            <div className="about_main" style={{marginTop:"10px"}}>
           
<div className="flex md:block ">
<div className="cont_left">
 <div class="a_heading mb-10 md:mb-2 sm:mb-2">
  <div class="a_line-12"></div>
  <div class="a_about-us">Why US</div>
  <div class="a_line-13"></div>
</div>
<h1 className="aboutfh">Why Choose Us? </h1>
<br className="desktop"/>
<img className="mobile"  src="../images/Why Choose Us.svg"/>
<img className="tablet"  src="../images/Why Choose Us.svg"/>

<p className="aboutfc">
You are hiring an experienced team of editors, graphic designers, developers, programmers, Animators and content writers. We are conceptual people looking for the right answers in an open-minded way to help help convey your brand’s story. 
 </p>
 <br className="desktop"/>
 <br className="tablet"/>
 <p className="aboutfc">
 Just imagine adding people to collaborate with your team will be about thirty times as efficient as working independently. 
That’s because each will see what the other might miss, not to mention they can leverage one another’s strength while holding each other responsible for higher standards. 
</p>
</div>
<div className="cont_right desktop">
<img  src="../images/Why Choose Us.svg"/>
</div>
</div>
{/* Second Row */}

    {whyUs.map((item, index) => (
            <>
            {index % 2 === 0 ? (
        <div className="flex md:block ">
        <h1 className="aboutfh-utlrauvx text-center mobile sm:mt-8">{item.name}</h1>
        <h1 className="aboutfh-utlrauvx text-center tablet mt-5">{item.name}</h1>
<div className=" why_cont_left whyImg">
<img className="" src={item.picture}/>
</div>
<div className="why_cont_right ">
 
<h1 className="aboutfh-utlrauvx desktop">{item.name}</h1>
<br className="desktop"/>
<p className="aboutfc">
{item.content}
 </p>
 <br/>
</div>
</div>
            ):(
<div className="flex md:block ipadAirFlex">
<div className="why_cont_left">
 
<h1 className="aboutfh-utlrauvx  sm:text-center md:text-center">{item.name}</h1>
<br className="desktop"></br>

<img className="whyImg w-full mobile" src={item.picture}/>
<img className="whyImg w-full tablet" src={item.picture}/>

<p className="aboutfc">
{item.content}
 </p>
 <br className="desktop"/>
</div>
<div className="why_cont_right whyImg desktop">
<img className="" src={item.picture}/>
</div>
</div>
            )}
</>
        
        ))}
        <br className="tablet"/>
        <Clients/>
        <Blogs/>
        <div className="desktop">
        <Top/>
        </div>
            </div>
        </>
    )
};
export default Why;