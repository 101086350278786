// import React, { useState, useEffect, useRef } from 'react'
// import { firestore, auth } from '../firebase'
// import SendMessage from '../components/SendmessageSupport'
// import { collection, query,limit, orderBy, onSnapshot} from "firebase/firestore";

// const Support =()=>{
//     const [messages, setMessages] = useState([])
//     const userId = auth.currentUser.uid;
  

//     useEffect(() => {
//         const q = query(
//           collection(firestore, 'Support&Help', `${userId}-Admin`,"chats"),
//           orderBy("createdAt"),
//           limit(50)
//         );
      
//         const unsubscribe = onSnapshot(q, (querySnapshot) => {
//           let messages = [];
//           querySnapshot.forEach((doc) => {
//             messages.push({ ...doc.data(), id: doc.id });
//           });
//           setMessages(messages);
//         });
      
//         return () => unsubscribe();
//       }, []);
 
//     return (
       
//       <div className="bg-white-A700 messad flex flex-col font-poppins items-start justify-start mb-[0px] md:mt-0 mt-[0px] p-[29px] sm:px-5 rounded-bl rounded-br rounded-tl-[10px] h-[662px] rounded-tr-[10px] mt-[40px] mb-[40px] shadow-bs1 w-[95%] md:w-full">
//       <div className='convo'>
//             {messages && messages.map((message, id) => (
//               <div key={id} className={`msg ${message.uid === auth.currentUser.uid ? 'sent' : 'received'}`}>
               
//                 {message.uid === auth.currentUser.uid ? (
//                   <>
//                   <div className="cursor-pointer messageposition leading-[normal] min-w-[202px] mt-1 rounded-bl-[25px] rounded-tl-[25px] rounded-tr-[25px] text-center text-xs  p-5 bg-gray-200_04 text-gray-800_02">
                  
                      
                     
//                       {message.image == null ? (
//                         <p className="text-left text-gray-800_02 text-xs w-auto font-normal font-poppins">{message.text}</p>
//                       ) : (
//                         <img className="h-[100px]   w-[100px]" src={message.image} alt="User Image" />
//                       )}
//                     </div>
//                   </>
//                 ) : (
//                   <>
//                   <div className="cursor-pointer leading-[normal] messageposition min-w-[202px] mt-1 rounded-br-[25px] rounded-tl-[25px] rounded-tr-[25px] text-xs  p-5 bg-gray-200_04 text-gray-800_02">
                   
//                       {message.image == null ? (
//                         <p className="text-left text-gray-800_02 text-xs w-auto font-normal font-poppins">{message.text}</p>
//                       ) : (
//                         <img  className="h-[100px]   w-[100px]" src={message.image} alt="Other User Image" />
//                       )}
//                     </div>
//                   </>
//                 )}
//               </div>
//             ))}
         
//           {/* </div> */}
//           <SendMessage />
//         </div>
//         </div>
//         );
// {/* */}
   
// }
// export default Support;// // import React, { useState } from "react";
// import React, { useState, useEffect, useRef } from 'react'
// import { firestore, auth } from '../firebase'
// import SendMessage from '../components/Sendmessage'

// import { collection, query,limit, orderBy, onSnapshot} from "firebase/firestore";
// import Modal from 'react-modal'; // Import react-modal
// import { FaTimes } from 'react-icons/fa'; 
// // Modal.setAppElement('#root');
// const ProfileMesages =()=>{
//     const [messages, setMessages] = useState([])
//     const userId = auth.currentUser.uid;
//     const [selectedImage, setSelectedImage] = useState(null);
//     const [isModalOpen, setIsModalOpen] = useState(false);
    
//     useEffect(() => {
//         const q = query(
//           collection(firestore, 'messages', `${userId}-Admin`,"chats"),
//           orderBy("createdAt"),
//           limit(50)
//         );
      
//         const unsubscribe = onSnapshot(q, (querySnapshot) => {
//           let messages = [];
//           querySnapshot.forEach((doc) => {
//             messages.push({ ...doc.data(), id: doc.id });
//           });
//           setMessages(messages);
//         });
      
//         return () => unsubscribe();
//       }, []);
 
//       const handleImageClick = (imageUrl) => {
//         setSelectedImage(imageUrl);
//         setIsModalOpen(true);
//     };

    
//     return (
       
//         <div className="bg-white-A700 messad flex flex-col font-poppins items-start justify-start mb-[0px] md:mt-0 mt-[0px] p-[29px] sm:px-5 rounded-bl rounded-br rounded-tl-[10px] h-[662px] rounded-tr-[10px] mt-[40px] mb-[40px] shadow-bs1 w-[95%] md:w-full">
//         {/* Popup modal for displaying the selected image */}
//         <Modal
//   isOpen={isModalOpen}
//   onRequestClose={() => setIsModalOpen(false)}
//   contentLabel="Image Popup"
//   style={{
//     overlay: {
//       backgroundColor: 'rgba(0, 0, 0, 0.5)'
//     },
//     content: {
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//       justifyContent: 'center',
//       // background: 'transparent',
//       border: 'none'
//     }
//   }}
// >
//   <img
//     src={selectedImage}
//     alt="Preview"
//     className="img-fluid"
//   />
//   <button 
//   onClick={() => setIsModalOpen(false)} style={{
//     position: 'absolute', // Set the position of the button to absolute
//     top: '10px', // Adjust top and right values to position the button
//     right: '10px',
//     backgroundColor: 'transparent',
//     border: 'none',
//     cursor: 'pointer'
//   }}
// ><FaTimes /> {/* Cross icon */}</button>
// </Modal>


          

//         <div className='convo'>
//             {messages && messages.map((message, id) => (
//               <div key={id} className={`msg ${message.uid === auth.currentUser.uid ? 'sent' : 'received'}`}>
               
//                 {message.uid === auth.currentUser.uid ? (
//                   <>
                                           
//                       {message.image == null ? (
//                         <div className="cursor-pointer messageposition leading-[normal] min-w-[202px] mt-1 rounded-bl-[25px] rounded-tl-[25px] rounded-tr-[25px] text-center text-xs  p-5 bg-gray-200_04 text-gray-800_02">
//                         <p className="text-left text-gray-800_02 text-xs w-auto font-normal font-poppins">{message.text}</p>
//                      </div>
//                       ) : (
//                         <div className="cursor-pointer messageposition leading-[normal] min-w-[202px] mt-1 rounded-bl-[25px] rounded-tl-[25px] rounded-tr-[25px] text-center text-xs  p-5  text-gray-800_02">
//                         <img className="h-[100px] w-[100px]" src={message.image} alt="User Image"  onClick={() => handleImageClick(message.image)} />
//                         </div>
//                       )}
                   
//                   </>
//                 ) : (
//                   <>
//                     <div className="cursor-pointer leading-[normal] messageposition min-w-[202px] mt-1 rounded-br-[25px] rounded-tl-[25px] rounded-tr-[25px] text-xs  p-5 bg-gray-200_04 text-gray-800_02">
                   
//                       {message.image == null ? (
//                         <p className="text-left text-gray-800_02 text-xs w-auto font-normal font-poppins">{message.text}</p>
//                       ) : (
//                         <img  className="h-[100px]   w-[100px]" src={message.image} alt="Other User Image" onClick={() => handleImageClick(message.image)} />
//                       )}
//                     </div>
//                   </>
//                 )}
//               </div>
//             ))}
         
//           {/* </div> */}
//           <SendMessage />
//         </div>
       
//          </div>
//         );
// {/* */}
   
// }
// export default ProfileMesages;
import React, { useState, useEffect } from 'react';
import { firestore, auth, storage } from '../firebase';
import SendMessage from '../components/SendmessageSupport';
import { collection, query, limit, orderBy, onSnapshot } from "firebase/firestore";
import Modal from 'react-modal';
import { FaTimes, FaFile } from 'react-icons/fa';
import { IoDocument } from 'react-icons/io5';
import { getDownloadURL, ref } from 'firebase/storage';

const Support = () => {
    const [messages, setMessages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);

    const userId = auth.currentUser.uid;

    useEffect(() => {
        const q = query(
            collection(firestore, 'Support&Help', `${userId}-Admin`, "chats"),
            orderBy("createdAt"),
            limit(50)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let messages = [];
            querySnapshot.forEach((doc) => {
                messages.push({ ...doc.data(), id: doc.id });
            });
            setMessages(messages);
        });

        return () => unsubscribe();
    }, []);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setIsModalOpen(true);
    };

    const handleDocumentClick = async (documentUrl) => {
        const storageRef = ref(storage, documentUrl);
        const url = await getDownloadURL(storageRef);
        window.open(url, '_blank');
    };

    return (
        <div className="bg-white-A700 messad flex flex-col font-poppins items-start justify-start mb-[0px] md:mt-0 mt-[0px] p-[29px] sm:px-5 rounded-bl rounded-br rounded-tl-[10px] h-[662px] rounded-tr-[10px] mt-[40px] mb-[40px] shadow-bs1 w-[95%] md:w-full">
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Image Popup"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    content: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: 'none'
                    }
                }}
            >
                <img src={selectedImage} alt="Preview" className="img-fluid" />
                <button
                    onClick={() => setIsModalOpen(false)}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    <FaTimes />
                </button>
            </Modal>

            <div className='convo'>
                {messages && messages.map((message, id) => (
                    <div key={id} className={`msg ${message.uid === auth.currentUser.uid ? 'sent' : 'received'}`}>
                        {message.uid === auth.currentUser.uid ? (
                            <>
                                {message.image == null ? (
                                    <>
                                        {message.document ? (
                                            <div
                                                className="cursor-pointer messageposition leading-[normal] min-w-[202px] mt-1 rounded-bl-[25px] rounded-tl-[25px] rounded-tr-[25px] text-center text-xs p-5 bg-gray-200_04 text-gray-800_02"
                                                onClick={() => handleDocumentClick(message.document.url)}
                                            >
                                                <IoDocument className="text-gray-400 inline-block" />
                                                <span className="text-left text-gray-800_02 text-xs w-auto font-normal font-poppins ml-2">
                                                    {message.document.name}
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="cursor-pointer messageposition leading-[normal] min-w-[202px] mt-1 rounded-bl-[25px] rounded-tl-[25px] rounded-tr-[25px] text-center text-xs p-5 bg-gray-200_04 text-gray-800_02">
                                                <p className="text-left text-gray-800_02 text-xs w-auto font-normal font-poppins">{message.text}</p>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div  className="cursor-pointer sentimgPosition messageposition leading-[normal] min-w-[202px] mt-1 rounded-bl-[25px] rounded-tl-[25px] rounded-tr-[25px] text-xs p-5  text-gray-800_02">
                                        <img
                                            className="h-[150px] w-[150px]"
                                            src={message.image}
                                            alt="User Image"
                                            onClick={() => handleImageClick(message.image)}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {message.image == null ? (
                <>
                    {message.document ? (
                        <div
                            className="cursor-pointer leading-[normal] messageposition min-w-[202px] mt-1 rounded-br-[25px] rounded-tl-[25px] rounded-tr-[25px] text-xs p-5 bg-gray-200_04 text-gray-800_02"
                            onClick={() => handleDocumentClick(message.document.url)}
                        >
                            <IoDocument className="text-gray-400 inline-block" />
                            <span className="text-left text-gray-800_02 text-xs w-auto font-normal font-poppins ml-2">
                                {message.document.name}
                            </span>
                        </div>
                    ) : (
                        <div className="cursor-pointer leading-[normal] messageposition min-w-[202px] mt-1 rounded-br-[25px] rounded-tl-[25px] rounded-tr-[25px] text-xs p-5 bg-gray-200_04 text-gray-800_02">
                            <p className="text-left text-gray-800_02 text-xs w-auto font-normal font-poppins">{message.text}</p>
                        </div>
                    )}
                </>
            ) : (
                <div className="cursor-pointer recievdbg  messageposition leading-[normal] min-w-[202px] mt-1 rounded-br-[25px] rounded-tl-[25px] rounded-tr-[25px]  text-xs p-5 text-gray-800_02">
                  <img
                                            className="h-[150px] w-[150px]"
                                            src={message.image}
                                            alt="User Image"
                                            onClick={() => handleImageClick(message.image)}
                                        />
                </div>
            )}
                            </>
                        )}
                    </div>
                ))}
                <SendMessage />
            </div>
        </div>
    );
}

export default Support;
